import React, { useEffect } from 'react';
import s from './ViewOrders.module.scss';

import { useStore } from '../../../stores/stores';

import { observer } from 'mobx-react';
import { Loader } from '../../Loader/Loader';
import { TableOrder } from '../TableOrder/TableOrder';
import { Box, Typography } from '@material-ui/core';
import { FilterOrders } from '../FilterOrders/FilterOrders';
import { useHistory } from 'react-router-dom';
import { privateRoutes } from '../../../constants/routes';

export const ViewOrders = observer(() => {
  const { orders, users } = useStore();
  const AuthUser = users.authUser;
  const history = useHistory();
  const currentLocation = history.location.pathname;

 

  useEffect(() => {
    orders.fetchOrders(AuthUser.id);
  }, []);

  const ActiveOrders = orders.activeOrders.map((item) =>
    item.toJSON(),
  );
  const ManagerOrders = orders.managersOrders.map((item) =>
    item.toJSON(),
  );
  const DirectionsOrders = orders.managersDirections.map((item) =>
    item.toJSON(),
  );
  const ordersDate = orders.managersDate.map((item) => item.toJSON());

   
 
  
  return (
    <>
      {ActiveOrders.isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          {ActiveOrders.length > 0 ? (
            <Box my={6} mx={4}>
              <Typography className={s.MainTitle} variant="h4">
                Активні замовлення
              </Typography>
              <Box className={s.tableWrap}>
                <Box>
                  <Typography className={s.filterTitle}>
                    Фільтр
                  </Typography>
                  <FilterOrders
                    managers={ManagerOrders}
                    directions={DirectionsOrders}
                    date={ordersDate}
                    //   status={}
                  ></FilterOrders>
                </Box>
                <Box className={s.tableBox}>
                  <TableOrder
                    
                    AuthUser={AuthUser}
                    rows={ActiveOrders}
                  ></TableOrder>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box my={6} mx={4}>
              <Typography className={s.MainTitle} variant="h4">
                Активні замовлення
              </Typography>
              <Box className={s.tableWrap}>
                <Box>
                  <Typography className={s.filterTitle}>
                    Фільтр
                  </Typography>
                  <FilterOrders
                    managers={ManagerOrders}
                    directions={DirectionsOrders}
                    date={ordersDate}
                  ></FilterOrders>
                </Box>
                <Box my={6} mx={4}>
                  <Box className={s.OrdersEmpty}>
                    Замовлень не знайдено
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
});
