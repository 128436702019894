import React, { useState } from 'react';
import s from './UserForm.module.scss';

import { ChengPassword } from '../../Form/chengPassword/chengPassword';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Typography, Box, Avatar, Button } from '@material-ui/core';
// import { CustomInput } from '../Elements/input/input';
// import { CustomInputMask } from '../Elements/inputMask/inputMask';
import * as Api from '../../../api';
import { useSnackbar } from 'notistack';
import { CustomInput } from '../../Form/Elements/input/input';
import { CustomInputMask } from '../../Form/Elements/inputMask/inputMask';
import { useEffect } from 'react';
import { useStore } from '../../../stores/stores';
import { propOr } from 'ramda';

export const UserForm = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const { infoUser, userId, AuthUser } = props;

  const { enqueueSnackbar } = useSnackbar();

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  function isOpenBox() {
    setOpen(!open);
  }

  const initialValues = {
    full_name: infoUser.full_name.value,
    email: infoUser.email.value,
    phone_number: infoUser.phone_number.value,
    company_name: infoUser.companyName.value,
    company_edrpou: infoUser.companyEdrpou.value,
    address: infoUser.address.value,
    website: infoUser.webSite.value,
  };

  function onSubmit(value) {
    value.user_id = Number(infoUser.idUser);

    Promise.resolve(Api.updateUser(value))
      .then((result) => {
        result.data.messages.length > 0 &&
          result.data.messages.map((item) =>
            infoMassege(item.status, item.text),
          );
      })
      .catch((result) => {
        console.log(result);
      });
  }

  const validationSchema = Yup.object({
    full_name: Yup.string().required(
      "Поле обов'язкове для заповнення",
    ),
    phone_number: Yup.string().required(
      "Поле обов'язкове для заповнення",
    ),
    email: Yup.string()
      .required("Поле обов'язкове для заповнення")
      .email('Введіть E-mail адресу'),
    company_edrpou: Yup.number().typeError(
      'Код ЄДРПУ повинен містити числові дані',
    ),
  });
  console.log(infoUser);

  return (
    <>
      <Box px={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Box mt={2} className={s.setings}>
              <div className={s.conteiner}>
                <Typography
                  className={s.SetingTitle}
                  variant="h4"
                  fontWeight="600"
                >
                  Особиста інформація
                </Typography>
                <div className={s.SetingAvatar}>
                  <Avatar className={s.SetingAvatarFoto}></Avatar>
                  {/* <Button>Завантажити фото </Button> */}
                </div>
                <div className={s.userInfoBlock}>
                  <Field
                    placeholder="Прізвище Ім'я Побатькові"
                    name="full_name"
                    id="full_name"
                    disabled={!!infoUser.full_name.visible}
                    type="text"
                    component={CustomInput}
                  />
                  <Field
                    placeholder="Номер телефону"
                    name="phone_number"
                    id="phone_number"
                    mask="+380(99)999-99-99"
                    disabled={!!infoUser.phone_number.visible}
                    type="tel"
                    component={CustomInputMask}
                  />

                  <Field
                    disabled
                    placeholder="E-mail"
                    name="email"
                    id="email"
                    disabled={!!infoUser.email.visible}
                    type="text"
                    component={CustomInput}
                  />
                </div>
              </div>

              {console.log(infoUser.role_code)}
              {(AuthUser.role_code === 'ap' ||
                AuthUser.role_code === 'ak') &&
              infoUser.role_code !== 'mp' ? (
                <div className={`${s.conteiner} `}>
                  <Typography
                    className={s.SetingTitle}
                    variant="h4"
                    fontWeight="600"
                  >
                    Особиста інформація
                  </Typography>
                  <div className={s.userInfoCompany}>
                    <div>
                      <Typography
                        className={s.InpunTitle}
                        variant="body2"
                      >
                        Назва компанії
                      </Typography>

                      <Field
                         placeholder="Введіть назву компанії"
                        name="company_name"
                        id="company_name"
                        type="text"
                        disabled={!!infoUser.companyName.visible}
                        component={CustomInput}
                      />
                    </div>
                    <div className="">
                      <Typography
                        className={s.InpunTitle}
                        variant="body2"
                      >
                        Код ЄДРПОУ
                      </Typography>
                      <Field
                        placeholder="Введіть код  ЄДРПОУ"
                        name="company_edrpou"
                        id="company_edrpou"
                        // maskPlaceholder="_"
                        mask="99999999"
                        type="text"
                        disabled={!!infoUser.companyEdrpou.visible}
                        component={CustomInputMask}
                      />
                    </div>
                    <div className="">
                      <Typography
                        className={s.InpunTitle}
                        variant="body2"
                      >
                        Адреса поставки
                      </Typography>

                      <Field
                        placeholder="Введіть адресу поставки"
                        name="address"
                        id="address"
                        type="text"
                        disabled={!!infoUser.address.visible}
                        component={CustomInput}
                      />
                    </div>
                    <div className="">
                      <Typography
                        className={s.InpunTitle}
                        variant="body2"
                      >
                        Сайт
                      </Typography>

                      <Field
                         placeholder="Введіть адресу сайту"
                   
                        name="website"
                        id="website"
                        type="text"
                        disabled={!!infoUser.webSite.visible}
                        component={CustomInput}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </Box>
            <Box>
              <Button
                className={s.btn_standart}
                type="submit"
                // onSubmit={onSubmit}
              >
                Зберегти дані
              </Button>
              <Button onClick={isOpenBox}>Змінити пароль</Button>
            </Box>
          </Form>
        </Formik>
      </Box>
      {open && (
        <Box p={3} className={s.setings}>
          <ChengPassword userId={infoUser.idUser}></ChengPassword>
        </Box>
      )}
    </>
  );
};
