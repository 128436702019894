import React, { useEffect, useContext, useState } from 'react';
import s from './twoStepOrder.module.scss';
import { Box, Typography, Button } from '@material-ui/core';
import CreateInfoOrder from '../CreateOrder/CreateInfoOrderContext';
import { ModalOrder } from '../ModalOrder/ModalOrder';
import { TableOrder } from '../TableOrder/TableOrder';
import { propOr } from 'ramda';
import * as Api from './../../../api';
import { useSnackbar } from 'notistack';
import { getUserInfoOrder } from '../ThreeStepOrder/getUserInfoOrder';
import storageService from '../../../utils/storageService';
import localStorageKeys from '../../../constants/localStorageKeys';
import { useStore } from '../../../stores/stores';
import { useHistory } from 'react-router-dom';
import { privateRoutes } from '../../../constants/routes';

export const TwoStepOrder = ({ ...props }) => {
  const history = useHistory();

  const { newOrder } = useContext(CreateInfoOrder);
  const { users } = useStore();

  const {
    AuthUser,
    isLoaderOrderNomenclatures,
    copyNomenclature,
    deleteNomenclature,
    eleteNomenclature,
    onNomenclature,
    onDirections,
    setActiveStep,
  } = props;

  const [open, setOpen] = useState({ isOpen: false });

  const handleOpen = () => {
    setOpen({ isOpen: true });
  };

  const handleClose = () => {
    setOpen({ isOpen: !open.isOpen });
  };

  const handleOpenEdit = (nomenclatureId) => () => {
    // storageService.set('nomenclatureId', nomenclatureId);
    setOpen({
      isOpen: true,
      nomenclature: nomenclatureId,
    });
  };

  function OrderNomenclature(Orders) {
    const temsArray = [];

    Orders.nomenclatures.map((item) => {
      temsArray.push({
        idNomenclature: 1,
        manager: item.nomenclature.nomenclature.name,
        direction: item.nomenclature.direction.name,
        nomenclature: item.nomenclature.name,
        Count: `${item.item} ${item.nomenclature.unit.name}`,
        date: item.date,
        address: item.address,
      });
    });

    return temsArray;
  }

  const defaultData = {
    columns: [
    
      {
        Header: 'Дата створення',
        accessor: 'createdData',
      },
      {
        Header: 'Номенклатура',
        accessor: 'nomenclature',
      },
      {
        Header: 'Напрям',
        accessor: 'direction',
      },
      {
        Header: 'Кількість',
        accessor: 'Count',
      },
      {
        Header: 'Дата поставки',
        accessor: 'date',
      },
      {
        Header: 'Адреса поставки',
        accessor: 'address',
      },
      {
        Header: 'Коментар та файл',
        accessor: 'comment_and_file',
      },
      {
        Header: 'Менеджер',
        accessor: 'manager_info',
      },
      {
        Header: 'Дії',
        accessor: 'actions',
      },
    ],

    data: [],
  };

  const [dataTable, setDataTable] = useState(defaultData);

  useEffect(() => {
    console.log(newOrder );
    
    const temsArray = [];
    let increment = 1;
    newOrder.nomenclatures.map((item) => {
      temsArray.push({
        idNomenclature: item.id,
        manager: item.nomenclature.manager.full_name,
        direction:
          item.nomenclature.direction != null
            ? item.nomenclature.direction.name
            : '',
        nomenclature: item.nomenclature.name,
        Count: `${item.amount} ${item.nomenclature.unit.name}`,
        date: item.date,
        createdData: item.created,
        address: item.address,
        comment_and_file:{nomenclature_id:item.id},
        manager_info: {
          phone_number: propOr('', 'phone_number', item),
          full_name: propOr('', 'full_name', item),
          email: propOr('', 'email', item),
          id: propOr('', 'id', item.nomenclature.manager),
        },

        actions: { orderId: item.id },
      });
      increment++;
    });

    setDataTable({
      ...dataTable,
      data: temsArray,
    });
  }, [newOrder]);


  function NextStep() {
    setActiveStep(2);
  }

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const { enqueueSnackbar } = useSnackbar();
  let infoUser = getUserInfoOrder({ AuthUser, newOrder });

  function orderCreating() {
    const userID = storageService.get(localStorageKeys.USER_ID);
    let value = {};
    value.order_id = newOrder.orderID;

    if (newOrder.orderID) {
      Promise.resolve(Api.orderFinishStep(value))
        .then((result) => {
          result.data.messages.map((item) =>
            infoMassege(item.status, item.text),
          );
          return users.fetchUser(userID);
        })
        .then((result) => {
          AuthUser.role_code === 'ap'
            ? history.push('/statuses/valuating/')
            : history.push(privateRoutes.HOME);
        })
        .catch((result) => {
          console.log(result);
        });
    }
  }

  return (
    <>
      <Box>
        <Button
          onClick={handleOpen}
          variant="outlined"
          style={{ fontWeight: '600', margin: '12px 0px 32px' }}
        >
          Додати номенклатуру
        </Button>
      </Box>
      <Box>
        <Typography variant="h5" className={s.SubMainTitle}>
          Товари для розцінки
        </Typography>
      </Box>
      <div className="">
        <div className={s.table_wrap}>
          {!isLoaderOrderNomenclatures ? (
            <TableOrder
              handleOpenEdit={handleOpenEdit}
              className={s.table_box}
              columns={dataTable.columns}
              data={dataTable.data}
              deleteNomenclature={deleteNomenclature}
              copyNomenclature={copyNomenclature}
              AuthUser={AuthUser}
            />
          ) : (
            <>Завантаження номенклатур ...</>
          )}
        </div>
        <Box className={s.fotterControl} px={3} px={2}>
          <Typography
            className={s.fotterControlText}
            variant="subtitle2"
          >
            Якщо Ваше замовлення готове, відправте на розцінення
            відповідальному менеджеру, натиснувши кнопку
            {propOr(
              false,
              'concluded',
              AuthUser.client_profile.company,
            )
              ? ` "ОФОРМИТИ ЗАМОВЛЕННЯ"`
              : ` "ДАЛІ"`}
          </Typography>
          {}
          {propOr(
            false,
            'concluded',
            AuthUser.client_profile.company,
          ) ? (
            <Button className={s.orderStep} onClick={orderCreating}>
              Оформити замовлення
            </Button>
          ) : (
            <Button className={s.NextStep} onClick={NextStep}>
              Далі
            </Button>
          )}
        </Box>
      </div>

      <ModalOrder
        open={open}
        AuthUser={AuthUser}
        onNomenclature={onNomenclature}
        onDirections={onDirections}
        handleClose={handleClose}
      ></ModalOrder>
    </>
  );
};
