import React, { useContext } from 'react';
import s from './ThreeStepOrder.module.scss';
import { Button, Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { CustomInput } from '../../Form/Elements/input/input';
import * as Yup from 'yup';
import * as Api from '../../../api';
import CreateInfoOrder from '../CreateOrder/CreateInfoOrderContext';
import storageService from '../../../utils/storageService';
import { privateRoutes } from '../../../constants/routes';
import localStorageKeys from '../../../constants/localStorageKeys';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../../stores/stores';
import { useSnackbar } from 'notistack';
import { CustomInputMask } from '../../Form/Elements/inputMask/inputMask';
import { edrpoValidation } from '../../../utils/validations';
import { getUserInfoOrder } from './getUserInfoOrder';

export const ThreeStepOrder = ({ ...props }) => {
  const history = useHistory();
  const { setOrderInfo, ...orderInfo } = useContext(CreateInfoOrder);
  const { directions, nomenclature, newOrder, manager } = orderInfo;

  const { users } = useStore();
  const AuthUser = users.authUser;

  function notHasOrders(event) {
    // console.log(event);

    event.preventDefault();
    infoMassege(
      'warning',
      'У вас більше немає безкоштовних замовлень, укладіть договір щоб продовжити роботу на сайті',
    );
    infoMassege(
      'warning',
      'Ваше замовлення переведено в статус "Чернетка"',
    );
  }

  const { onTwoStep } = props;
  let infoUser = {};

  console.log(newOrder);

  infoUser = getUserInfoOrder({ AuthUser, newOrder });

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    company_name: infoUser.companyName.value,
    edrpou: infoUser.companyEdrpou.value,
    full_name: infoUser.full_name.value,
    phone_number: infoUser.phone_number.value,
    email: infoUser.email.value,
    address: infoUser.address.value,
    website: infoUser.webSite.value,
  };
  const error = {
    form: {
      required: "Поле обов'язкове для заповнення",
    },
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required(error.form.required),
    edrpou: edrpoValidation('Код ЄДРПОУ повинен складатись з 8 цифр')
      .required("Поле обов'язкове для заповнення")
      .min(8, 'Код ЄДРПОУ повинен складатись з 8 цифр'),
    full_name: Yup.string().required(error.form.required),
    phone_number: Yup.string().required(error.form.required),
    email: Yup.string()
      .required("Поле обов'язкове для заповнення")
      .email('Введіть E-mail адресу'),
    address: Yup.string().required(error.form.required),
    website: Yup.string(),
  });

  function onSubmit(value) {
    const userID = storageService.get(localStorageKeys.USER_ID);

    value.user_id = Number(userID);
    value.order_id = newOrder.orderID;

    if (newOrder.orderID) {
      Promise.resolve(Api.orderFinishStep(value))
        .then((result) => {
          result.data.messages.map((item) =>
            infoMassege(item.status, item.text),
          );
          return users.fetchUser(userID);
        })
        .then((result) => {
          AuthUser.role_code === 'ap'
            ? history.push('/statuses/valuating/')
            : history.push(privateRoutes.HOME);
        })
        .catch((result) => {
          console.log(result);
        });
    }
  }

  return (
    <>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <div className={s.form_wrap}>
              <Field
                placeholder="Введіть назву компанії"
                name="company_name"
                id="company_name"
                disabled={!!infoUser.companyName.visible}
                type="text"
                component={CustomInput}
              />

              <Field
                placeholder="Введіть код  ЄДРПОУ"
                name="edrpou"
                id="edrpou"
                // maskPlaceholder="_"
                mask="99999999"
                type="text"
                disabled={!!infoUser.companyEdrpou.visible}
                component={CustomInputMask}
              />
              <Field
                placeholder="Введіть прізвище, ім'я по батькові"
                name="full_name"
                id="full_name"
                disabled={!!infoUser.full_name.visible}
                type="text"
                component={CustomInput}
              />
              <Field
                placeholder="Введіть телефон"
                name="phone_number"
                id="phone_number"
                disabled={!!infoUser.phone_number.visible}
                mask="+380(99)999-99-99"
                type="tel"
                type="text"
                component={CustomInputMask}
              />
              <Field
                placeholder="Введіть email"
                name="email"
                disabled={!!infoUser.email.visible}
                id="email"
                type="text"
                component={CustomInput}
              />
              <Field
                placeholder="Введіть адреса поставки"
                name="address"
                disabled={!!infoUser.address.visible}
                id="address"
                type="text"
                component={CustomInput}
              />
              <Field
                placeholder="Сайт"
                name="website"
                disabled={!!infoUser.webSite.visible}
                id="website"
                type="text"
                component={CustomInput}
              />
            </div>

            <Box className={s.butoon__order_wrap}>
              <Button className={s.buttonBack} onClick={onTwoStep}>
                Повернутись назад
              </Button>
              {AuthUser.client_profile.can_make_order === true ? (
                <Button
                  type="submit"
                  onSubmit={onSubmit}
                  className={s.butoon__order}
                >
                  Відправити на розцінення
                </Button>
              ) : (
                <Button
                  onClick={notHasOrders}
                  className={s.buttonDisable}
                >
                  Відправити на розцінення
                </Button>
              )}
            </Box>
          </Form>
        </Formik>
      </div>
    </>
  );
};
