import React, { useState } from 'react';
import cx from 'classnames';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/uk';
import isSameDay from 'date-fns/isSameDay';
import moment from 'moment';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import format from 'date-fns/format';
import './InputDataPicer.scss';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { makeJSDateObject } from './utils';
import { compareAsc } from 'date-fns';
import { useFormikContext } from 'formik';

const styles = (theme) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 24,
    height: 24,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  currentDay: {
    color: 'red',
  },
  CurrentMonthDay: {
    fontSize: '14px',
    borderRadius: '2px',
    color: '#36373a',
    transition: '.3s',
    '&:hover': {
      transition: '.3s',
      borderRadius: '2px',
      background: '#d5d9e8',
    },
  },
  nonCurrentMonthDay: {
    fontSize: '14px',
    color: '#b4b6ba',
    background: 'none',
    cursor: 'default',
    '&:hover': {
      transition: '.3s',
      borderRadius: '2px',
      background: 'none',
      cursor: 'default',
    },
  },
  notWrapCurrentMonthDay: {
    background: 'none',
    cursor: 'default',
    '&:hover': {
      transition: '.3s',
      borderRadius: '2px',
      background: 'none',
      cursor: 'default',
    },
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    borderRadius: '2px',
    background: ' #2e408a',
    borderRadius: 2,
    color: theme.palette.common.white,
  },
});

const InputDataPicer = ({field: { name },classes,...props}) => {
  DateFnsUtils.prototype.getCalendarHeaderText = function (date) {
    return format(date, 'LLLL yyyy', { locale: this.locale });
  };  

  const [isOpen, setIsOpen] = useState(false);

  const { setFieldValue } = useFormikContext();

  const renderWrappedWeekDay = (
    date,
    selectedDate,
    dayInCurrentMonth,
  ) => {
    const today = new Date();
    const isSelectedDate = isSameDay(selectedDate, date);
    const isToday = isSameDay(today, date);
    const isMinActiveDate = isSameDay(
      props.selectedDate.minData,
      date,
    );
    const isMinDate =
      compareAsc(props.selectedDate.minData, date) > 0 ? true : false;

    const wrapperClassName = cx({
      [classes.highlight]: isSelectedDate,
      [classes.notWrapCurrentMonthDay]: !dayInCurrentMonth,
    });

    const dayClassName = cx(classes.day, {
      [classes.currentDay]: isToday,
      [classes.nonCurrentMonthDay]:
        (isMinDate || !dayInCurrentMonth) && !isMinActiveDate,

      [classes.CurrentMonthDay]: dayInCurrentMonth && !isSelectedDate,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(date, 'dd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <div className="input_dataPicker">
      <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
        <DatePicker
          className="dataPicer"
          disablePast
          disableToolbar
          // ToolbarComponent={(props) => (<span>RS Custom</span>)}
          format="dd.MM.yyyy"
          margin="normal"
          id="dataPicer"
          variant="inline"
          fullWidth
          minDate={props.selectedDate.minData}
          name="date"
          value={props.selectedDate.selectData}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          onChange={(val) => {
            setFieldValue(name, val);
            props.setSelectedDate({
              ...props.selectedDate,
              selectData: val,
            });
            setIsOpen(false);
          }}
          renderDay={renderWrappedWeekDay}
        />
      </MuiPickersUtilsProvider>
      {/* {touched[field.name] && errors[field.name] && (
          <div className="input_error">{errors[field.name]}</div>
        )} */}
    </div>
  );
};

export default withStyles(styles)(InputDataPicer);
