import React from 'react';
import s from './RowManagerInfo.module.scss';
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import { propOr } from 'ramda';
import { Link } from 'react-router-dom';
export const RowManagerInfo = ({ ...props }) => {
  const { row, role,viewsManagerCompani } = props;
  
  return (
    <>
      <TableRow>
        <TableCell>
          <Box display="flex">
            <Box mr={2}>
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            </Box>
            <Box className={s.userInfo}>
              <Link to={`/settings/${row.id}`}>
                <Typography
                  variant="body2"
                  className={`${s.name}  ${s.link}`}
                >
                  {row.full_name}
                </Typography>
              </Link>

              {row.phone_number && (
                <Typography variant="subtitle2" className={s.phone}>
                  {row.phone_number}
                </Typography>
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <a className={s.link} href={`mailto:${row.email}`}>
            {row.email}
          </a>{' '}
        </TableCell>
        {role === 'client' && (
          <TableCell>
            {propOr('', 'address', row.client_profile)}
          </TableCell>
        )}

        <TableCell>
          {role === 'client' &&
            propOr('0', 'orders_amount', row.client_profile)}
          {role === 'manager' &&
            propOr('0', 'orders_amount', row.manager_profile)}
        </TableCell>
        
        <TableCell>
          {viewsManagerCompani ? (
            <Link to={`/companies/manager/${row.id}`}>
            <Button
              variant="outlined"
              style={{ fontWeight: '600', margin: '10px 0px' }}
            >
              Детальніше
            </Button>
          </Link>
          ):(
            <Link to={`/manager/${role==="manager"?row.manager_profile.id:row.client_profile.id}`}>
            <Button
              variant="outlined"
              style={{ fontWeight: '600', margin: '10px 0px' }}
            >
              Детальніше
            </Button>
          </Link> 
          )}
           
        </TableCell>
      </TableRow>
    </>
  );
};
