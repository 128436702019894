import React, { Children } from 'react';
import {
  Modal,
  Fade,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Closse } from '../../../assetc/svg/Closse';

import s from './ModalCreate.module.scss';

export const ModalCreateManager = ({ ...props }) => {
  const { open, onClose, handleClose } = props;
  //   open.isOpen
  return (
    <>
      <Modal open={open.isOpen} onClose={handleClose}>
        <Fade in={open.isOpen}>
          <div className={s.ModalWrapBox}>
            <div className={s.modal}>
              <div className={s.modalHeader}>
                <Typography variant="h3">Новий менеджер</Typography>
                {true ? (
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <Closse color="#5866a1" width="18px"></Closse>
                  </IconButton>
                ) : null}
              </div>
            </div>
            <div className={s.modalBox}>{props.children}</div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
