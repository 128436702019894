import React from 'react';
import './TableOrder.scss';
import { useTable } from 'react-table';
import {
  Avatar,
  Typography,
  IconButton,
  Box,
  Popover,
  Button,
} from '@material-ui/core';
import { EditIcon } from '../../../assetc/svg/editIcon';
import { CopyIcon } from '../../../assetc/svg/copyIcons';
import { DeleteIcon } from '../../../assetc/svg/deleteIcon';
import { CardManager } from '../CardManagerOneStep/CardManagerOneStep';
import { CardManagerAll } from '../CardManagerAll/CardManagerAll';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { useState } from 'react';
import { Chat } from '../../Chat/Chat';

export const TableOrder = ({
  copyNomenclature,
  deleteNomenclature,
  handleOpenEdit,
  columns,
  data,
  className,
  AuthUser,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const [anchorEl, setAnchorEl] = React.useState({});
  const handleClick = (event, popoverId) => {
    setAnchorEl({
      openedPopoverId: popoverId,
      anchorEl: event.currentTarget,
    });
  };
  const handleClose = () => {
    setAnchorEl({ openedPopoverId: null, anchorEl: null });
  };
  const open = Boolean(anchorEl);

  // modall comment and file
  const [openModalChat, setOpenModalChat] = useState({
    isOpen: false,
  });

  const [modalChat, setModalChat] = React.useState({});
  const handleOpenChat = (event, popoverId, nomenclature_id) => {
    setModalChat({
      openedPopoverId: popoverId,
      isOpen: true,
      anchorEl: event.currentTarget,
      nomenclature_id: nomenclature_id,
    });
  };
  const handleCloseChat = () => {
    setModalChat({
      openedPopoverId: null,
      isOpen: false,
      anchorEl: null,
    });
  };

  function isOpenModalChat(expresio) {}

  return (
    <>
      <table className={`${className} `} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id == 'comment_and_file') {
                    return (
                      <td {...cell.getCellProps()}>
                        <div className="idNomenclatureBox">
                          <Button
                            type="button"
                            onClick={(e) => {
                              handleOpenChat(
                                e,
                                i,
                                cell.row.id == i &&
                                  cell.value.nomenclature_id,
                              );
                            }}
                          >
                            Переглянути
                          </Button>

                          {modalChat.openedPopoverId === i && (
                            <ModalWindow
                              open={
                                modalChat.openedPopoverId === i
                                  ? modalChat
                                  : modalChat
                              }
                              handleClose={handleCloseChat}
                              titleWindow="Коментарі та файли"
                            >
                              <Chat
                              AuthUser={AuthUser}
                                nomenclature_id={
                                  modalChat.nomenclature_id
                                }
                              ></Chat>
                            </ModalWindow>
                          )}
                        </div>
                      </td>
                    );
                  } else if (cell.column.id == 'manager_info') {
                    return (
                      <td {...cell.getCellProps()}>
                        <div className="managerBox">
                          <Avatar className="avatarManager"></Avatar>
                          <Typography
                            className="linkManager"
                            onClick={(e) => {
                              handleClick(e, i);
                            }}
                          >
                            {cell.value.full_name}
                          </Typography>
                        </div>
                        <div>
                          <Popover
                            open={anchorEl.openedPopoverId === i}
                            anchorEl={anchorEl.anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Typography>
                              <Box>
                                <CardManagerAll
                                  titleText="Менеджер напряму"
                                  AuthUser={AuthUser}
                                  manager={cell.value}
                                ></CardManagerAll>
                              </Box>
                            </Typography>
                          </Popover>
                        </div>
                      </td>
                    );
                  } else if (cell.column.id == 'actions') {
                    return (
                      <td {...cell.getCellProps()}>
                        <Box className="OrdersActions">
                          <IconButton
                            className="OrdersActionsBtn"
                            onClick={handleOpenEdit(
                              cell.value.orderId,
                            )}
                          >
                            <EditIcon
                              display="block"
                              size="18px"
                            ></EditIcon>
                          </IconButton>
                          <IconButton
                            className="OrdersActionsBtn"
                            onClick={copyNomenclature({
                              nomenclature_id: cell.value.orderId,
                            })}
                          >
                            <CopyIcon
                              display="block"
                              size="18px"
                            ></CopyIcon>
                          </IconButton>
                          <IconButton
                            className="OrdersActionsBtn"
                            onClick={deleteNomenclature(
                              cell.value.orderId,
                            )}
                          >
                            <DeleteIcon
                              display="block"
                              size="18px"
                            ></DeleteIcon>
                          </IconButton>
                        </Box>
                      </td>
                    );
                  } else if (cell.column.id == 'idNomenclature') {
                    return (
                      <td {...cell.getCellProps()}>
                        <div className="idNomenclatureBox">
                          {cell.render('Cell')}
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
