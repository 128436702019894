import React, { useContext, useState } from 'react';
import s from './CreateOrderProduct.module.scss';
import { Button, Typography } from '@material-ui/core';
// import { CustomInput } from '../Elements/input/input';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { CustomInput } from '../../Form/Elements/input/input';
import { CustomInputMask } from '../../Form/Elements/inputMask/inputMask';

import * as Api from '../../../api';
import { useSnackbar } from 'notistack';
import { updateManager } from '../ViewsAllManager/managerFunctions';

export const CreateManager = ({ ...props }) => {
  const {
    company_id,
    handleClose,
    filterValue,
    tableData,
    setTablerData,
  } = props;

  const initialValues = {
    company_id: company_id,
    full_name: '',
    phone_number: '',
    email: '',
    address: '',
    website: '',
    // rule: 'client',
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
    phone_number: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
    email: Yup.string()
      .required("Поле обов'язкове для заповнення ")
      .email('Введіть E-mail адресу'),
    address: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
    website: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
  });

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const { enqueueSnackbar } = useSnackbar();

  function onSubmit(value) {
     

    Promise.resolve(Api.createManager(value)).then((result) => {
      result.data.messages.map((item) =>
        infoMassege(item.status, item.text),
      );
      handleClose();
   
      if (filterValue !== undefined && tableData !== undefined) {
        updateManager({ filterValue, tableData, setTablerData });
      }
    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={s.Form_wrap}
      >
        <Form>
          <div className={s.Form_wrap}>
            <div>
              {/* <Typography className={s.InpunTitle} variant="body1">
                Прізвище, ім'я по батькові
              </Typography> */}

              <Field
                placeholder="Прізвище, ім'я по батькові"
                name="full_name"
                id="full_name"
                type="text"
                component={CustomInput}
              ></Field>
            </div>
            <div>
              {/* <Typography className={s.InpunTitle} variant="body1">
                Телефон
              </Typography> */}

              <Field
                placeholder="Телефон"
                name="phone_number"
                id="phone_number"
                mask="+380(99)999-99-99"
                type="tel"
                type="text"
                component={CustomInputMask}
              />
            </div>
            <div>
              {/* <Typography className={s.InpunTitle} variant="body1">
                Email
              </Typography> */}
              <Field
                placeholder="Email"
                name="email"
                id="email"
                type="text"
                component={CustomInput}
              />
            </div>

            <div>
              {/* <Typography className={s.InpunTitle} variant="body1">
                Адреса поставки
              </Typography> */}

              <Field
                placeholder="Адреса поставки"
                name="address"
                id="address"
                type="text"
                component={CustomInput}
              />
            </div>
            <div>
              {/* <Typography className={s.InpunTitle} variant="body1">
                Сайт
              </Typography> */}

              <Field
                placeholder="Сайт"
                name="website"
                id="website"
                type="text"
                component={CustomInput}
              />
            </div>
          </div>
          <div className={s.butoon__order_wrap}>
            <Button className={s.butoon__order} type="submit">
              Створити
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
