import React from 'react';
import s from './TableManagerAll.module.scss';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { RowManagerInfo } from '../RowManagerInfo/RowManagerInfo';

export const TableManagerAll = ({ ...props }) => {
  const {
    onClick,
    role,
    nomenclatures,
    tableData,
    setTablerData,
    viewsManagerCompani,
    filterValue,
    setFilterValue,
  } = props;

  const handleChangePage = (event, newPage) => {
    setFilterValue({ ...filterValue, page_number: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterValue({
      ...filterValue,
      page_size: Number(event.target.value),
    });
  };
  const handleLineLabels = ({ from, to, count, page }) => {
    return `${from}-${to} з ${count !== -1 ? count : to}`;
  };

  console.log(filterValue);

  return (
    <>
      <TableContainer>
        <Table className={s.TableManagers}>
          <TableHead>
            <TableRow>
              <TableCell>Менеджер</TableCell>
              <TableCell>E-mail</TableCell>
              {role === 'client' && <TableCell>Адреса</TableCell>}

              <TableCell>Замовлення</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.managers.map((item) => (
              <RowManagerInfo viewsManagerCompani={viewsManagerCompani} role={role} row={item} />
            ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={tableData.allCount}
            rowsPerPage={filterValue.page_size}
            labelRowsPerPage="Менеджерів на сторінці"
            labelDisplayedRows={handleLineLabels}
            page={filterValue.page_number - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </>
  );
};
