import React from 'react';
import s from './TableCompaniesAll.module.scss';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { TableCompaniesRow } from '../TableCompaniesRow/TableCompaniesRow';

export const TableCompaniesAll = ({ ...props }) => {
  const {
    onClick,
    role,
    nomenclatures,
    tableData,
    setTablerData,
    filterValue,
    setFilterValue,
  } = props;

  const handleChangePage = (event, newPage) => {
    setFilterValue({ ...filterValue, page_number: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterValue({
      ...filterValue,
      page_number:1,
      page_size: Number(event.target.value),
    });
  };
  const handleLineLabels = ({ from, to, count, page }) => {
    return `${from}-${to} з ${count !== -1 ? count : to}`;
  };

  return (
    <>
      <TableContainer>
        <Table className={s.TableManagers}>
          <TableHead>
            <TableRow>
              <TableCell>Компанії</TableCell>
              <TableCell>Менеджер</TableCell>
              <TableCell>ЄДРПОУ</TableCell>
              <TableCell>Договір</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.companies.map((item) => (
              <TableCompaniesRow row={item} />
            ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={tableData.allCount}
            rowsPerPage={filterValue.page_size}
            labelRowsPerPage="Менеджерів на сторінці"
            labelDisplayedRows={handleLineLabels}
            page={filterValue.page_number - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </>
  );
};
