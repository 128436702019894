import React from 'react';
import s from './ChengeStatus.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
// import Select from 'react-select';
import * as Api from '../../api';
import { updateOrders } from '../evaluationNomenclature/statusProcessingOrders/updateOrdersApi';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    '&:before, &:after': {
      border: 'none',
    },
    '&:hover': {
      '&:before, &:after': {
        border: 'none !important',
      },
    },
  },
}));

export const ChengeStatus = ({ ...props }) => {
  const classes = useStyles();
  const {
    setTablerData,
    filterValue,
    value,
    chengeValue,
    orderId,
  } = props;

  let statusValue = {
    label: getStatus(value).text,
    value: getStatus(value).text,
  };
  let nextValue = {
    label: getStatus(chengeValue).text,
    value: getStatus(chengeValue).text,
  };

  const handleChange = (event) => {
    Api.chengeOrder(orderId, { status: event.target.value }).then(
      (res) => {
        updateOrders(filterValue, setTablerData);
      },
    );
  };

  return (
    <>
      <Box className={s.selectBox}>
        <Select
          displayEmpty
          defaultValue={getStatus(value).classes}
          onChange={handleChange}
          className={classes.selectEmpty}
        >
          <MenuItem disabled value={getStatus(value).classes}>
            {getStatus(value).text}
          </MenuItem>

          <MenuItem value={getStatus(chengeValue).classes}>
            {getStatus(chengeValue).text}
          </MenuItem>
        </Select>
      </Box>
    </>
  );
};

function getStatus(key_status) {
  const statusOBJ = {
    unordered: {
      text: 'Чернетка',
      classes: 'unordered',
    },
    agreed: {
      text: 'Погоджено',
      classes: 'agreed',
    },
    valuating: {
      text: 'Розцінюється',
      classes: 'valuating',
    },
    valuated: {
      text: 'Розцінено',
      classes: 'valuated',
    },
    in_progress: {
      text: 'Опрацьовано',
      classes: 'in_progress',
    },
    agreed_without_contract: {
      text: 'Погоджено, очікує на договір',
      classes: 'agreed_without_contract',
    },
    contract_sent: {
      text: 'Договір відправлено',
      classes: 'contract_sent',
    },
    contract_concluded: {
      text: 'Договір укладено',
      classes: 'contract_concluded',
    },
    declined: {
      text: 'Відмовлено',
      classes: 'declined',
    },
    finished: {
      text: 'Заактовано',
      classes: 'finished',
    },
    unactive: {
      text: 'Неактивне(ігнор 15 днів)',
      classes: 'unactive',
    },
  };

  return statusOBJ[key_status];
}
