import React from 'react';
import s from './TableCompaniesRow.module.scss';
import {
  TableCell,
  Button,
  TableRow,
  Avatar,
  Typography,
  Box,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { CheckCirckleSVG } from '../../../assetc/svg/check-circkle';
import { TimeSolid } from '../../../assetc/svg/times-solid';

export const TableCompaniesRow = ({ ...props }) => {
  const { row } = props;

  return (
    <>
      <TableRow>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <Box display="flex">
            <Box mr={2}>
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            </Box>
            <Box className={s.userInfo}>
              {!!row.admin && (
                <>
                  <Link to={`/settings/${row.admin.id}`}>
                    <Typography
                      variant="body2"
                      className={`${s.name}  ${s.link}`}
                    >
                      {row.admin.full_name}
                    </Typography>
                  </Link>
                  {row.admin.phone_number && (
                    <Typography
                      variant="subtitle2"
                      className={s.phone}
                    >
                      {row.admin.phone_number}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell>{row.edrpou}</TableCell>

        <TableCell>
          {}
          {row.concluded ? (
            <CheckCirckleSVG
              size="20px"
              color="#2fa372"
            ></CheckCirckleSVG>
          ) : (
            <TimeSolid size="20px" color="#c11e02"></TimeSolid>
          )}
        </TableCell>
        <TableCell>
          <Link to={`/companies/${row.id}`}>
            <Button
              variant="outlined"
              style={{ fontWeight: '600', margin: '10px 0px' }}
            >
              Детальніше
            </Button>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
