import React from 'react';

import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { UnregisterForm } from '../../components/forms/UnregisterForm';


import { useStore } from '../../stores/stores';
import { privateRoutes } from '../../constants/routes';

function Unregister() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    auth: { unregisterUser },
  } = useStore();

  const onSubmit = async (values) => {
    await unregisterUser.run(values);

    if (unregisterUser.isError) {
      return enqueueSnackbar(unregisterUser.errorMessage, {
        variant: 'error',
      });
    }
    if (!unregisterUser.error) {
      enqueueSnackbar("Ваша заявка надіслана, менеджер з вами зв'яжеться", {
        variant: 'success',
      });
      enqueueSnackbar("Через 5 секунд вас перенаправить на головну сторінку", {
        variant: 'success',
      });
    }

    setTimeout(() => {
      const websiteUrl = 'https://puz.supply';
      window.location = websiteUrl;
    }, 5000);


    // return history.push(privateRoutes.HOME);
  };

  return (
    <AuthLayout>
      <UnregisterForm
        isLoading={unregisterUser.inProgress}
        onSubmit={onSubmit}
      />
    </AuthLayout>
  );
}
export default observer(Unregister);
