import React from 'react';
import s from './TableOrder.module.scss';
import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import { TableOrderRow } from '../TableOrderRow/TableOrderRow';

import PropTypes from 'prop-types';

function TablePaginationActions(props) {
  const {
    deleteOrder,
    editOrder,
    copyOrder,
    count,
    page,
    rowsPerPage,
    onChangePage,
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(
      event,
      Math.max(0, Math.ceil(count / rowsPerPage) - 1),
    );
  };

  return <div></div>;
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const TableOrder = ({ ...props }) => {
  let { copyOrder, rows,AuthUser } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {/* <TableCell>#ID</TableCell> */}
              <TableCell>
                <div>№ замовлення</div>
              </TableCell>
              <TableCell>Дата створення</TableCell>
              <TableCell>Номенклатура</TableCell>
              <TableCell>Напрям</TableCell>
              <TableCell>К-сть</TableCell>
              <TableCell>Дата поставки</TableCell>
              <TableCell>Адреса поставки</TableCell>
              <TableCell>Менеджер</TableCell>
              <TableCell>Коментар та файл</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Дія</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : rows
            ).map((row, index) => (
              <TableOrderRow
                copyOrder={copyOrder}
                key={row.id}
                row={row}
                AuthUser={AuthUser}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={11}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // labelDisplayedRows={({from,to,count})=>{`${from}-${to} з ${count !== -1 ? count :more than ${to}}`}}
                labelRowsPerPage="Замовлень на сторінці"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
