import React, { useState, useEffect } from 'react';
import s from './priceNomenclatures.module.scss';
import { Box, Button } from '@material-ui/core';
import { propOr } from 'ramda'; 
import * as Api from '../../../api';
import { BaseFilter } from '../../baseFilter/baseFilter';
import { fetchDataFilters } from '../../baseFilter/apiFetchDataFilters';
import { TableNomanclaturesManager } from '../tableNomanclaturesManager/tableNomanclaturesManager';
import { fetchDataNomenclatureTable } from '../tableNomanclaturesManager/fetchTableDate';

import { PricingWindow } from '../pricingWindow/pricingWindow';
import storageService from '../../../utils/storageService';
import { Loader } from '../../Loader/Loader';

export const PriceNomenclatures = ({ ...props }) => {
  const { AuthUser, statuses } = props;

  let manager_id =
    AuthUser.role_code !== 'ap' ? AuthUser.manager_profile.id : '';
 
  const initValue = {
    manager_profile_id: manager_id,
    company_id: '',
    direction_id: '',
    // date: '',
    viewer_id:AuthUser.id,
    date_to: '',
    date_from: '',
    statuses: [statuses],
    page_number: 1,
    page_size: 5,
    // manager_profile_id: AuthUser.manager_profile.id,
  };
  const tableValue = {
    nomenclature: [],
  };
  const [filterValue, setFilterValue] = useState(initValue);
  const [filterData, setFilterData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [tableData, setTablerData] = useState(tableValue);
  const [open, setOpen] = useState({ isOpen: false }); // modal розцінка

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(
      fetchDataFilters({
        manager_profile_id: manager_id,
        current_user_id: AuthUser.id,
        statuses:
          statuses === 'archive'
            ? ['finished', 'declined', 'unactive']
            : [statuses],
      }),
    )
      .then((result) => {
         
        setIsLoading(false);
        setFilterData(result);
      })
      .catch((result) => setIsLoading(false));
  }, []);

  useEffect(() => {
    console.log(filterValue );
    
    updateNomenclatures(filterValue, setTablerData);
  }, [filterValue]);

  // modal розцінка
  const handleOpen = (nomenclatureId) => () => {
    storageService.set('nomenclatureId', nomenclatureId);

    setOpen({
      isOpen: !open.isOpen,
      nomenclature: tableData.nomenclature.filter(
        (item) => item.id === nomenclatureId,
      )[0],
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onValuation(value) {
    Api.valurionNomenclatures(
      storageService.get('nomenclatureId'),
      value.price,
    ).then((item) => {
      updateNomenclatures(filterValue, setTablerData);
    });

    setOpen(false);
  }
 
  return (
    <>
      <>
        <Box className={s.tableMain}>
          <Box className="Header">
            <BaseFilter
              defaultValue={initValue}
              filterData={filterData}
              setFilterData={setFilterData}
              filterValue={filterValue}
              changefilterValue={setFilterValue}
            ></BaseFilter>
          </Box>
          <Box className="Main">
            {isLoading ? (
              <Box className={`${s.wrapLoad} ${s.wrapIsLoading}`}>
                <Box className={s.loadText}>
                  Завантаження номенклатур
                </Box>
                <Box className={s.wrapLoaderBox}>
                  <Loader className={s.loader}></Loader>
                </Box>
              </Box>
            ) : (
              <>
                {tableData.nomenclature < 1 ? (
                  <Box className={s.wrapLoad}>
                    <Box className={s.loadText}>
                      Замовлень з даним статусом не знайдено
                    </Box>
                  </Box>
                ) : (
                  <div className={s.TableNomenclature}>
                    <TableNomanclaturesManager
                      filterValue={filterValue}
                      tableData={tableData}
                      AuthUser={AuthUser}
                      setTablerData={setTablerData}
                      filterValue={filterValue}
                      setFilterValue={setFilterValue}
                      handleOpen={handleOpen}
                      statuses={statuses}
                    ></TableNomanclaturesManager>
                  </div>
                )}
              </>
            )}
          </Box>

          <PricingWindow
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            onValuation={onValuation}
          ></PricingWindow>
        </Box>
      </>
    </>
  );
};

function updateNomenclatures(filterValue, setTablerData) {
  console.log(filterValue );
  
  let filterValueNomenclature = {
    company_id: propOr('', 'id', filterValue.company_id),
    date: propOr('', 'date', filterValue.date),
    date_from: filterValue.date_from,
    date_to: filterValue.date_to,
    // date_from: propOr('', 'date', filterValue.date_from),
    // date_to: propOr('', 'date', filterValue.date_to),
    direction_id: propOr('', 'id', filterValue.direction_id),
    manager_profile_id: filterValue.manager_profile_id,
    page_number: filterValue.page_number,
    viewer_id: filterValue.viewer_id,
    page_size: filterValue.page_size,
    statuses: filterValue.statuses,
    manager_profile_id: filterValue.manager_profile_id,
  };

  Promise.resolve(
    fetchDataNomenclatureTable(filterValueNomenclature),
  ).then((result) => {
     console.log(result );
     
    setTablerData(result);
  });
}
