import * as Api from '../../../api';

export function updateManager({ ...props }) {
  const { tableData, filterValue, setTablerData } = props;
  console.log(filterValue );
  
  Promise.resolve(Api.getAllManagers(getUrl(filterValue))).then(
    (res) => {
      setTablerData({
        ...tableData,
        allCount: res.data.count,
        itemsPerPage: res.data.results.length,
        managers: res.data.results,
      });
    },
  );
}

function getUrl(data) {
    console.log( data);
    
  return Object.entries(data)
    .map(([key, val]) => {
      if (typeof val === 'object') {
        return `${encodeURIComponent(key)}=${JSON.stringify(val)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          val,
        )}`;
      }
    })
    .join('&');
}
