import React from 'react';
import  './CustomStatus.scss';
import { Box } from '@material-ui/core';

export const CustomStatus = (status) => {
 
 
  return (
    <>
      <Box className={ getStatus(status.status).classes}>
        { getStatus(status.status).text}
      </Box>
    </>
  );
};


function getStatus(key_status) {
  const statusOBJ = {
    unordered: {
      text: 'Чернетка',
      classes: 'unordered',
    },
    agreed: {
      text: 'Погоджено',
      classes: 'agreed', // темно зелений
    },
    valuating: {
      text: 'Розцінюється',
      classes: 'valuating',
    },
    valuated: {
      text: 'Розцінено',
      classes: 'valuated',
    },
    in_progress: {
      text: 'Опрацьовано',  // синій
      classes: 'in_progress',
    },
    agreed_without_contract: {
      text: 'Погоджено, очікує на договір', // оранживий
      classes: 'agreed_without_contract',
    },
    contract_sent: {
      text: 'Договір відправлено', // блакитний
      classes: 'contract_sent',
    },
 
    declined: {
      text: 'Відмовлено', 
      classes: 'declined',
    },
    finished: {
      text: 'Заактовано', // зелений
      classes: 'finished',
    },
    unactive: {
      text: 'Неактивне', // сірий
      classes: 'unactive',
    },
  };

 

  return statusOBJ[key_status];
}
