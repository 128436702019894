import React, { useRef } from 'react';
import s from './fileUpload.module.scss';
import PublishIcon from '@material-ui/icons/Publish';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export const FileUpload = ({ ...props }) => {
   
  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const { enqueueSnackbar } = useSnackbar();

  const { setUploadFile, uploadFile } = props;
 
  const fileInput = useRef(null);
// console.log('1' );

  const handleClick = () => {
    console.log('123' );
    console.log(fileInput.current );
    
    fileInput.current.click();
  };
  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded.name.split('.') );

    //проверка на формат
    let $file_type = fileUploaded.name.split('.').pop();
    console.log($file_type );

    var array = ['exe'];

    
    if (array.indexOf($file_type) === -1) {
      setUploadFile(fileUploaded);
    } else {
      // console.log('123' );
      
      infoMassege(
        'warning',
        `файли з розширенням ".${$file_type}" завантажити неможливо`,
      );
      // event.target=null;
    }
  };
  return (
    <>
      {!!uploadFile && (
        <div className={s.fileName}>
          {uploadFile.name}{' '}
          <CheckIcon style={{ color: green[500] }}></CheckIcon>
        </div>
      )}

      <label for="upload_file">
        <Button
          color="default"
          className={s.button}
          startIcon={<PublishIcon />}
          onClick={handleClick}
        >
          {!!uploadFile ? 'Змінити файл' : 'Завантажити файл'}
        </Button>
        <input
          ref={fileInput}
          className={s.upload_file}
          id="upload_file"
           
          type="file"
          onChange={handleFileChange}
        />
      </label>
    </>
  );
};
