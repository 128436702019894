import React from 'react';
import s from './TableManagerRequest.module.scss';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, 
  Box,
  Button,
  Avatar,
  Typography,
} from '@material-ui/core';
 
export const TableManagerRequest = ({ ...props }) => {
  const {
    onClick,
    nomenclatures,
    AuthUser,
    tableData,
    deleteManager,
    confirmManager,
    setTablerData,
    filterValue,
    setFilterValue,
  } = props;

  return (
    <>
      <TableContainer>
        <Table className={s.TableManagers}>
          <TableHead>
            <TableRow>
              <TableCell>Менеджер</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Адреса</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box display="flex">
                    <Box mr={2}>
                      <Avatar
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                    </Box>
                    <Box className={s.userInfo}>
                      <Typography variant="body2" className={s.name}>
                        {row.full_name}
                      </Typography>
                      {row.phone_number && (
                        <Typography
                          variant="subtitle2"
                          className={s.phone}
                        >
                          {row.phone_number}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell> {row.email}</TableCell>
                <TableCell> {row.address}</TableCell>

                <TableCell>
                  <Box>
                    <Button
                      onClick={deleteManager(row.id)}
                      className={s.btn_remove}
                      variant="outlined"
                    >
                      Відхилити
                    </Button>
                    <Button
                      onClick={confirmManager(row.id)}
                      className={s.btn_standart}
                      variant="outlined"
                    >
                      Прийняти
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
