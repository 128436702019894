import { apiRequest } from './utils';
import storageService from '../utils/storageService';
import localStorageKeys from '../constants/localStorageKeys';

export const companyManagerRequests = (company_id) => {
  return apiRequest({
    method: 'GET',
    url: `/company_manager_requests/?company_id=${company_id}`,
  });
};
export const getAllManagers = (data) => {
  return apiRequest({
    method: 'GET',
    url: `/project_users/?${data}`,
  });
};
export const getManagerId = (id) => {
  return apiRequest({
    method: 'GET',
    url: `/project_users/${id}/`,
  });
};
export const createManager = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/create_company_manager/`,
    data,
  });
};
export const changeCompanyManager = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/company_manager_requests/`,
    data,
  });
};

export const deleteCompanyManager = (data) => {
  return apiRequest({
    method: 'DELETE',
    url: `/company_manager_requests/`,
    data,
  });
};
export const sendManager = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/message_manager/`,
    data,
  });

  
};

export const downloadFile = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/export_orders/`,
    responseType: 'blob',
    data,
  });
};


