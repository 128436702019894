async function loadOptions(search, loadedOptions, ...props) {
    console.log('loadOptions -> loadedOptions', loadedOptions);
    let [
      { page_number, page_size, directionOld, directionNew },
    ] = props;
  
    directionOld = directionOld === undefined ? '' : directionOld;
  
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/paginated_nomenclatures/?page_size=${page_size}&page_number=${page_number}&search_query=${search}&direction_id=${directionNew}`,
    );
   
    const responseJSON = await response.json();
  
    let options = responseJSON.results.map((item) => {
      return { value: item.id, label: item.name, item };
    });
  
    return {
      options: options,
      hasMore: responseJSON.next !== null ? true : false,
      additional: {
        page_size: page_size,
        page_number: page_number + 1,
        directionOld: directionNew,
      },
    };
  }
  
  export default loadOptions;