import React from 'react';
export const EditIcon = ({ size, color, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      {...props}
      viewBox="0 0 24 24"
    >
      <g
        id="Home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="order_creation"
          transform="translate(-1303.000000, -443.000000)"
        >
          <g
            id="Group-5"
            transform="translate(273.000000, 382.000000)"
          >
            <g id="table">
              <g
                id="Group-3"
                transform="translate(0.000000, 32.000000)"
              >
                <g
                  id="ic-/-24px-/-edit"
                  transform="translate(1030.000000, 29.000000)"
                >
                  <g id="info-24px">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                  </g>
                  <g
                    id="copy"
                    transform="translate(2.000000, 1.000000)"
                    fill="#5866A1"
                  >
                    <g id="edit-3">
                      <path
                        d="M19,20.2 C19.4418278,20.2 19.8,20.5581722 19.8,21 C19.8,21.407841 19.4948119,21.7444016 19.1003503,21.7937669 L19,21.8 L1,21.8 C0.5581722,21.8 0.2,21.4418278 0.2,21 C0.2,20.592159 0.505188147,20.2555984 0.8996497,20.2062331 L1,20.2 L19,20.2 Z M11.4343146,0.434314575 C11.746734,0.121895142 12.253266,0.121895142 12.5656854,0.434314575 L12.5656854,0.434314575 L16.5656854,4.43431458 C16.8781049,4.74673401 16.8781049,5.25326599 16.5656854,5.56568542 L16.5656854,5.56568542 L5.56568542,16.5656854 C5.41565632,16.7157145 5.21217319,16.8 5,16.8 L5,16.8 L1,16.8 C0.5581722,16.8 0.2,16.4418278 0.2,16 L0.2,16 L0.2,12 C0.2,11.7878268 0.284285472,11.5843437 0.434314575,11.4343146 L0.434314575,11.4343146 Z M12,2.131 L1.8,12.33 L1.8,15.2 L4.668,15.2 L14.869,5 L12,2.131 Z"
                        id="Combined-Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
