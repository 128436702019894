import React, { useEffect, useState } from 'react';
import * as Api from '../../../api';
import s from './ViewsRequestManagers.module.scss';
import { Box, Typography, Button } from '@material-ui/core';
import { TableManagerAll } from '../TableManagerAll/TableManagerAll';
import { propOr } from 'ramda';
import { useStore } from '../../../stores/stores';
import { ModalCreateManager } from '../ModalCreateManager/ModalCreate';
import { CreateManager } from '../CreatedManager/CreatedManager';
import { TableManagerRequest } from '../TableManagerRequest/TableManagerRequest';
import { Loader } from '../../Loader/Loader';
import { useSnackbar } from 'notistack';

export const ViewsRequestManagers = () => {
  const { users } = useStore();
  const AuthUser = users.authUser;
  const company_id = AuthUser.client_profile.company.id;

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTablerData] = useState();
  const [open, setOpen] = useState({ isOpen: false });

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen({ isOpen: true });
  };

  const handleClose = () => {
    setOpen({ isOpen: !open.isOpen });
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(Api.companyManagerRequests(company_id))
      .then((res) => {
        let tempArrayManagerRequest = res.data.filter(
          (item) => item.id !== AuthUser.id,
        );

        setTablerData(tempArrayManagerRequest);

        setIsLoading(false);
      })
      .catch((res) => {
        setIsLoading(false);
      });
  }, []);

  const confirmManager = (clientId) => () => {
    Promise.resolve(
      Api.changeCompanyManager({ client_profile_id: clientId }),
    )
      .then((res) => {
        res.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
       return Api.companyManagerRequests(company_id);
      })
      .then((res) => {
        if (res.length < 1) {
          setTablerData([]);
        } else {
          setTablerData(res.data)
        }
      });
  };
  const deleteManager = (clientId) => () => {
    Promise.resolve(
      Api.deleteCompanyManager({ client_profile_id: clientId }),
    )
      .then((res) => {
        res.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
       return Api.companyManagerRequests(company_id);
      })
      .then((res) => {
        console.log(res );
        
        if (res.length < 1) {
          setTablerData([]);
        } else {
          setTablerData(res.data)
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          {console.log('tableData', tableData)}
          {console.log('tableData')}
          {tableData.length < 1 ? (
            <>
              <Box my={6} mx={4} className={s.headerWrap}>
                <Box className={s.header}>
                  <Typography className={s.MainTitle} variant="h4">
                    Запити на створення
                  </Typography>
                  <Button
                    onClick={handleOpen}
                    className={s.btn_standart}
                  >
                    Додати менеджера
                  </Button>
                </Box>

                <Box className={s.tableWrap}>
                  <Typography className={s.TextMessage}>
                    Запитів на додавання менеджерів немає
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box my={6} mx={4}>
                <Box className={s.header}>
                  <Typography className={s.MainTitle} variant="h4">
                    Запити на створення
                  </Typography>
                  <Button
                    onClick={handleOpen}
                    className={s.btn_standart}
                  >
                    Додати менеджера
                  </Button>
                </Box>

                <Box className={s.tableWrap}>
                  <Box className={s.tableBox}>
                    <TableManagerRequest
                      tableData={tableData}
                      confirmManager={confirmManager}
                      deleteManager={deleteManager}
                      AuthUser={AuthUser}
                    ></TableManagerRequest>
                  </Box>
                </Box>
              </Box>
              <ModalCreateManager
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
              >
                <CreateManager
                  handleClose={handleClose}
                  company_id={company_id}
                ></CreateManager>
              </ModalCreateManager>
            </>
          )}
        </>
      )}
    </>
  );
};

{
  /*  */
}

function getUrl(data) {
  return Object.entries(data)
    .map(([key, val]) => {
      if (typeof val === 'object') {
        return `${encodeURIComponent(key)}=${JSON.stringify(val)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          val,
        )}`;
      }
    })
    .join('&');
}
