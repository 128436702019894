import React, { useContext, useState } from 'react';
import s from './CardManagerAll.module.scss';
import { Card, Typography, Box, Avatar } from '@material-ui/core';
import CreateInfoOrder from '../CreateOrder/CreateInfoOrderContext';
import { Mail } from '../../../assetc/svg/mail';
import { SendManager } from '../../ManagerActions/SendManager/SendManager';
import { ModalWindow } from '../../ModalWindow/ModalWindow';

export const CardManagerAll = ({ ...props }) => {
  // const { manager } = useContext(CreateInfoOrder);
  const { AuthUser,titleText, manager} = props;
  const [open, setOpen] = useState({ isOpen: false });

  const handleOpen = () => {
    setOpen({ isOpen: true });
  };

  const handleClose = () => {
    setOpen({ isOpen: !open.isOpen });
  };
 

  return (
    <>
      
          <Card p={3} className={s.card}>
            <Typography className={s.cardTitle}>
              {titleText}
            </Typography>
            <Box variant="outlined" display="flex">
              <Box mr={2}>
                {/* User Photo */}
                <Avatar
                  style={{
                    width: 40,
                    height: 40,
                    transition: '0.3s',
                  }}
                />
              </Box>
              <Box>
                {!!manager.full_name && (
                  <Typography className={s.cardName} variant="body2">
                    {manager.full_name}
                  </Typography>
                )}
                {!!manager.name && (
                  <Typography className={s.cardName} variant="body2">
                    {manager.name}
                  </Typography>
                )}
                {manager.email && (
                  <a
                    href={`mailto:${manager.email}`}
                    className={s.cardMailLink}
                  >
                    <Typography
                      className={s.cardMail}
                      variant="body2"
                    >
                      {manager.email}
                    </Typography>
                  </a>
                )}
                {manager.phone && (
                  <a
                    href={`tel:${manager.phone}`}
                    className={s.cardMailLink}
                  >
                    <Typography
                      className={s.cardMail}
                      variant="body2"
                    >
                      {manager.phone}
                    </Typography>
                  </a>
                )}
                {manager.email && (
                  <div
                    className={s.cardButtonLink}
                    href={`mailto:${manager.email}`}
                    onClick={handleOpen}
                  >
                    <Mail
                      size="16px"
                      color="#5866a1"
                      name="setings"
                    ></Mail>
                    <Typography>Написати менеджеру</Typography>
                  </div>
                )}
              </Box>
            </Box>
          </Card>
          <ModalWindow
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            titleWindow="Повідомлення менеджеру"
          >
          
            <SendManager
              manager_id={manager.id}
              client_id={AuthUser.id}
              handleClose={handleClose}
            ></SendManager>
          </ModalWindow>
       
    </>
  );
};
