import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
 
  Sidebar: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
 
  },

   
}));
