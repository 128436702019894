import React from 'react'; 
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'; 
import {
  IconButton, 
  Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MoreVert } from '../../assetc/svg/moreVert';
import { DeleteIcon } from '../../assetc/svg/deleteIcon';
import { CopyIcon } from '../../assetc/svg/copyIcons';
import { EditIcon } from '../../assetc/svg/editIcon';
import { ViewsDetailSVG } from '../../assetc/svg/viewsDetail';
import { hasStatusUnordered } from '../../constants/statuses';

export const SmallMenuCRUD = ({
  onEditOrder,
  order,
  opening,
  status,
  anchorEl,
  ViewsDetail,
  handleClick,
  handleClose,
  copyOrder,
  onDeleteOrder,
}) => {
  return (
    <>
      <Tooltip title="Дії" arrow>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert size="18px" />
        </IconButton>
      </Tooltip>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={opening}
        onClose={handleClose}
      >
        <Tooltip title="Переглянути детально" arrow>
          <Link to={`/order/${order.id}`}>
            <MenuItem>
              <ViewsDetailSVG
                size="16px"
                color="#5866a1"
                display="block"
              />
            </MenuItem>
          </Link>
        </Tooltip>
        {!order.has_valuated && (
          <Tooltip title="Редагування" arrow>
            <MenuItem onClick={onEditOrder(order.id)}>
              <EditIcon size="16px" color="#5866a1" display="block" />
            </MenuItem>
          </Tooltip>
        )}

        <Tooltip title="Копіювання" arrow>
          <MenuItem onClick={copyOrder(order.id)}>
            <CopyIcon size="16px" color="#8f9297" display="block" />
          </MenuItem>
        </Tooltip>
        {!order.has_valuated && (
          <Tooltip title="Видалення" arrow>
            <MenuItem onClick={onDeleteOrder(order.id)}>
              <DeleteIcon
                size="16px"
                color="#e94b51"
                display="block"
              ></DeleteIcon>
            </MenuItem>
          </Tooltip>
        )}
      </Menu>
    </>
  );
};
