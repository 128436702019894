import React, { useState } from 'react';
import s from './CustomSelect.module.scss';
import Select from 'react-select';
import { useFormikContext } from 'formik';
export const CustomSelect = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { directions, onDirections } = props;
    
  const [isError, setIsError] = useState(true);
 

  const { setFieldValue } = useFormikContext();

  return (
    <>
      <div className={s.wrapSelect}>
        <div>
          <Select
            // // onFocus={console.log('123' )
            // }
            onFocus={() => setIsError(false)}
            className={s.input}
            fullWidth
            placeholder="Виберіть напрям"
            options={directions.list}
            isLoading={directions.isLoading}
            onChange={(item) => {
              // console.log(item );
              setFieldValue(field.name, item.value);
              onDirections(item);
            }}
            // onChange={onDirections}
            value={directions.value}
            name="directions"
            id="directions"
          />
        </div>

        { errors[field.name] && (
          <div className="input_error">{errors[field.name]}</div>
        )}
      </div>
    </>
  );
};
