import React, { useEffect, useState } from 'react';
import s from './ManagerDetailOrder.module.scss';
import { Box, Typography, Button } from '@material-ui/core';
import { TableOrderManager } from '../../evaluationNomenclature/tableOrderManager/tableOrderManager';
import { Loader } from '../../Loader/Loader';
import { BaseFilter } from '../../baseFilter/baseFilter';
import { updateOrders } from '../../evaluationNomenclature/statusProcessingOrders/updateOrdersApi';
import { fetchDataFilters } from '../../baseFilter/apiFetchDataFilters';
import * as Api from '../../../api';

import { DownloadIcons } from './../../../assetc/svg/downloadIcons';
import { propOr } from 'ramda';
import { TableOrderClient } from '../TableOrderClient/TableOrderClient';

export const ManagerDetailOrder = ({ ...props }) => {
 
  const { AuthUser, statuses, role, allManagerCheck } = props;
 
  let ManagerId;
  //  = props.match.params.id;

  if (allManagerCheck === 'true') {
    ManagerId = '';
  } else {
    ManagerId = props.match.params.id;
  }

  const initValue = {
    direction_id: '',
    date_to: '',
    date_from: '',
    current_user_id: AuthUser.id,
    viewer_id: AuthUser.id,
    page_number: 1,
    page_size: 5,
    statuses: [],
    manager_profile_id: '',
    manager_profile_ids: [],
    client_profile_id: '',
  };

  let filterQuery = {
    manager_profile_id: '',
    client_profile_id: '',
  };

  if (role === 'client') {
    filterQuery.client_profile_id = ManagerId;
    initValue.client_profile_id = ManagerId;
  } else {
    initValue.manager_profile_id = ManagerId;
    filterQuery.manager_profile_id = ManagerId;
  }

  const tableValue = {
    orders: [],
  };

  const [filterValue, setFilterValue] = useState(initValue);
  const [filterData, setFilterData] = useState({});
  const [managerData, setManagerData] = useState({
    isLoading: true,
    data: [],
  });
  const [tableData, setTablerData] = useState(tableValue);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState({ isOpen: false }); // modal розцінка
  const [managerInfo, setManagerInfo] = useState();

  useEffect(() => {
    if (allManagerCheck === 'true') {
    } else {
      Promise.resolve(Api.getManagerId(ManagerId)).then((res) => {
        setManagerInfo(res.data.user);
      });
    }
  }, []);

  // console.log(filterQuery);

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(
      fetchDataFilters({
        client_profile_id: filterQuery.client_profile_id,
        manager_profile_id: filterQuery.manager_profile_id,
        manager_profile_ids: filterValue.manager_profile_ids,
        current_user_id: AuthUser.id,
        viewer_id: AuthUser.id,
        
        // filterQuery.manager_profile_id,
        // manager_profile_id: ManagerId,
        statuses: [],
      }),
    ).then((result) => {
      console.log(result);

      setFilterData(result);
      setIsLoading(false);
    });
  }, [filterValue.statuses, filterValue.manager_profile_ids]);

  useEffect(() => {
    if (allManagerCheck === 'true') {
      Promise.resolve(Api.getManagers()).then((res) => {
        console.log(res.data );
        
        let temArrayOptions = res.data.map((item) => {
          return { value: item.id, label: item.full_name };
        });

        setManagerData({ isLoading: false, data: temArrayOptions });
      });
    } else {
    }
  }, []);

  useEffect(() => {
    console.log('filterValue_order',filterValue );
    
    updateOrders(filterValue, setTablerData);
  }, [filterValue]);

  const onDownloadFile = (filterValue) => () => {
    let tempFilterValues = filterValue;
    tempFilterValues.date_to = propOr(
      '',
      'date',
      filterValue.date_to,
    );
    tempFilterValues.date_from = propOr(
      '',
      'date',
      filterValue.date_from,
    );
    tempFilterValues.direction_id = propOr(
      '',
      'id',
      filterValue.direction_id,
    );

    Promise.resolve(Api.downloadFile(tempFilterValues))
      .then((res) => {
        const type =
          res.headers[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ];
        const blob = new Blob([res.data], {
          type: type,
          encoding: 'UTF-8',
        });

        let string_date_to = '',
          string_date_from = '';
        if (tempFilterValues.date_from !== '') {
          string_date_from = `від ${tempFilterValues.date_from} `;
        }
        if (tempFilterValues.date_to !== '') {
          string_date_to = `до ${tempFilterValues.date_to} `;
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        let TitleReport
        if (allManagerCheck === 'true') {
          TitleReport = 'Звіт по менеджерах'
        }else{
          TitleReport = 'Звіт по менеджеру'
        }

        link.download = `${TitleReport} ${propOr(
          '',
          'full_name',
          managerInfo,
        )} ${string_date_from} ${string_date_to}.xlsx`;
        link.click();
      })
      .catch((res) => console.log(res));
  };

  return (
    <>
      <Box my={6} mx={4} className={s.FilterWrapBox}>
        <Box className={s.tableWrap}>
          <Box className={s.tableBox}>
            <Box className={s.tableMain}>
              <Box>
                <div className={s.headerInfo}>
                  <div className="">
                    <div className={s.pageTitle}>
                      Деталі замовлень
                    </div>
                    <div className={s.managerName}>
                      {propOr('', 'full_name', managerInfo)}
                    </div>
                  </div>
                  <Button
                    className={`${s.btn}  ${s.buttonDownload}`}
                    onClick={onDownloadFile(filterValue)}
                  >
                    <DownloadIcons size="16px"></DownloadIcons>
                    <Typography className={s.textButton}>
                      Завантажити звіт
                    </Typography>{' '}
                  </Button>
                </div>
              </Box>
              <Box className={s.Header}>
                <BaseFilter
                  filterStatus="true"
                  defaultValue={initValue}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  filterValue={filterValue}
                  managerData={managerData}
                  changefilterValue={setFilterValue}
                  role={role}
                  allManagerCheck={allManagerCheck}
                ></BaseFilter>
              </Box>
              <Box>
                <>
                  {isLoading ? (
                    <Box
                      className={`${s.wrapLoad} ${s.wrapIsLoading}`}
                    >
                      <Box className={s.loadText}>
                        Завантаження замовлень
                      </Box>
                      <Box className={s.wrapLoaderBox}>
                        <Loader className={s.loader}></Loader>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {tableData.orders < 1 ? (
                        <Box className={s.wrapLoad}>
                          <Box className={s.loadText}>
                            Замовлень з даним статусом не знайдено
                          </Box>
                        </Box>
                      ) : (
                        <>
                        
                          {role === 'manager' ? (
                            <TableOrderManager
                              filterValue={filterValue}
                              tableData={tableData}
                              setTablerData={setTablerData}
                              setFilterValue={setFilterValue}
                              AuthUser={AuthUser}
                            ></TableOrderManager>
                          ) : (
                            <TableOrderClient
                            
                              filterValue={filterValue}
                              tableData={tableData}
                              AuthUser={AuthUser}
                              ManagerId={ManagerId}
                              setTablerData={setTablerData}
                              setFilterValue={setFilterValue}
                            ></TableOrderClient>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
