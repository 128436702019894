import React, { useState } from 'react';

import s from './DetailUser.module.scss';
import { ChengPassword } from '../../Form/chengPassword/chengPassword';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Typography, Box, Avatar, Button } from '@material-ui/core';
// import { CustomInput } from '../Elements/input/input';
// import { CustomInputMask } from '../Elements/inputMask/inputMask';
import * as Api from '../../../api';
import { useSnackbar } from 'notistack';
import { CustomInput } from '../../Form/Elements/input/input';
import { CustomInputMask } from '../../Form/Elements/inputMask/inputMask';
import { useEffect } from 'react';
import { useStore } from '../../../stores/stores';
import { propOr } from 'ramda';
import { UserForm } from '../DetailedUserInformationForm/UserForm';
import { Loader } from '../../Loader/Loader';

export const DetailUser = ({ ...props }) => {
  console.log('test');

  const { users } = useStore();
  const AuthUser = users.authUser;

  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const userId = props.match.params.id;
  const adminCompany = propOr(
    '',
    'id',
    AuthUser.client_profile.company,
  );

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(Api.getUser(userId)).then((res) => {
      let userCompany = propOr(
        '',
        'id',
        res.data.user.client_profile.company,
      );

      if (
        AuthUser.role_code === 'ak' &&
        userCompany === adminCompany
      ) {
        setUserInfo(
          userDataFormat(res.data.user, AuthUser.role_code),
        );
      } else if (AuthUser.role_code === 'ap') {
        setUserInfo(
          userDataFormat(res.data.user, AuthUser.role_code),
        );
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          {console.log(userInfo)}

          {userInfo.idCompany !== adminCompany &&
          AuthUser.role_code !== 'ap' ? (
            <Box>
              <Typography className={s.infoText}>
                Користувач не належить до вашої компанії
              </Typography>
            </Box>
          ) : (
            <>
              <UserForm
                userId={userId}
                infoUser={userInfo}
                AuthUser={AuthUser}
              ></UserForm>
            </>
          )}
        </>
      )}
    </>
  );
};

function userDataFormat(userData, roleAuthUser) {
  return {
    full_name: {
      value: propOr('', 'full_name', userData),
      visible:
        roleAuthUser === 'ak' || roleAuthUser === 'ap' ? false : true,
    },
    email: {
      value: propOr('', 'email', userData),
      visible:
        roleAuthUser === 'ak' || roleAuthUser === 'ap' ? false : true,
    },
    phone_number: {
      value: propOr('', 'phone_number', userData),
      visible:
        roleAuthUser === 'ak' || roleAuthUser === 'ap' ? false : true,
    },
    companyName: {
      value: '',
      visible: false,
    },
    companyEdrpou: {
      value: '',
      visible: false,
    },
    address: {
      value: propOr('', 'address', userData.client_profile),
      visible: false,
    },
    webSite: {
      value: propOr('', 'site', userData.client_profile),
      visible: false,
    },
    companyEdrpou: {
      value: propOr('', 'edrpou', userData.client_profile.company),
      visible: false,
    },
    companyName: {
      value: propOr('', 'name', userData.client_profile.company),
      visible: false,
    },
    idCompany: propOr('', 'id', userData.client_profile.company),
    idUser: userData.id,
    role_code: userData.role_code,
  };
}
