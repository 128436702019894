import React from 'react';
import { F } from 'ramda';
import { string, func, object, bool, shape } from 'prop-types';
import {
  CircularProgress,
  Button as MaterialButton,
  Box,
} from '@material-ui/core';
 
import useStyle from './style1';

function ButtonCustom({
  text,
  onClick,
  isLoading,
  isDisabled,
  buttonProps,
  loaderProps,
  buttonStyles,
  type,
}) {
  const s = useStyle(buttonStyles);
  return (
    <MaterialButton
      onClick={onClick}
      type
      className={s.button}
      disabled={isDisabled || isLoading}
      {...buttonProps}
    >
      {text}
      {isLoading && (
        <Box className={s.loader_button}>
          <CircularProgress
            
            className={s.loader}
            {...loaderProps}
          />
        </Box>
      )}
    </MaterialButton>
  );
}

ButtonCustom.propTypes = {
  text: string.isRequired,
  onClick: func,
  isLoading: bool,
  isDisabled: bool,
  buttonStyle: shape({
    button: object,
    loader: object,
  }),
  buttonProps: object,
  loaderProps: object,
};
ButtonCustom.defaultProps = {
  onClick: F,
  isLoading: false,
  isDisabled: false,
  buttonStyles: {
    button: {},
    loader: {},
  },
  buttonProps: {},
  loaderProps: {},
};

export default ButtonCustom;
