import { propOr, uniq } from 'ramda';
import { types as t, flow } from 'mobx-state-tree';

import * as Api from '../api';
import OrderModel from './modeles/OrderModel';
import {
  allSelectFilter,
  allDirections,
  allData,
  filterValue,
} from './modeles/allSelectFilter';
import { getStatus } from '../constants/statuses';

const OrdersStore = t
  .model('OrdersStore', {
    list: t.optional(t.array(OrderModel), []),
    filterOrders: t.optional(t.array(OrderModel), []),
    allManager: t.optional(t.array(allSelectFilter), []),
    allDirections: t.optional(t.array(allDirections), []),
    allData: t.optional(t.array(allData), []),
    filterValue: t.maybeNull(filterValue),
  })
  .actions((store) => ({
    addOrder(Ordres) {
 

      let allManager = [];
      let allDirections = [];
      let allData = [];

      let allOrders = Ordres.map((item, index) => {
        let tempArray = {},
          flagValuated = true;
        tempArray.header = [];
        tempArray.main = [];
        tempArray.id = item.id;
        tempArray.status = item.status;
        tempArray.has_valuated = item.has_valuated;
        tempArray.countNomenclatures =
          item.ordered_nomenclatures.length;

        tempArray.nomenclatures = item.ordered_nomenclatures.map(
          (nomenclature, index) => {
            let tempNomenclature = {};
  

            tempNomenclature.id = nomenclature.id;
            tempNomenclature.seguence_number = ++index;
            tempNomenclature.amount = nomenclature.amount;
            tempNomenclature.created = nomenclature.created;
            
            tempNomenclature.date = nomenclature.date;
            tempNomenclature.has_unread_comments = nomenclature.has_unread_comments;

            

            tempNomenclature.address = nomenclature.address;
            tempNomenclature.unit =
              nomenclature.nomenclature.unit.name;
            tempNomenclature.price = propOr(
              '',
              'price',
              nomenclature,
            ).toString();
            if (tempNomenclature.price == '') {
              flagValuated = false;
            }
            tempNomenclature.directions = {
              code: propOr(
                '',
                ['code'],
                nomenclature.nomenclature.direction,
              ),
              name: propOr(
                '',
                ['name'],
                nomenclature.nomenclature.direction,
              ),
            };

            tempNomenclature.nomenclature = propOr(
              '',
              ['name'],
              nomenclature.nomenclature,
            );
            tempNomenclature.manager = {
              id: propOr(
                '',
                ['id'],
                nomenclature.nomenclature.manager,
              ),
              name: propOr('', ['full_name'], nomenclature),
              email: propOr('', ['email'], nomenclature),
              phone_number: propOr(
                '',
                ['phone_number'],
                nomenclature,
              ),
            };
            if (
              tempArray.status !== 'finished' &&
              tempArray.status !== 'declined'
            ) {
              allManager.push({
                value: tempNomenclature.manager.id,
                label: tempNomenclature.manager.name,
              });

              if (tempNomenclature.directions.code !== '')
                allDirections.push({
                  value: tempNomenclature.directions.code,
                  label: tempNomenclature.directions.name,
                });
              allData.push({
                value: tempNomenclature.date,
                label: tempNomenclature.date,
              });
            }

            index === 1
              ? tempArray.header.push(tempNomenclature)
              : tempArray.main.push(tempNomenclature);
 

            return tempNomenclature;
          },
        ); 
        
        // if (
        //   tempArray.status !== 'finished' &&
        //   tempArray.status !== 'declined' &&
        //   flagValuated
        // ) {
        //   tempArray.status = 'valuated';
        // } 

        return tempArray;
      });
 
      store.list = allOrders;

      store.filterOrders = allOrders;

      store.allManager = uniq(allManager);
      store.allDirections = uniq(allDirections);
      store.allData = uniq(allData);
    },
    deleteOrderStor(idOrder) {
      store.filterOrders = store.filterOrders.filter(
        (item) => item.id !== idOrder,
      );
    },

    addFilterOrder(data) {
      store.filterValue = data;
      let OrdersFilterManager = [],
        OrdersFilterDirections = [],
        OrdersFilterData = [],
        OrdersFilterStatus = [];

      // manager filter
      if (store.filterValue.manager !== 'all') {
        store.list.filter((item) => {
          let flag = false;
          item.nomenclatures.filter((item) =>
            item.manager.id == Number(store.filterValue.manager)
              ? (flag = true)
              : null,
          );

          if (flag) {
            OrdersFilterManager.push(item.toJSON());
          }
        });
      } else {
        store.list.filter((item) => {
          OrdersFilterManager.push(item.toJSON());
        });
      }

      // // directions filter

      if (store.filterValue.direction !== 'all') {
        OrdersFilterManager.filter((item) => {
          let flag = false;

          item.nomenclatures.filter((item) =>
            item.directions.code ==
            Number(store.filterValue.direction)
              ? (flag = true)
              : null,
          );
          if (flag) {
            OrdersFilterDirections.push(item);
          }
        });
      } else {
        OrdersFilterManager.map((item) => {
          OrdersFilterDirections.push(item);
        });
      }

      // data filter
      if (store.filterValue.data !== 'all') {
        OrdersFilterDirections.filter((item) => {
          let flag = false;

          item.nomenclatures.filter((item) =>
            item.date === store.filterValue.data
              ? (flag = true)
              : null,
          );
          if (flag) {
            OrdersFilterData.push(item);
          }
        });
      } else {
        OrdersFilterDirections.map((item) => {
          OrdersFilterData.push(item);
        });
      }

      let statusOrder = store.filterValue.status;
      let statusCheckFilter = false;
      for (const key in statusOrder) {
        if (statusOrder.hasOwnProperty(key)) {
          const element = statusOrder[key];
          if (element) {
            statusCheckFilter = true;
          }
        }
      }

      if (statusCheckFilter) {
        OrdersFilterData.filter((item) => {
          let flag = false;
          for (const key in statusOrder) {
            if (statusOrder.hasOwnProperty(key)) {
              const element = statusOrder[key];

              if (element == true && item.status == key) {
                flag = true;
                continue;
              }
            }
          }

          if (flag) {
            OrdersFilterStatus.push(item);
          }
        });
      } else {
        OrdersFilterData.map((item) => {
          OrdersFilterStatus.push(item);
        });
      }

      store.filterOrders = OrdersFilterStatus;
    },
    copyOrder: flow(function* (id) {
      try {
        const res = yield Api.copyOrder(id);

        let allOrders = store.list;
        let copyOrder = JSON.parse(
          JSON.stringify(
            allOrders.filter((item) => {
              let jsonItem = item.toJSON();
              return jsonItem.id === id;
            })[0],
          ),
        );

        copyOrder.id = res.data.new_order_id;
        copyOrder.status = getStatus('unordered').classes;

        store.filterOrders.unshift(copyOrder);
        store.list.unshift(copyOrder);
        store.list = allOrders;

        return res;
      } catch (error) {
        console.log(error);
      }
    }),
    deleteOrder: flow(function* (id) {
      try {
      } catch (error) {
        console.log(error);
      }
    }),
    removeOrder: flow(function* (id) {
      try {
        const res = yield Api.deleteOrder(id);
        store.deleteOrderStor(id);

        return res;
      } catch (error) {
        console.log(error);
      }
    }),

    changeFilterOrders({ obj }) {},
    fetchOrders: flow(function* (userID) {
      try {
        const res = yield Api.fetchOrderUser(userID);
        store.addOrder(res.data);
      } catch (error) {
        console.log(error);
      }
    }),
  }))

  .views((store) => ({
    get activeOrders() {
      const allOrders = propOr([], 'filterOrders', store);
 
      const activeOrders = allOrders.filter(
        ({ status }) =>
          [
            'valuating',
            'unordered',
            'valuated',
            'agreed',
            'in_progress',
            'agreed_without_contract',
            'contract_sent',
            'contract_concluded',
          ].indexOf(status) != -1,
      );
       
      
      return activeOrders;
    },
    get managersOrders() {
      const allManagers = propOr([], 'allManager', store);
      return allManagers;
    },
    get managersDirections() {
      const allManagers = propOr([], 'allDirections', store);
      return allManagers;
    },
    get managersDate() {
      const allManagers = propOr([], 'allData', store);
      return allManagers;
    },

    get archiveOrders() {
      const allNomenclatures = propOr([], 'list', store);

      return allNomenclatures.filter(
        ({ status }) =>
          ['declined', 'finished'].indexOf(status) != -1,
      );
    },
  }));

export default OrdersStore;
