import { types as t } from 'mobx-state-tree';

const manager = t.model('manager', {
  id: t.maybeNull(t.number),
  name: t.maybeNull(t.string),
  phone: t.maybeNull(t.string),
  email: t.maybeNull(t.string),
});
const direction = t.model('manager', {
  code: t.maybeNull(t.string),
  name: t.maybeNull(t.string),
});

const Nomenclature = t.model('Nomenclature', {
  address: t.maybeNull(t.string),
  date: t.maybeNull(t.string),
  created: t.maybeNull(t.string),
  amount: t.number,
  directions: t.maybeNull(direction),
  id: t.number,
  nomenclature: t.maybeNull(t.string),
  price: t.maybeNull(t.string),
  has_unread_comments:t.maybeNull(t.boolean),
  seguence_number: t.number,
  manager: t.maybeNull(manager),
  unit: t.maybeNull(t.string),
});

const OrderModel = t
  .model('Order', {
    id: t.number,
    status: t.maybeNull(t.string),
    has_valuated: t.maybeNull(t.boolean),
    countNomenclatures: t.number,
    nomenclatures: t.optional(t.array(Nomenclature), []),
    header: t.optional(t.array(Nomenclature), []),
    main: t.optional(t.array(Nomenclature), []),
  })

  .views((store) => ({}));

export default OrderModel;
