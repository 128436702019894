import React, { useEffect, useState } from 'react';
import s from './allCompanies.module.scss';
import * as Api from '../../../api';
import { Box, Typography } from '@material-ui/core';
import { Loader } from '../../Loader/Loader';
import { TableCompaniesAll } from '../TableCompaniesAll/TableCompaniesAll';
import { BaseFilter } from '../../baseFilter/baseFilter';
import { companyFilterValues } from '../../../api';

export const All_Companies = ({ ...props }) => {
  const initValue = {
    manager_name_query: '',
    name_query: '',
    edrpou_query: '',

    page_number: 1,
    page_size: 5,
  };
  const tableValue = {
    companies: [],
  };

  const initValueFilterData = {
    companies: [],
    managers: [],
    edrpous: [],
  };

  const [filterValue, setFilterValue] = useState(initValue);
  const [filterData, setFilterData] = useState(initValueFilterData);
  const [tableData, setTablerData] = useState(tableValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(filterValue);

    Promise.resolve(Api.allCompanies(filterValue))
      .then((res) => {
        setTablerData({
          ...tableData,
          allCount: res.data.count,
          itemsPerPage: res.data.results.length,
          companies: res.data.results,
        });

        setIsLoading(false);
      })
      .catch((res) => {
        setIsLoading(false);
      });
  }, [filterValue]);

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(Api.companyFilterValues()).then((result) => {
      setFilterData(result.data);

      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          <Box my={6} mx={4}>
            <Box className={s.header}>
              <Typography className={s.MainTitle} variant="h4">
                Усі компанії
              </Typography>
            </Box>
            <Box>
              <BaseFilter
                defaultValue={initValue}
                filterData={filterData}
                setFilterData={setFilterData}
                filterValue={filterValue}
                changefilterValue={setFilterValue}
              ></BaseFilter>
            </Box>

            <Box className={s.tableWrap}>
              <Box className={s.tableBox}>
                <TableCompaniesAll
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  tableData={tableData}
                ></TableCompaniesAll>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
