import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ButtonOrder } from './component/buttonOrder/buttonOrder';
import { NavLink } from 'react-router-dom';
import useStyles from './style';

import './style_1.css';
import LogoutUser from './component/LogoutUser/LogoutUser';
import { SmalInfoUser } from '../Users/SmalInfoUser/SmalInfoUser';
import { privateRoutes } from '../../constants/routes';
import { useStore } from '../../stores/stores';

export const Sidebar = ({ SetOpen, isOpen, ...props }) => {
  const classes = useStyles();
  const { users } = useStore();
  const AuthUser = users.authUser;
  // console.log(SetOpen);

  return (
    <>
      <Box className={classes.Sidebar}>
        {/* 1. Component User */}
        <SmalInfoUser SetOpen={SetOpen} />
        {AuthUser.manager_profile === null ? (
          <ButtonOrder SetOpen={SetOpen} />
        ) : (
          <Box my={3}></Box>
        )}

        <div className="SidebarGroup">
          <div className="SidebarGroupHeader">
            {/* <IconSvg className="Icon" name="my_orders" /> */}
            <Typography className="Title" variant="body1">
              Заявки
            </Typography>
          </div>
          <div className="SidebarGroupMain">
            {AuthUser.manager_profile === null ? (
              <>
                <NavLink
                  activeClassName="activeRoute"
                  to={privateRoutes.HOME}
                  onClick={() => SetOpen(false)}
                  exact={true} 
                  className="Item"
                >
                  Активні
                </NavLink>

                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to={privateRoutes.ARCHIVE}
                  exact={true} 
                  disabled
                  className="Item"
                >
                  Архів заявок
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to="/statuses/valuating/"
                  exact={true} 
                  disabled
                  className="Item"
                >
                  На розцінці
                </NavLink>
                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to="/statuses/valuated/"
                  exact={true} 
                  disabled
                  className="Item"
                >
                  Розцінено
                </NavLink>
                <NavLink
                activeClassName="activeRoute"
                  
                  onClick={() => SetOpen(false)}
                  to="/statuses/agreed_without_contract/"
                  exact={true} 
                  disabled
                  className="Item"
                >
                  Очікує на договір
                </NavLink>
                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to="/statuses/contract_sent/"
                  exact={true} 
                  disabled
                  className="Item"
                >
                  Договір відправлено
                </NavLink>

                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to="/statuses/agreed/"
                  exact={true} 
                  disabled
                  className="Item"
                >
                  Погоджено
                </NavLink>
                <NavLink
                 exact={true} 
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to="/statuses/in_progress/"
                  disabled
                  className="Item"
                >
                  Опрацьовано
                </NavLink>
                <NavLink
                  activeClassName="activeRoute"
                  exact={true} 
                  onClick={() => SetOpen(false)}
                  to="/statuses/archive/"
                  disabled
                  className="Item"
                >
                  Архів замовлень
                </NavLink>
              </>
            )}
          </div>
        </div>

        {AuthUser.role_code === 'ak' ||
        AuthUser.role_code === 'ap' ? (
          <div className="SidebarGroup">
            <div className="SidebarGroupHeader">
              {/* <IconSvg className="Icon" name="my_orders" /> */}
              <Typography className="Title" variant="body1">
                Менеджери
              </Typography>
            </div>
            <div className="SidebarGroupMain">
              <NavLink
                activeClassName="activeRoute"
                to={privateRoutes.MANAGERS}
                onClick={() => SetOpen(false)}
                className="Item"
              >
                Усі менеджери
              </NavLink>

              {AuthUser.role_code === 'ap' && (
                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to={privateRoutes.MANAGERSORDER}
                  disabled
                  className="Item"
                >
                  Статистика по менеджерах
                </NavLink>
              )}

              {AuthUser.role_code !== 'ap' && (
                <NavLink
                  activeClassName="activeRoute"
                  onClick={() => SetOpen(false)}
                  to={privateRoutes.REQUESTMANAGER}
                  disabled
                  className="Item"
                >
                  Запити на створення
                </NavLink>
              )}
            </div>
          </div>
        ) : null}


{AuthUser.role_code === 'ap' && (      
        <div className="SidebarGroup">
          <div className="SidebarGroupHeader">
            {/* <IconSvg className="Icon" name="my_orders" /> */}
            <Typography className="Title" variant="body1">
              Компанії
            </Typography>
          </div>
          <div className="SidebarGroupMain">
            <NavLink
              activeClassName="activeRoute"
              exact={true} 
              to={privateRoutes.ALL_COMPANIES}
              onClick={() => SetOpen(false)}
              className="Item"
            >
              Усі Компанії
            </NavLink>
          </div>
        </div>
        )}
      </Box>
      <LogoutUser SetOpen={SetOpen}></LogoutUser>
    </>
  );
};
