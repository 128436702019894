import React from 'react';
import useStyles from './HeaderStyle';
import { Typography, Box } from '@material-ui/core';
import { AdaptiveMenu } from './components/AdaptiveMenu/AdaptiveMenu';
import { HamburgerSlider } from 'react-animated-burgers';

export const Headers = ({SetOpen, isOpen, OpenMenu }) => {
  const s = useStyles();

  // const websiteUrl = process.env.WEBSITE_URL;
  const websiteUrl = 'https://puz.supply';

  return (
    <>
      <Box p={1.25}>
        <div className={s.headerBox}>
          <div className="">
            <a className={s.phone} href="tel:380504318736">
              <Typography
                className={s.phoneText}
                color="primary"
                variant="body1"
              >
                +380 (50) 431 8736
              </Typography>
            </a>
            <div className=""></div>
          </div>
          <div className="">
            <a href={websiteUrl} className={s.headerButton}>
              Повернутись на головну
            </a>
            <HamburgerSlider
              isActive={isOpen.open}
              toggleButton={OpenMenu}
              buttonWidth={28}
              barColor="#494a4e"
              className={s.HamburgerSlider}
            />
          </div>
        </div>
      </Box>
      <AdaptiveMenu SetOpen={SetOpen} isOpen={isOpen.open}></AdaptiveMenu>
    </>
  );
};
