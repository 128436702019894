import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Typography,
  Button as MaterialButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Formik, Field, Form } from 'formik';
// import { GoogleLogin } from 'react-google-login';
 
// import ButtonCustom from '../Button';
import { CustomInput } from '../../components/Form/Elements/input/input';

import useStyle from './style';
import { publicRoutes } from '../../constants/routes';
import {
  emailValidation,
  passwordValidation,
} from '../../utils/validations';
import ButtonCustom from '../Button';

const initialValues = {
  email: '',
  password: '',
};
const validationSchema = Yup.object({
  email: emailValidation(),
  password: passwordValidation(),
});

export function LoginForm({ onSubmit, isLoading }) {
  const s = useStyle({ isLoading });

  const responseGoogle = (response) => {
    console.log(response);
  }

  return (
    <>
      <Box>
        <Typography variant="h3" className={s.formTitle}>
          Логін
        </Typography>
      </Box>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Form className={s.form}>
          <Field
            placeholder="Email"
            name="email"
            id="email"
            type="text"
            autoComplete="on"
            component={CustomInput}
          />
          <Field
            placeholder="Пароль"
            name="password"
            id="password"
            type="password"
            component={CustomInput}
          />

          <Box className={s.formFooter}>
            <a className={s.formFooterLink} href="https://puz.supply/accounts/password/reset/">
            <Typography variant="body2">Забули пароль</Typography>
            </a>
           
            <ButtonCustom
              type="submit"
              text="Увійти"
              isLoading={isLoading}
            />
          </Box>
        </Form>
      </Formik>

      {/* <Box display="flex" justifyContent="center">
        {/* <GoogleLogin
          clientId="697687398766-c0bp6htapgjknp375e62arb8njtutt2i.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        /> */}
        {/* , */}
        {/* <MaterialButton size="large" className={s.googleButton}>
          Увійти через Gmail
        </MaterialButton> 
        
      </Box> */}
      <Box className={s.formFooterLogin}>
        <Typography variant="body1" color="textPrimary">
          Ще не зареєcтровані?
        </Typography>
        <Box ml={1}>
          <Link
            to={publicRoutes.REGISTER}
            style={{ textDecoration: 'none', color: '#5866a1' }}
          >
            <Typography
              variant="body2"
              style={{ textTransform: 'initial' }}
            >
              Зареєструватися
            </Typography>
          </Link>
        </Box>
      </Box>
    </>
  );
}

LoginForm.propTypes = {
  isLoading: bool,
  onSubmit: func.isRequired,
};

LoginForm.defaultProps = { isLoading: false };
