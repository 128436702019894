import React, { Children } from 'react';
import {
  Modal,
  Fade,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Closse } from '../../assetc/svg/Closse';

import s from './ModalWindow.module.scss';

export const ModalWindow = ({ ...props }) => {
  const { open,  handleClose,titleWindow } = props;
  
  // handleClose()

  return (
    <>
      <Modal open={open.isOpen} onClose={handleClose}>
        <Fade in={open.isOpen}>
          <div className={s.ModalWrapBox}>
            <div className={s.modal}>
              <div className={s.modalHeader}>
  <Typography variant="h2" className={s.titleWindow} >{titleWindow}</Typography>
                {true ? (
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <Closse color="#5866a1" width="18px"></Closse>
                  </IconButton>
                ) : null}
              </div>
            </div>
            <div className={s.modalBox}>{props.children}</div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
