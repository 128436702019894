export const statusOBJ = {
  unordered: {
    text: 'Чернетка',
    classes: 'unordered',
  },
  agreed: {
    text: 'Погоджено',
    classes: 'agreed',
  },
  valuating: {
    text: 'Розглядається',
    classes: 'valuating',
  },
  valuated: {
    text: 'Розцінено',
    classes: 'valuated',
  },
  in_progress: {
    text: 'Опрацьовано',
    classes: 'in_progress',
  },
  agreed_without_contract: {
    text: 'Погоджено, очікує на договір',
    classes: 'agreed_without_contract',
  },
  contract_sent: {
    text: 'Договір відправлено',
    classes: 'contract_sent',
  },
  contract_concluded: {
    text: 'Договір укладено',
    classes: 'contract_concluded',
  },
  declined: {
    text: 'Відмовлено',
    classes: 'declined',
  },
  finished: {
    text: 'Заактовано',
    classes: 'finished',
  },
  unactive: {
    text: 'Неактивне',
    classes: 'unactive',
  },
};

export function getStatus(key_status) {
  return statusOBJ[key_status];
}

export function hasStatusUnordered(key_status) {
  return (
    statusOBJ.hasOwnProperty(key_status) && key_status === 'unordered'
  );
}
export function hasStatusValuated(key_status) {
  return (
    statusOBJ.hasOwnProperty(key_status) && key_status === 'valuated'
  );
}
