import { propOr } from 'ramda';

export function getUserInfoOrder({ ...props }) {
  const { AuthUser, newOrder } = props;

  const UserInfoOrder = propOr(
    '',
    'client',
    newOrder.nomenclatures[0],
  );
 
 

  let infoUser = {};
 
  if (UserInfoOrder.id == AuthUser.id) {
    const UserCompany = propOr(
      '',
      'company',
      AuthUser.client_profile,
    );
    const UserCompanyInfo = propOr(
      '',
      'client_profile',
      AuthUser,
    );

    console.log(UserCompanyInfo );
  
    console.log('оформляє клієн');

    console.log(UserCompany);

    infoUser = {
      full_name: {
        value: !!AuthUser.full_name ? AuthUser.full_name : '',
        visible: false,
      },
      email: {
        value: !!AuthUser.email ? AuthUser.email : '',
        visible: !!AuthUser.email ? true : false,
      },
      phone_number: {
        value: !!AuthUser.phone_number ? AuthUser.phone_number : '',
        visible: false,
      },
      companyName: {
        value: propOr('', ['name'], UserCompany),
        visible:
          propOr('', ['name'], UserCompany) !== '' &&
          AuthUser.role_code === 'mk'
            ? true
            : false,
      },
      companyEdrpou: {
        value: propOr('', ['edrpou'], UserCompany),
        visible:
          propOr('', ['edrpou'], UserCompany) !== '' &&
          AuthUser.role_code === 'mk'
            ? true
            : false,
      },
      address: {
        value: propOr('', 'address', UserCompanyInfo),

        visible:
          propOr('', ['address'], UserCompanyInfo) !== '' &&
          AuthUser.role_code === 'mk'
            ? true
            : false,
      },
      webSite: {
        value: propOr('', 'site', UserCompanyInfo),
        visible:
          propOr('', ['site'], UserCompanyInfo) !== '' &&
          AuthUser.role_code === 'mk'
            ? true
            : false,
      },
    };
  } else {
    const UserCompany = propOr(
      '',
      'company',
      UserInfoOrder.client_profile,
    );

    console.log('оформляє якийсь адмін');

    infoUser = {
      full_name: {
        value: propOr('', 'full_name', UserInfoOrder),
        visible:
          AuthUser.role_code == 'ap' || AuthUser.role_code == 'ak'
            ? false
            : true,
      },
      email: {
        value: propOr('', 'email', UserInfoOrder),
        visible: true,
      },
      phone_number: {
        value: propOr('', 'phone_number', UserInfoOrder),
        visible:
          AuthUser.role_code == 'ap' || AuthUser.role_code == 'ak'
            ? false
            : true,
      },
      companyName: {
        value: propOr('', ['name'], UserCompany),
        visible: true,
      },
      companyEdrpou: {
        value: propOr('', ['edrpou'], UserCompany),
        visible: true,
      },
      address: {
        value: propOr('', 'address', UserInfoOrder.client_profile),

        visible: true,
      },
      webSite: {
        value: propOr('', 'site', UserInfoOrder.client_profile),
        visible: true,
      },
    };
  }

  return infoUser;
}
