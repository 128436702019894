import React, { useState, useEffect } from 'react';
import s from './FilterOrders.module.scss';
import { Box, Typography, Button } from '@material-ui/core';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/stores';

export const FilterOrders = observer((props ) => {
  const { managers, directions, date } = props;
  const { orders } = useStore();
  const initValue = {
    manager: 'all',
    direction: 'all',
    data: 'all',
    status: {
      valuating: false,
      valuated: false,
      unordered: false,
    },
    value: {
      manager: null,
      direction: null,
      data: null,
    },
  };

   
  const [filterParams, setfilterParams] = useState(initValue);

  function onFilterManager({ value }) {
    let managerActiveName = managers.filter(item=>{
      return item.value==value ;
    })
     
    setfilterParams({
      ...filterParams,
      manager: value.toString(),
      value: {
        ...filterParams.value,
        manager: {value:value,label:managerActiveName[0].label},
      }
    });
  }
  function onFilterDirections({ value }) {
    let directionActiveName = directions.filter(item=>{
      return item.value==value ;
    })
    setfilterParams({
      ...filterParams,
      direction: value,
      value: {
        ...filterParams.value,
        direction: {value:value,label:directionActiveName[0].label},
      }
    });
  }
  function onFilterDate({ value }) {
    let dateActiveName = date.filter(item=>{
      return item.value==value ;
    })
    setfilterParams({
      ...filterParams,
      data: value,
      value: {
        ...filterParams.value,
        data: {value:value,label:dateActiveName[0].label},
      }
    });
  }

  function onCleareFilter() {
    setfilterParams(initValue);
  }

  function valuating() {
    setfilterParams({
      ...filterParams,
      status: {
        ...filterParams.status,
        valuating: !filterParams.status.valuating,
      },
    });
  }
  function valuated() {
    setfilterParams({
      ...filterParams,
      status: {
        ...filterParams.status,
        valuated: !filterParams.status.valuated,
      },
    });
  }
  function unordered() {
    setfilterParams({
      ...filterParams,
      status: {
        ...filterParams.status,
        unordered: !filterParams.status.unordered,
      },
    });
  }

  useEffect(() => {
    orders.addFilterOrder(filterParams);
  }, [filterParams]);

  return (
    <>
      <Box className={s.filterWrap}>
        <Box className={s.selectWrap}>
          <Box className={s.selectBox}>
            <Select
              //   className={s.input}
              placeholder="Менеджер"
              options={managers}
              onChange={onFilterManager}
              value={filterParams.value.manager}
              name="manager"
              id="manager"
            />
          </Box>
          <Box className={s.selectBox}>
            <Select
              //   className={s.input}
              placeholder="Напрям"
              options={directions}
              onChange={onFilterDirections}
              value={filterParams.value.direction}
              // value={filterParams.direction}
              name="manager"
              id="manager"
            />
          </Box>
          <Box className={s.selectBox}>
            <Select
              //   className={s.input}
              placeholder="Дата"
              options={date}
              onChange={onFilterDate}
              value={filterParams.value.data}
              // value={filterParams.data}
              name="manager"
              id="manager"
            />
          </Box>
        </Box>

        <Box className={s.statusWrap}>
          <Typography>Статуси:</Typography>
          <Box className={s.statusWrapButton}>
            <Button
              className={`${s.statusButton} ${
                filterParams.status.valuating && s.statusButtonActive
              }`}
              onClick={valuating}
            >
              Розглядається
            </Button>
            <Button
              className={`${s.statusButton} ${
                filterParams.status.unordered && s.statusButtonActive
              }`}
              onClick={unordered}
            >
              Чернетка
            </Button>
            <Button
              className={`${s.statusButton} ${
                filterParams.status.valuated && s.statusButtonActive
              }`}
              onClick={valuated}
            >
              Розцінено
            </Button>
          </Box>
        </Box>
        <Box>
          <Button className={s.CleareButton} onClick={onCleareFilter}>
            Очистити фільтр
          </Button>
        </Box>
      </Box>
    </>
  );
});
