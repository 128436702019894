import { propOr } from 'ramda';
import { types as t, flow } from 'mobx-state-tree';
// const {observable, computed, autorun, } = mobx;
import storageService from '../utils/storageService';

import * as Api from '../api';
import NomenclaturesManagerModel from './modeles/NomenclaturesManagerModel';
 
const NomenclaturesManagerStore = t
  .model('NomenclaturesManagerStore', {
    list: t.optional(t.array(NomenclaturesManagerModel), []),

    isLoading: t.optional(t.boolean, true),
  })
  .actions((store) => ({
    addNomenclaturesManager(Nomenclatures) {
      store.list = [];
      Nomenclatures.map((item) => {
        console.log(item);

        let tempArray = {};

        tempArray.id = item.id;
        tempArray.orderId = Number(item.order_id);
        tempArray.amount = item.amount;
        tempArray.date = item.date;
        tempArray.created = item.created;
        tempArray.address = item.address;
        tempArray.price = item.price;

        tempArray.directions = propOr(
          '',
          ['name'],
          item.nomenclature.direction,
        );
        // tempArray.directions = null;
        tempArray.nomenclature = item.nomenclature.name;

        tempArray.lastPrice = [];
        tempArray.client = {
          fullName: item.client.full_name,
          companyName: item.client.client_profile.full_name,
          photoUrl: 'Тест компані',
        };

        item.last_orders.map((last_order) => {
          tempArray.lastPrice.push({
            dataTime: last_order.datetime,
            price: last_order.price,
          });
        });
        // console.log(tempArray);

        store.list.unshift(tempArray);
      });
    },
    valuationNomenclature: flow(function* (nomenclatureId, price) {
      try {
        store.setStartLoading();
        yield Api.valurionNomenclatures(nomenclatureId, price);
        store.list.filter(
          ({ id }) => id == nomenclatureId,
        )[0].price = Number(price);
        store.setFinishLoading();
      } catch (error) {
        store.setFinishLoading();
        console.log(error);
      }
    }),
    setStartLoading() {
      store.isLoading = true;
    },
    setFinishLoading() {
      store.isLoading = false;
    },
    removeOrder() {
      //     store.list = [];
    },
    fetchNomenclaturesManager: flow(function* (userID) {
      try {
        store.setStartLoading();
        const res = yield Api.nomenclaturesManager(userID);
        store.addNomenclaturesManager(res.data);
        store.setFinishLoading();
      } catch (error) {
        store.setFinishLoading();
        console.log(error);
      }
    }),
  }))
  .views((store) => ({
    get activeNomenclatures() {
      const allNomenclatures = propOr([], 'list', store);
      return allNomenclatures.filter(({ price }) => price === null);
    },
    get archiveNomenclatures() {
      const allNomenclatures = propOr([], 'list', store);
      return allNomenclatures.filter(({ price }) => price !== null);
    },

    get getNomenclatureId() {
      const nomenclatureId = storageService.get('nomenclatureId');

      const allNomenclatures = propOr([], 'list', store);
      return allNomenclatures.filter(
        ({ id }) => id == nomenclatureId,
      );
    },
    // get isPrisingOpen(){
    //   return store.isPrisingOpen;
    // }
  }));

export default NomenclaturesManagerStore;
