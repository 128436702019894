import React from 'react';
import s from './pricingWindow.module.scss';
import {
  Modal,
  Fade,
  Typography,
  Box,
  Avatar,
  Button,
  IconButton,
} from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { CustomInput } from '../../Form/Elements/input/input';

import * as Yup from 'yup';
import { Closse } from '../../../assetc/svg/Closse';

export const PricingWindow = ({ ...props }) => {
  const { onValuation, open, handleClose } = props;

  const initialValues = {
    price: '',
  };
  const validationSchema = Yup.object({
    price: Yup.number()
      .typeError('Введіть ціну в числовому форматі')
      .positive('Ціна повинна бути додатнім числом')
      .required("Поле обов'язкове для заповнення "),
  });

  return (
    <>
      {open.isOpen && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={s.modal}
          open={open.isOpen}
          onClose={handleClose}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={s.windowBox}>
              <div className={s.window}>
                <div className={s.windowHeader}>
                  <Typography
                    className={s.windowHeaderTitle}
                    variant="h4"
                  >
                    Розцінка товару
                  </Typography>

                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <Closse color="#5866a1" width="18px"></Closse>
                    {/* <CloseIcon /> */}
                  </IconButton>
                </div>
                {}
                <div className={s.windowWrap}>
                  <div className={s.windowMain}>
                    <Typography className={s.windowMainTitle}>
                      {console.log(open.nomenclature)}
                      Замовлення №{open.nomenclature.orderId} від{' '}
                      {open.nomenclature.created}
                      {/* Замовлення {open.nomenclature.orderId} */}
                    </Typography>
                    <Box display="flex" className={s.windowMainUser}>
                      <Box mr={2}>
                        <Avatar />
                      </Box>
                      <Box>
                        <Typography
                          className={s.windowMainUserName}
                          variant="body2"
                        >
                          {open.nomenclature.client.fullName}
                        </Typography>
                        <Typography
                          className={s.windowMainCompanyName}
                          variant="body2"
                        >
                          {open.nomenclature.client.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div className={s.windowInfo}>
                    {open.nomenclature.lastPrice.map((lastPrice) => (
                      <Box className={s.windowInfoPrice}>
                        {`Попередня ціна товару ${open.nomenclature.nomenclature} станом на ${lastPrice.dataTime}`}

                        <div className={s.windowInfoPriceValue}>
                          {lastPrice.price.replace('.', ',')}
                        </div>
                      </Box>
                    ))}
                  </div>
                  <div className="">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      className={s.Form_wrap}
                      onSubmit={onValuation}
                    >
                      <Form>
                        <div className={s.windowFormMain}>
                          <div>
                            <Field
                              placeholder="Введіть ціну номенклатури (грн/без ПДВ)"
                              name="price"
                              id="price"
                              type="text"
                              component={CustomInput}
                            />
                          </div>
                        </div>
                        <div className={s.windowFormfooter}>
                          <Button
                            className={s.back}
                            onClick={handleClose}
                            type="submit"
                          >
                            Скасувати
                          </Button>
                          <Button
                            className={`${s.standart}  ${s.btn_standart}`}
                            type="submit"
                          >
                            Розцінити
                          </Button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
};
