import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createStore, MstProvider } from './stores/stores';
import {
  CssBaseline,
  ThemeProvider,
  Button,
} from '@material-ui/core';

import { Router } from './scenes/Routes';
import { SnackbarProvider } from 'notistack';
import themes from './them';

const store = createStore();
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
const App = () => (
  <ThemeProvider theme={themes.default}>
    <MstProvider value={store}>
      <SnackbarProvider
        // preventDuplicate
        hideIconVariant="false"
        autoHideDuration="30000"
        action={(key) => (
          <Button variant="contained" onClick={onClickDismiss(key)}>
            Закрити
          </Button>
        )}
        ref={notistackRef}
        maxSnack={6}
      >
        <CssBaseline />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </SnackbarProvider>
    </MstProvider>
  </ThemeProvider>
);

export default App;
