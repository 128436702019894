import React from 'react';
import s from './DeatailOrder.module.scss';
import * as Api from '../../../api';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Avatar,
  Tooltip,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { privateRoutes } from '../../../constants/routes';
import { propOr } from 'ramda';
import { useStore } from '../../../stores/stores';
import { DeleteIcon } from '../../../assetc/svg/deleteIcon';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { ArrowLeft } from '../../../assetc/svg/arrowLeft';
import {
  hasStatusValuated,
  hasStatusUnordered,
  getStatus,
} from '../../../constants/statuses';
import storageService from '../../../utils/storageService';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { Chat } from '../../Chat/Chat';

export const DeatailOrder = ({ ...props }) => {
  const [orderInfo, setOrder] = useState({
    isloding: true,
    order: {},
  });
  const history = useHistory();
  const { orders, users,statuses } = useStore();
  const AuthUser = users.authUser;

  const { enqueueSnackbar } = useSnackbar();

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }
  useEffect(() => {
    const orderId = Number(props.match.params.id);
    Api.infoOrder(orderId).then((res) => {
      setOrder({
        isloding: false,
        order: res.data.ordered_nomenclatures,
        orderStatus: propOr('', 'status', res.data),
      });
    });
  }, []);

  function CansleOrder() {
    let OrderId = orderInfo.order[0].order_id;

    Api.chengeOrder(OrderId, { status: 'declined' }).then((res) => {
      orders.deleteOrderStor(OrderId);
      history.push(privateRoutes.HOME);
    });
  }
  function orderBuy() {
    let OrderId = orderInfo.order[0].order_id;

    Api.chengeOrder(OrderId, { status: 'agreed' }).then((res) => {
      orders.deleteOrderStor(OrderId);
      history.push(privateRoutes.HOME);
    });
  }
  function orderBuyDocument() {
    let OrderId = orderInfo.order[0].order_id;

    Api.chengeOrder(OrderId, {
      status: 'agreed_without_contract',
    }).then((res) => {
      users.fetchUser(storageService.get('userId'));

      orders.deleteOrderStor(OrderId);
      history.push(privateRoutes.HOME);
    });
  }

  function notHasOrders() {
    infoMassege(
      'warning',
      'У вас більше немає безкоштовних замовлень, укладіть договір щоб продовжити роботу на сайті',
    );
  }

  let AllSum = 0;

  const deleteNomenclature = (nomenclatureId) => () => {
    return Api.deleteNomenclatureOrdered(nomenclatureId).then(
      (results) => {
        const orderId = Number(props.match.params.id);

        Api.infoOrder(orderId)
          .then((res) => {
            res.data.ordered_nomenclatures.length > 1
              ? setOrder({
                  isloding: false,
                  order: res.data.ordered_nomenclatures,
                  orderStatus: propOr('', 'status', res.data),
                })
              : history.push(privateRoutes.HOME);
          })
          .catch((res) => {
            if (AuthUser.role_code === 'ap') {
              history.push('/statuses/valuating/');
            } else {
              history.push(privateRoutes.HOME);
            }
          });

        results.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
      },
    );
  };
  let can_make_order = false,
    concluded = false;
console.log(AuthUser );

  if (!orderInfo.isloding) {
    if (AuthUser.role_code === 'ap') {
      can_make_order =
        orderInfo.order[0].client.client_profile.can_make_order;
      concluded =
        orderInfo.order[0].client.client_profile.company.concluded;
    } else {
      can_make_order = AuthUser.client_profile.can_make_order;
      concluded = propOr(false,'concluded',AuthUser.client_profile.company);
    }
  }




  // modall comment and file
  const [openModalChat, setOpenModalChat] = useState({
    isOpen: false,
  });

  const [modalChat, setModalChat] = React.useState({});
  const handleOpenChat = (event, popoverId, nomenclature_id) => {
    setModalChat({
      openedPopoverId: popoverId,
      isOpen: true,
      anchorEl: event.currentTarget,
      nomenclature_id: nomenclature_id,
    });
  };
  const handleCloseChat = () => {
    setModalChat({
      openedPopoverId: null,
      isOpen: false,
      anchorEl: null,
    });
  };



  return (
    <>
      {orderInfo.isloding ? (
        //// <Loader></Loader>
        <></>
      ) : (
        <>
          {}
          <Box my={6} mx={4}>
            <div className={s.oreder}>
              <Box mb={1}>
                <Link to={privateRoutes.HOME}>
                  <Tooltip
                    title="Повернутись на попередню сторінку"
                    arrow
                  >
                    <Button>
                      <ArrowLeft
                        style={{ transform: 'rotate(-180deg)' }}
                        size="24px"
                        color="#6c6e76"
                      ></ArrowLeft>
                      <Typography>Назад</Typography>
                    </Button>
                  </Tooltip>
                </Link>
              </Box>

              <Box className={s.orederHeader}>
                <Box className={s.orederHeaderInfo}>
                  <Box
                    className={`  ${
                      getStatus(orderInfo.orderStatus).classes
                    }`}
                  >
                    {getStatus(orderInfo.orderStatus).text}
                  </Box>
                  <Box className={s.orderTitle}>
                    Деталі замовлення № {orderInfo.order[0].order_id}
                  </Box>
                </Box>
                {hasStatusValuated(orderInfo.orderStatus) && (
                  <Box className={s.orederHeaderControl}>
                    <Button
                      onClick={CansleOrder}
                      className={s.orderCansleOrder}
                    >
                      Скасувати замовлення
                    </Button>

                    {concluded ? (
                      <Button
                        onClick={orderBuy}
                        className={s.orderBuy}
                      >
                        Погоджуюсь придбати
                      </Button>
                    ) : (
                      <>
                        {AuthUser.client_profile.can_make_order ===
                        true ? (
                          <Button
                            onClick={orderBuyDocument}
                            className={s.orderBuy}
                          >
                            Погоджуюсь, відправити договір
                          </Button>
                        ) : (
                          <Button
                            onClick={notHasOrders}
                            className={s.orderBuyDisable}
                          >
                            Погоджуюсь, відправити договір
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </div>
            <div className={s.orederMain}>
              <TableContainer>
                <Table
                  className={s.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                    <TableCell>Дата створення</TableCell>
                      
                       <TableCell>Менеджер</TableCell>
                      <TableCell>Напрям</TableCell>
                      <TableCell>Номенклакура</TableCell>
                      <TableCell>К-сть</TableCell>
                      <TableCell>Дата поставки</TableCell>
                      <TableCell>Адреса</TableCell> 
                      <TableCell>Коментарі та файли</TableCell>

                      {!hasStatusUnordered(orderInfo.orderStatus) && (
                        <>
                          <TableCell>Ціна грн/без ПДВ</TableCell>
                          <TableCell>Сума грн/без ПДВ</TableCell>
                        </>
                      )}
                      {hasStatusValuated(orderInfo.orderStatus) && (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderInfo.order.map((row, key) => {
                      console.log(row);

                      AllSum = AllSum + Number(row.total_price);
                      return (
                        <TableRow>
                           <TableCell>{row.created}</TableCell>
                          
                          <TableCell>
                            <Box className={s.manager} display="flex">
                              <Box mr={2}>
                                <Avatar
                                  style={{
                                    width: 40,
                                    height: 40,
                                    transition: '0.3s',
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={s.companyName}
                                >
                                  {row.full_name}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {propOr(
                              '',
                              'name',
                              row.nomenclature.direction,
                            )}
                          </TableCell>
                          <TableCell>
                            {row.nomenclature.name}
                          </TableCell>
                          <TableCell>{row.amount}</TableCell>
                         <TableCell>{row.date}</TableCell>
                          <TableCell>{row.address}</TableCell>

                          <TableCell>
                    <Button
                      type="button"
                      onClick={(e) => {
                        handleOpenChat(e,   1, row.id);
                      }}
                    >
                      Переглянути
                    </Button>

                    {modalChat.openedPopoverId ===   1 && (
                      <ModalWindow
                        open={
                          modalChat.openedPopoverId ===   1
                            ? modalChat
                            : modalChat
                        }
                        handleClose={handleCloseChat}
                        titleWindow="Коментарі та файли"
                      >
                        <Chat
                        AuthUser={AuthUser}
                        statuses={statuses}
                          nomenclature_id={modalChat.nomenclature_id}
                        ></Chat>
                      </ModalWindow>
                    )}
                  </TableCell>

                          {!hasStatusUnordered(
                            orderInfo.orderStatus,
                          ) && (
                            <>
                              <TableCell className={s.tdPrise}>
                                {row.price}
                              </TableCell>
                              <TableCell className={s.tdPrise}>
                                {row.total_price}
                              </TableCell>
                            </>
                          )}
                          {hasStatusValuated(
                            orderInfo.orderStatus,
                          ) && (
                            <TableCell colSpan={3}>
                              {/* {hasStatusUnordered(status) && ( */}
                              <Tooltip title="Видалення" arrow>
                                <MenuItem
                                  onClick={deleteNomenclature(row.id)}
                                >
                                  <DeleteIcon
                                    size="16px"
                                    color="#e94b51"
                                    display="block"
                                  ></DeleteIcon>
                                </MenuItem>
                              </Tooltip>
                              {/* )} */}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                    {!hasStatusUnordered(orderInfo.orderStatus) && (
                      <TableRow className={s.footerTable}>
                        <TableCell colSpan={5}> </TableCell>
                        <TableCell colSpan={4}>
                          Сума замовлення грн/без ПДВ
                        </TableCell>

                        <TableCell>{AllSum}</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </>
      )}
    </>
  );
};
