 


import React, { useState } from 'react';
import s from './TableOrderClient.module.scss';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableOrderRowManager } from '../../evaluationNomenclature/tableOrderRowManager/tableOrderRowManager';
import { TableOrderRowClient } from '../../UserOrdres/TableOrderRowClient/TableOrderRowClient';
// import { TableOrderRowManager } from '../tableOrderRowManager/tableOrderRowManager';

export const TableOrderClient = ({ ...props }) => {
  const {
    onClick,
    nomenclatures,
    tableData,
    setTablerData,
    filterValue,
    ManagerId,
    setFilterValue,
  } = props;

  const handleChangePage = (event, newPage) => {
    setFilterValue({ ...filterValue, page_number: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterValue({
      ...filterValue,
      page_number: 1,
      page_size: Number(event.target.value),
    });
  };
  const handleLineLabels = ({ from, to, count, page }) => {
    return `${from}-${to} з ${count !== -1 ? count : to}`;
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
          <TableRow>
              <TableCell></TableCell>
              {/* <TableCell>#ID</TableCell> */}
              <TableCell>
                <div>№ замовлення</div>
              </TableCell>
              <TableCell>Дата створення</TableCell>
              <TableCell>Номенклатура</TableCell>
              <TableCell>Напрям</TableCell>
              <TableCell>К-сть</TableCell>
              <TableCell>Дата поставки</TableCell>
              <TableCell>Адреса поставки</TableCell>
              <TableCell>Менеджер</TableCell>
              <TableCell>Коментар та файл</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Дія</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={'table_managers'}>
            {tableData.orders.map((item, index) => (
              <>
                 
              <TableOrderRowClient
                key={item.id}
                tableData={tableData}
                setTablerData={setTablerData}
                filterValue={filterValue}
                row={item}
                ManagerId={ManagerId}
              />
              </>
            ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={tableData.allCount}
            rowsPerPage={filterValue.page_size}
            labelRowsPerPage="Номенклатур на сторінці"
            labelDisplayedRows={handleLineLabels}
            page={filterValue.page_number - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </>
  );
};
