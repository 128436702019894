import React from 'react';
export const CopyIcon = ({ size, color, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      {...props}
      viewBox="0 0 24 24"
    >
      <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="order_creation" transform="translate(-1350.000000, -443.000000)">
            <g id="Group-5" transform="translate(273.000000, 382.000000)">
                <g id="table">
                    <g id="Group-3" transform="translate(0.000000, 32.000000)">
                        <g id="ic-/-24px-/-delete" transform="translate(1077.000000, 29.000000)">
                            <g id="info-24px">
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"/>
                            </g>
                            <g id="copy" transform="translate(1.000000, 1.000000)" fill="#8F9297">
                                <path d="M19,7.2 C20.5463973,7.2 21.8,8.4536027 21.8,10 L21.8,10 L21.8,19 C21.8,20.5463973 20.5463973,21.8 19,21.8 L19,21.8 L10,21.8 C8.4536027,21.8 7.2,20.5463973 7.2,19 L7.2,19 L7.2,10 C7.2,8.4536027 8.4536027,7.2 10,7.2 L10,7.2 Z M19,8.8 L10,8.8 C9.3372583,8.8 8.8,9.3372583 8.8,10 L8.8,10 L8.8,19 C8.8,19.6627417 9.3372583,20.2 10,20.2 L10,20.2 L19,20.2 C19.6627417,20.2 20.2,19.6627417 20.2,19 L20.2,19 L20.2,10 C20.2,9.3372583 19.6627417,8.8 19,8.8 L19,8.8 Z M12,0.2 C13.4891233,0.2 14.7067389,1.36246286 14.79489,2.82943181 L14.8,3 L14.8,4 C14.8,4.4418278 14.4418278,4.8 14,4.8 C13.592159,4.8 13.2555984,4.49481185 13.2062331,4.1003503 L13.2,4 L13.2,3 C13.2,2.37867966 12.7278003,1.86764746 12.122693,1.80619546 L12,1.8 L3,1.8 C2.37867966,1.8 1.86764746,2.27219968 1.80619546,2.87730697 L1.8,3 L1.8,12 C1.8,12.6213203 2.27219968,13.1323525 2.87730697,13.1938045 L3,13.2 L4,13.2 C4.4418278,13.2 4.8,13.5581722 4.8,14 C4.8,14.407841 4.49481185,14.7444016 4.1003503,14.7937669 L4,14.8 L3,14.8 C1.51087667,14.8 0.293261098,13.6375371 0.205110045,12.1705682 L0.2,12 L0.2,3 C0.2,1.51087667 1.36246286,0.293261098 2.82943181,0.205110045 L3,0.2 L12,0.2 Z" id="Combined-Shape"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    </svg>
  );
};



 