import React, { useState } from 'react';
import cx from 'classnames';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/uk';
import isSameDay from 'date-fns/isSameDay';
import moment from 'moment';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import format from 'date-fns/format';
import './InputFilterDataPicer.scss';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { makeJSDateObject } from './utils';
import { compareAsc } from 'date-fns';
import { useFormikContext } from 'formik';
import { propOr } from 'ramda';

const styles = (theme) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 24,
    height: 24,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  currentDay: {
    fontSize: '14px',
    borderRadius: '2px',
    color: 'inherit',
    transition: '.3s',
    background: 'none',
  },
  CurrentMonthDay: {
    fontSize: '14px',
    borderRadius: '2px',
    color: '#36373a',
    transition: '.3s',
    '&:hover': {
      transition: '.3s',
      borderRadius: '2px',
      background: '#d5d9e8',
    },
  },
  nonCurrentMonthDay: {
    fontSize: '14px',
    color: '#b4b6ba',
    background: 'none',
    cursor: 'default',
    '&:hover': {
      transition: '.3s',
      borderRadius: '2px',
      background: 'none',
      cursor: 'default',
    },
  },
  notWrapCurrentMonthDay: {
    background: 'none',
    cursor: 'default',
    '&:hover': {
      transition: '.3s',
      borderRadius: '2px',
      background: 'none',
      cursor: 'default',
    },
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    borderRadius: '2px',
    background: ' #2e408a',
    borderRadius: 2,
    color: theme.palette.common.white,
  },
});

const InputFilterDataPicer = ({ classes, ...props }, ...prop) => {
  DateFnsUtils.prototype.getCalendarHeaderText = function (date) {
    return format(date, 'LLLL yyyy', { locale: this.locale });
  };

  const { onChange, valueDate, placeholder } = props;

  const [isOpen, setIsOpen] = useState(false);

  const renderWrappedWeekDay = (
    date,
    selectedDate,
    dayInCurrentMonth,
  ) => {
    const today = new Date();
    // console.log(selectedDate );

    const isSelectedDate = isSameDay(
      propOr('', 'valueDate', props),
      date,
    );

    // console.log(isSelectedDate );

    const isToday = isSameDay(today, date);
    // const isMinActiveDate = isSameDay(
    //   props.selectedDate.minData,
    //   date,
    // );
    // const isMinDate =
    //   compareAsc(props.selectedDate.minData, date) > 0 ? true : false;

    const wrapperClassName = cx({
      [classes.highlight]: isSelectedDate,
      [classes.notWrapCurrentMonthDay]: !dayInCurrentMonth,
    });

    const dayClassName = cx(classes.day, {
      // [classes.currentDay]: isToday,
      // [classes.nonCurrentMonthDay]:

      [classes.CurrentMonthDay]: dayInCurrentMonth && !isSelectedDate,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(date, 'dd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <div className={`input_dataPicker inputFilterdataPicker ${propOr(null, 'valueDate', props)!==null?``:`input_dataPicker_plachholder`}`  }>
      <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
        <DatePicker
          className="dataPicer"
          clearable
          disableToolbar
          format="dd.MM.yyyy"
          margin="normal"
          id="dataPicer"
          variant="inline"
          // minDate=""
          value={propOr(null, 'valueDate', props)}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          shouldDisableDate={false}
          open={isOpen}
          emptyLabel={placeholder}
          onChange={(val) => {
            onChange(val);

            setIsOpen(false);
          }}
          renderDay={renderWrappedWeekDay}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withStyles(styles)(InputFilterDataPicer);
