import React from 'react';
import {
  Box,
  Typography,
  Button as MaterialButton,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Formik, Field, Form } from 'formik';

import ButtonCustom from '../Button';
import { CustomInput } from '../Form/Elements/input/input';
import { CustomInputMask } from '../Form/Elements/inputMask/inputMask';
import { TextareaInput } from '../../components/Form/Elements/TextareaInput/TextareaInput';

import useStyle from './style';
import { publicRoutes } from '../../constants/routes';

import {
  nameValidation,
  emailValidation,
  passwordValidation,
} from '../../utils/validations';

const initialValues = {
  phone_number: '',
  comment: 'Передзвоніть до мене і прийміть замовлення',
};
const validationSchema = Yup.object({
  comment: nameValidation("Коментар").required("Поле обов'язкове для заповнення"),
  phone_number: Yup.string().required("Поле обов'язкове для заповнення"),
});

export function UnregisterForm({ onSubmit, isLoading }) {
  const s = useStyle();

  return (
    <>
      <Box>
        <Typography variant="h3" className={s.formTitle}>
          Замовлення без Реєстрації
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className={s.form}>

          <Field
            placeholder="Номер телефону"
            name="phone_number"
            id="phone_number"
            mask="+380(99)999-99-99"
            type="tel"
            component={CustomInputMask}
          />
          <Field

            name="comment"
            id="comment"
            maxLength="520"
            // as="textarea"
            // autoComplete="true"
            component={TextareaInput}
          />

          <Box className={s.formFooterRegister}>
            <Box className={s.formFooterRegisterInfo}>
              <Typography variant="body1" color="textPrimary">
                Вже зареєстровані?
              </Typography>
              <Link
                className={s.formFooterLink}
                to={publicRoutes.LOGIN}
              >
                <Typography variant="body2">Увійти</Typography>
              </Link>
            </Box>
            <ButtonCustom
              text="Відправити"
              type="submit"
              isLoading={isLoading}
            />
          </Box>
        </Form>
      </Formik>

      {/* <Box display="flex" justifyContent="center">
        <MaterialButton size="large" className={s.googleButton}>
          Реєстрація через Gmail
        </MaterialButton>
      </Box> */}
    </>
  );
}

UnregisterForm.propTypes = {
  isLoading: bool,
  onSubmit: func.isRequired,
};

UnregisterForm.defaultProps = { isLoading: false };
