import { types as t } from 'mobx-state-tree';
 
const client = t.model('client',{
  fullName: t.maybeNull(t.string),
  companyName: t.maybeNull(t.string),
  photoUrl: t.maybeNull(t.string),
})
const lastPrice = t.model('lastPrice',{
  dataTime: t.maybeNull(t.string),
  price: t.maybeNull(t.string),
})

const NomenclaturesManagerModel = t
  .model('Nomenclatures', {
    id: t.number,
    orderId: t.number,
    address: t.maybeNull(t.string),
    amount: t.number,
    date: t.maybeNull(t.string),
    created: t.maybeNull(t.string),
    directions: t.maybeNull(t.string),
    nomenclature: t.maybeNull(t.string),
    price: t.maybeNull(t.number),
    client: t.maybeNull(client),
    lastPrice: t.array(lastPrice),
  })

  .views((store) => ({
    get fullName() {
      return `${store.profile.firstName} ${store.profile.firstName}`;
    },
  }));
 

export default NomenclaturesManagerModel;

