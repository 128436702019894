import React, { useEffect, useState } from 'react';
import * as Api from '../../../api';
import s from './ViewsAllManager.module.scss';
import { Box, Typography, Button } from '@material-ui/core';
import { TableManagerAll } from '../TableManagerAll/TableManagerAll';
import { propOr } from 'ramda';
import { useStore } from '../../../stores/stores';
import { ModalCreateManager } from '../ModalCreateManager/ModalCreate';
import { CreateManager } from '../CreatedManager/CreatedManager';
import { Loader } from '../../Loader/Loader';

export const ViewsAllManager = ({ ...props }) => {
  const { role,viewsManagerCompani } = props;
  const { users } = useStore();
  const AuthUser = users.authUser;
 
 
  let infoManager = {};
  if(viewsManagerCompani){
    infoManager.company_id = props.match.params.id
    
  }else{
    infoManager.company_id =
    AuthUser.role_code === 'ak'
      ? !!AuthUser.client_profile.company ? AuthUser.client_profile.company.id : 1
      : '';
  }
 

  const initValue = {
    company_id: infoManager.company_id,
    page_number: 1,
    page_size: 10,
    role: role,
    // is_admin: 0,
  };


  if(!viewsManagerCompani){
    initValue.is_admin=0;
  }

  const tableValue = {
    allCount: '',
    itemsPerPage: '',
    managers: [],
  };

  const [filterValue, setFilterValue] = useState(initValue);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTablerData] = useState(tableValue);
  const [open, setOpen] = useState({ isOpen: false });

  const handleOpen = () => {
    setOpen({ isOpen: true });
  };

  const handleClose = () => {
    setOpen({ isOpen: !open.isOpen });
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(Api.getAllManagers(getUrl(filterValue)))
      .then((res) => {
         
        setTablerData({
          ...tableData,
          allCount: res.data.count,
          itemsPerPage: res.data.results.length,
          managers: res.data.results,
        });
        
        setIsLoading(false);
      })
      .catch((res) => {
        
        setIsLoading(false);
      });
  }, [filterValue]);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          <Box my={6} mx={4}>
            <Box className={s.header}>
              <Typography className={s.MainTitle} variant="h4">
                Усі менеджери
              </Typography>

              {AuthUser.role_code !== 'ap' && (
                <Button
                  onClick={handleOpen}
                  className={s.btn_standart}
                >
                  Додати менеджера
                </Button>
              )}
            </Box>

            <Box className={s.tableWrap}>
              <Box className={s.tableBox}>
                <TableManagerAll
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  tableData={tableData}
                  role={role}
                  viewsManagerCompani={viewsManagerCompani}
                ></TableManagerAll>
              </Box>
            </Box>
          </Box>
          <ModalCreateManager
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          >
            <CreateManager
              handleClose={handleClose}
              company_id={infoManager.company_id}
              setTablerData={setTablerData}
              tableData={tableData}
              filterValue={filterValue}
              setTablerData={setTablerData}
            ></CreateManager>
          </ModalCreateManager>
        </>
      )}
    </>
  );
};

function getUrl(data) {
  return Object.entries(data)
    .map(([key, val]) => {
      if (typeof val === 'object') {
        return `${encodeURIComponent(key)}=${JSON.stringify(val)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          val,
        )}`;
      }
    })
    .join('&');
}
