import React, { useEffect } from 'react';
import s from './ArchiveUsersOrders.module.scss';

import { useStore } from '../../../stores/stores';
// import { propOr } from 'ramda';
import { observer } from 'mobx-react';
import { Loader } from '../../Loader/Loader';
import { TableOrder } from '../TableOrder/TableOrder';
import { Box, Typography } from '@material-ui/core';
// import { FilterOrders } from '../FilterOrders/FilterOrders';

export const ArchiveUsersOrders = observer(() => {
  const { orders, users } = useStore();
  const AuthUser = users.authUser;

  useEffect(() => {
    orders.fetchOrders(AuthUser.id);
  }, []);

  const ArchiveOrders = orders.archiveOrders.map((item) =>
    item.toJSON(),
  );

   
  return (
    <>
      {ArchiveOrders.isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          {ArchiveOrders.length > 0 ? (
            <Box my={6} mx={4}>
              <Typography className={s.MainTitle} variant="h4">
                Архівні замовлення
              </Typography>
              <Box className={s.tableWrap}>
                <Box className={s.tableBox}>
                  <TableOrder rows={ArchiveOrders}></TableOrder>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box  >
             
              <Box className={s.tableWrapArchive}>

                <Box my={6} mx={4}>
                  <Box className={s.OrdersEmpty}>
                    Замовлень не знайдено
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}

       
    </>
  );
});
