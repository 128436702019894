import s from './buttonOrder.module.scss';
import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { privateRoutes } from '../../../../constants/routes';
import { useStore } from '../../../../stores/stores';
import { useSnackbar } from 'notistack';

export const ButtonOrder = ({SetOpen}) => {
  const { users } = useStore();
  const AuthUser = users.authUser;

  const [open, setOpen] = React.useState(false);

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }
  const { enqueueSnackbar } = useSnackbar();

  function notHasOrders() {
    infoMassege(
      'warning',
      'У вас більше немає безкоштовних замовлень, укладіть договір щоб продовжити роботу на сайті',
    );
  }
// console.log(SetOpen );

console.log(AuthUser.client_profile );

  return (
    <>
      {AuthUser.client_profile.can_make_order === true ? (  
        <Link to={privateRoutes.CREATE_ORDER}  onClick={() =>     SetOpen({open:false})}>
          <Button
            variant="outlined"
            style={{ fontWeight: '600', margin: '30px 0px' }}
          >
            Оформити замовлення
          </Button>
        </Link>
      ) : (
        <Button
          role="button"
          // disabled
          variant="outlined"
          className={s.buttonDisable}
          onClick={notHasOrders}
          style={{ fontWeight: '600', margin: '30px 0px' }}
        >
          Офоромити замовлення
        </Button>
      )}

       
    </>
  );
};
