import { apiRequest } from './utils';

export const getDirections = () => {
  return apiRequest({
    method: 'GET',
    url: `/directions/`,
  });
};
export const getNomenclatures = () => {
  return apiRequest({
    method: 'GET',
    url: `/nomenclatures/`,
  });
};
export const getManagers = () => {
  return apiRequest({
    method: 'GET',
    url: `/managers/`,
  });
};

export const createOrder = (userID) => {
  return apiRequest({
    method: 'POST',
    url: `/orders/`,
    data: { client_profile_id: userID },
  });
};
export const copyOrder = (orderId) => {
  return apiRequest({
    method: 'POST',
    url: `/copy_order/`,
    data: { order_id: orderId },
  });
};

export const addOrderedNomenclatures = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/ordered_nomenclatures/`,
    data: data,
  });
};
export const addOrderedNomenclatureComments = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/ordered_nomenclature_comments/`,
    data: data,
  });
};
export const addOrderedNomenclatureFiles = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/ordered_nomenclature_files/`,
    data: data,
  });
};

export const chengeNomenclatureOrdered = (data) => {
  return apiRequest({
    method: 'PATCH',
    url: `/ordered_nomenclatures/${data.nomenclatureOrderOld}/`,
    data: data,
  });
};
export const copyNomenclatureOrdered = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/copy_nomenclature/`,
    data,
  });
};
export const deleteNomenclatureOrdered = (idOrder) => {
  return apiRequest({
    method: 'DELETE',
    url: `/ordered_nomenclatures/${idOrder}/`,
  });
};

export const orderFinishStep = (data) => {
  return apiRequest({
    method: 'POST',
    url: `/order_last_step/`,
    data: data,
  });
};
export const allOrderUsers = (id) => {
  return apiRequest({
    method: 'GET',
    url: `/orders/?user_id=${id}`,
  });
};
export const getNomenclatureOrder = (id) => {
 
  return apiRequest({
    method: 'GET',
    url: `/ordered_nomenclatures/?order_id=${id}`,
  });
}; 
 
export const fetchOrderUser = (id) => {
  return apiRequest({
    method: 'GET',
    url: `/orders/?user_id=${id}&viewer_id=${id}`,
  });
};
export const chengeOrder = (id, data) => {
  return apiRequest({
    method: 'PATCH',
    url: `/orders/${id}/`,
    data,
  });
};
export const deleteOrder = (id) => {
  return apiRequest({
    method: 'DELETE',
    url: `/orders/${id}/`,
  });
};
export const infoOrder = (id) => {
  return apiRequest({
    method: 'GET',
    url: `/orders/${id}/`,
  });
};





export const getOrderedNomenclatureComments = (nomenclature_id,user_id) => {
  return apiRequest({
    method: 'GET',
    url: `/ordered_nomenclature_comments/?ordered_nomenclature_id=${nomenclature_id}&viewer_id=${user_id}`,
  });
};

// export const getOrderedNomenclatureFiles = (nomenclature_id) => {
//   return apiRequest({
//     method: 'GET',
//     url: `/ordered_nomenclature_files/?ordered_nomenclature_id=${nomenclature_id}`,
//   });
// };