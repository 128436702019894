import React from 'react';
export const ViewsDetailSVG = ({ size, color, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      {...props}
      viewBox="0 0 18.453 18.453"
    >
      <g>
        <rect x="2.711" y="4.058" width="8.23" height="1.334" />
        <path d="M14.972,14.088c0.638-1.127,0.453-2.563-0.475-3.49c-0.549-0.549-1.279-0.852-2.058-0.852    c-0.779,0-1.51,0.303-2.059,0.852s-0.852,1.279-0.852,2.059c0,0.777,0.303,1.508,0.852,2.059c0.549,0.547,1.279,0.85,2.057,0.85    c0.507,0,0.998-0.129,1.434-0.375l3.262,3.262l1.101-1.102L14.972,14.088z M13.664,13.881c-0.652,0.652-1.796,0.652-2.448,0    c-0.675-0.676-0.675-1.773,0-2.449c0.326-0.326,0.762-0.506,1.225-0.506s0.897,0.18,1.224,0.506s0.507,0.762,0.507,1.225    S13.991,13.554,13.664,13.881z" />
        <path d="M13.332,16.3H1.857c-0.182,0-0.329-0.148-0.329-0.328V1.638c0-0.182,0.147-0.329,0.329-0.329    h11.475c0.182,0,0.328,0.147,0.328,0.329V8.95c0.475,0.104,0.918,0.307,1.31,0.597V1.638C14.97,0.735,14.236,0,13.332,0H1.857    C0.954,0,0.219,0.735,0.219,1.638v14.334c0,0.902,0.735,1.637,1.638,1.637h11.475c0.685,0,1.009-0.162,1.253-0.76l-0.594-0.594    C13.772,16.347,13.426,16.3,13.332,16.3z" />
        <rect x="2.711" y="7.818" width="8.23" height="1.334" />
      </g>
    </svg>
  );
};
