import React, { useContext, useState } from 'react';
import s from './SendManager.module.scss';
import {
  Button,
  Typography,
  TextareaAutosize,
} from '@material-ui/core';
// import { CustomInput } from '../Elements/input/input';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { CustomInput } from '../../Form/Elements/input/input';
import { CustomInputMask } from '../../Form/Elements/inputMask/inputMask';

import * as Api from '../../../api';
import { useSnackbar } from 'notistack';
import { updateManager } from '../ViewsAllManager/managerFunctions';
import { TextareaInput } from '../../Form/Elements/TextareaInput/TextareaInput';

export const SendManager = ({ ...props }) => {
  const {
    manager_id,
    client_id,
    company_id,
    handleClose,
    filterValue,
    tableData,
    setTablerData,
  } = props;

  const initialValues = {
    manager_id: manager_id,
    client_id: client_id,
    message: '',
    // rule: 'client',
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
  });

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const { enqueueSnackbar } = useSnackbar();

  function onSubmit(value) {
    Promise.resolve(Api.sendManager(value)).then((result) => {
      result.data.messages.map((item) =>
        infoMassege(item.status, item.text),
      );
      handleClose();
    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={s.Form_wrap}
      >
        <Form>
          <div className={s.Form_wrap}>
            <div>
             
              <Field
                placeholder="Повідомлення менеджеру"
                name="message"
                id="message"
                type="text"
                autofocus='true'
                
                rows="6"
                component={TextareaInput}
              />
            </div>
          </div>
          <div className={s.butoon__order_wrap}>
            <Button className={s.butoon__order} type="submit">
              Написати
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
