import * as Api from '../../api';

export const fetchDataFilters = ({ ...props }) => {
  let fetchDataFilter = props;

   
  let companies,
    directions,
    dates = {},
    dates_from = {},
    dates_to = {};

  return Api.fetchDataNomenclatureFilter(fetchDataFilter).then(
    (result) => {
      if (Object.keys(result.data).length > 0) {
        companies = formatDataOptions(result.data.companies);
        directions = formatDataOptions(result.data.directions);
        dates = formatDateOptions(result.data.dates);
        dates_from = formatDateOptions(result.data.dates_from);
        dates_to = formatDateOptions(result.data.dates_to);
      }

      return { companies, directions, dates, dates_from, dates_to };
    },
  );
};

function formatDataOptions(data) {
  return data.map((item) => {
    return {
      label: item.name,
      value: item.name,
      id: item.id,
    };
  });
}

function formatDateOptions(data) {
  return data.map((item) => {
    return {
      label: item,
      value: item,
      date: item,
    };
  });
}
