import { apiRequest } from './utils';
import storageService from '../utils/storageService';
import localStorageKeys from '../constants/localStorageKeys';
import { propOr } from 'ramda';

export const allCompanies = (data) => {
  return apiRequest({
    method: 'GET',
    url: `/companies/?page_number=${data.page_number}&page_size=${
      data.page_size
    }&manager_name_query=${propOr(
      '',
      'value',
      data.manager_name_query,
    )}&name_query=${propOr(
      '',
      'value',
      data.name_query,
    )}&edrpou_query=${propOr('', 'value', data.edrpou_query)}`,
  });
};
export const companyFilterValues = (data) => {
  return apiRequest({
    method: 'GET',
    url: `/company_filter_values/`,
  });
};
