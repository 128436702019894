import React from 'react';
export const DeleteIcon = ({ size, color, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      {...props}
      viewBox="0 0 24 24"
    >
          <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="order_creation" transform="translate(-1400.000000, -443.000000)">
            <g id="Group-5" transform="translate(273.000000, 382.000000)">
                <g id="table">
                    <g id="Group-3" transform="translate(0.000000, 32.000000)">
                        <g id="ic-/-24px-/-delete" transform="translate(1127.000000, 29.000000)">
                            <g id="info-24px">
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"/>
                            </g>
                            <g id="trash" transform="translate(2.000000, 1.000000)" fill="#E94B51">
                                <path d="M12,0.2 C13.4891233,0.2 14.7067389,1.36246286 14.79489,2.82943181 L14.8,3 L14.799,4.199 L19,4.2 C19.4418278,4.2 19.8,4.5581722 19.8,5 C19.8,5.40784105 19.4948119,5.74440158 19.1003503,5.79376686 L19,5.8 L17.799,5.799 L17.8,19 C17.8,20.4891233 16.6375371,21.7067389 15.1705682,21.79489 L15,21.8 L5,21.8 C3.51087667,21.8 2.2932611,20.6375371 2.20511004,19.1705682 L2.2,19 L2.199,5.799 L1,5.8 C0.5581722,5.8 0.2,5.4418278 0.2,5 C0.2,4.59215895 0.505188147,4.25559842 0.8996497,4.20623314 L1,4.2 L5.199,4.199 L5.2,3 C5.2,1.51087667 6.36246286,0.293261098 7.82943181,0.205110045 L8,0.2 L12,0.2 Z M3.799,5.799 L3.8,19 C3.8,19.6213203 4.27219968,20.1323525 4.87730697,20.1938045 L5,20.2 L15,20.2 C15.6213203,20.2 16.1323525,19.7278003 16.1938045,19.122693 L16.2,19 L16.199,5.799 L3.799,5.799 Z M12,1.8 L8,1.8 C7.37867966,1.8 6.86764746,2.27219968 6.80619546,2.87730697 L6.8,3 L6.799,4.199 L13.199,4.199 L13.2,3 C13.2,2.37867966 12.7278003,1.86764746 12.122693,1.80619546 L12,1.8 Z" id="Combined-Shape"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    </svg>
  );
};



 
 