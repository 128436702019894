import React, { useState } from 'react';
import s from './FormManagerAnsver.module.scss';
import { Formik, Form, Field } from 'formik';
import { Typography, Button } from '@material-ui/core';
import { TextareaInput } from '../Elements/TextareaInput/TextareaInput';
import { FileUpload } from '../../Order/fileUpload/fileUpload';
import * as Yup from 'yup';
import * as Api from './../../../api';
import { getStatus } from '../../../constants/statuses';
export const FormManagerAnsver = ({ ...props }) => {
  const { nomenclature_id, viewsStatus, AuthUser, setInfo, info, setIsFlag, statuses, viewsFile } = props;

  const [uploadFile, setUploadFile] = useState(null);
  const initialValues = {
    comment: '',
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
  });

  function onSubmit(value) {

    const file = new FormData();
    file.append('file', !!uploadFile ? uploadFile : '');
    file.append('author_id', AuthUser.id);
    file.append('ordered_nomenclature', nomenclature_id);
    file.append('status', statuses);
    file.append('text', value.comment);




    Promise.all([
      Api.addOrderedNomenclatureComments(file),
      // Api.addOrderedNomenclatureFiles(file),
    ]).then((result) => {

      console.log(result);
      let comment = result[0].data;

      // let api_comments = result[0].data;
      // let api_files = !!result[1].data.file ? result[1].data : null;


      if (comment.author.role === 'client') {
        comment['title'] = "Коментар"
      } else if (comment.author.role === 'manager' && (comment.status != null && viewsStatus.indexOf(comment.status) != -1)) {
        comment['title'] = `Відповідь менеджера після статусу "${getStatus(comment.status).text}"`
      } else if (comment.author.role === 'manager' && comment.status == null) {
        comment['title'] = `Відповідь менеджера після статусу "Розцінки"`
      }

      if (!!comment.file) {
        let formatFile = comment.file.split('.').pop();

        if (viewsFile.indexOf(formatFile) != -1) {
          comment['viewsFile'] = true;
        } else {
          comment['viewsFile'] = false;
        }
      }
      let infoStatus = [
        'valuating',
        'agreed',
      ];
      if (comment.author.role === 'manager' && comment.status != null && viewsStatus.indexOf(comment.status) != -1) {
        infoStatus.pop(comment.status)
      }


      let coment_new = info.comments;
      coment_new.push(comment);


      setInfo({
        comments: coment_new,
      });
      setIsFlag(false);

    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className={s.Form_wrap}>
          <div>
            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Коментар
              </Typography>
              <div className={s.inputWrap}>
                <Field
                  placeholder="Введіть коментар"
                  name="comment"
                  id="comment"
                  maxLength="520"
                  component={TextareaInput}
                />
              </div>
            </div>
            <div className={s.groupInput}>
              <Typography className={s.InpunTitle} variant="body1">
                Файл
              </Typography>
              <FileUpload
                setUploadFile={setUploadFile}
                uploadFile={uploadFile}
              />
            </div>
          </div>
          <div className={s.butoon__order_wrap}>
            <Button className={s.butoon__order} type="submit">
              Надіслати
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
