import React, { useState, useEffect } from 'react';
import s from './CreateOrder.module.scss';
import { Box, Typography } from '@material-ui/core';
import * as Api from './../../../api';
import { OneStepOrder } from '../OneStepOrder/OneStepOrder';
import { TwoStepOrder } from '../TwoStepOrder/TwoStepOrder';
import { ThreeStepOrder } from '../ThreeStepOrder/ThreeStepOrder';
import CreateInfoOrder from './CreateInfoOrderContext';
import storageService from '../../../utils/storageService';
import { useStore } from '../../../stores/stores';
import { useSnackbar } from 'notistack';

export const CreateOrder = () => {
  const { users } = useStore();
  const AuthUser = users.authUser;

  const [
    isLoaderOrderNomenclatures,
    setIsLoaderOrderNomenclatures,
  ] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [orderInfo, setOrderInfo] = useState({
    directions: {
      isLoading: true,
      value: [],
      list: [],
    },
    nomenclature: {
      isLoading: true,
      list: [],
      tempList: [],
      value: [],
    },
    manager: {
      isLoading: true,
      list: [],
      value: [],
    },
    newOrder: {
      orderID: '',
      nomenclatures: [],
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  useEffect(() => {
    if (storageService.get('continueOrderID')) {
      setActiveStep(1);
      setIsLoaderOrderNomenclatures(true);

      const continueOrderID = Number(
        storageService.get('continueOrderID'),
      );
      localStorage.removeItem('continueOrderID');

      Promise.all([
        Api.getDirections(),
        // Api.getNomenclatures(),
        Api.getManagers(),
        Api.getNomenclatureOrder(continueOrderID),
      ]).then((results) => {
        let tempOrderInfo = {
          directions: {
            isLoading: true,
            value: [],
            list: [],
          },
          nomenclature: {
            isLoading: true,
            list: [],
            tempList: [],
            value: [],
          },
          manager: {
            isLoading: true,
            list: [],
            value: [],
          },
          newOrder: {
            orderID: '',
            nomenclatures: [],
          },
        };
        let tempArrayDirections = [];

        results[0].data.forEach((element) => {
          tempArrayDirections.push({
            label: `${element.name}`,
            value: element.code,
            itemId: element.id,
          });
        });

        let allDirections = {
          label: `Усі напрямки`,
          value: 'all',
          itemId: 'all',
        };
        tempArrayDirections.unshift(allDirections);

        tempOrderInfo.directions.isLoading = false;
        tempOrderInfo.directions.list = tempArrayDirections;
 
        let tempArrayManagers = [];



        results[1].data.forEach((element) => {
          tempArrayManagers.push({
            phone: element.phone !== null ? element.phone : null,
            email: element.email,
            full_name: element.full_name,
            code: element.manager_profile.code,
            id: element.manager_profile.id,
          });
        });
 
        tempOrderInfo.manager.isLoading = false;
        tempOrderInfo.manager.list = tempArrayManagers;

        let tempArrayNewOrders = [];
        results[2].data.forEach((element) => {
          tempArrayNewOrders.push(element.nomenclature);
        });

        tempOrderInfo.newOrder.order_id = results[2].data[0].order_id;
        tempOrderInfo.newOrder.nomenclatures = results[2].data;

        setOrderInfo({
          ...orderInfo,
          directions: {
            ...orderInfo.directions,
            isLoading: false,
            list: tempArrayDirections,
          },
        
          manager: {
            ...orderInfo.manager,
            isLoading: false,
            list: tempArrayManagers,
          },
          newOrder: {
            orderID: tempOrderInfo.newOrder.order_id,
            nomenclatures: tempOrderInfo.newOrder.nomenclatures,
          },
        });
        console.log('123' );
        console.log('123' );
        
        setIsLoaderOrderNomenclatures(false);
      });
    } else {
      setIsLoaderOrderNomenclatures(true);
      const fetchDirections = () =>
        Promise.all([
          Api.getDirections(),
          Api.getManagers(),
        ])
          .then((results) => {
            let tempOrderInfo = {
              directions: {
                isLoading: true,
                value: [],
                list: [],
              },
              nomenclature: {
                isLoading: true,
                list: [],
                tempList: [],
                value: [],
              },
              manager: {
                isLoading: true,
                list: [],
                value: [],
              },
              newOrder: {
                orderID: '',
                nomenclatures: [],
              },
            };
            let tempArrayDirections = [];

            results[0].data.forEach((element) => {
              tempArrayDirections.push({
                label: `${element.name}`,
                value: element.code,
                itemId: element.id,
              });
            });

            let allDirections = {
              label: `Усі напрямки`,
              value: 'all',
              itemId: 'all',
            };
            tempArrayDirections.unshift(allDirections);

            tempOrderInfo.directions.isLoading = false;
            tempOrderInfo.directions.list = tempArrayDirections;
 
            let tempArrayManagers = [];
            results[1].data.forEach((element) => {
              tempArrayManagers.push({
                phone: element.phone !== null ? element.phone : null,
                email: element.email,
                full_name: element.full_name,
                code: element.manager_profile.code,
                id: element.manager_profile.id,
              });
            });

            tempOrderInfo.manager.isLoading = false;
            tempOrderInfo.manager.list = tempArrayManagers;

            setOrderInfo({
              ...orderInfo,
              directions: {
                ...orderInfo.directions,
                isLoading: false,
                list: tempArrayDirections,
              },
              
              manager: {
                ...orderInfo.manager,
                isLoading: false,
                list: tempArrayManagers,
              },
              newOrder: {
                ...orderInfo.newOrder,
              },
            });
            setIsLoaderOrderNomenclatures(false);
          })
          .catch((error) => console.log(error));
      fetchDirections();
    }
  }, []);

  // onChange Directions
  function onDirections(direction) {
    let filterNomenclature;
    if (direction.value !== 'all') {
      filterNomenclature = orderInfo.nomenclature.list.filter(
        (item) => item.direction.code === direction.value,
      );
    } else {
      filterNomenclature = orderInfo.nomenclature.list;
    }

    setOrderInfo({
      ...orderInfo,
      directions: {
        ...orderInfo.directions,
        value: direction,
      },
      nomenclature: {
        ...orderInfo.nomenclature,
        value: [],
        tempList: filterNomenclature,
      },
      manager: { ...orderInfo.manager, value: [] },
    });
  }

  // onChange Nomenclature
  function onNomenclature(value) {
     
    const managerValue = orderInfo.manager.list.filter(
      (item) => item.code === value.item.manager.code,
    );

    let directionValue;
    if (value.item.direction != null) {
      if (value.item.direction.code != null) {
        directionValue = orderInfo.directions.list.filter(
          (item) => item.value === value.item.direction.code,
        );
      } else {
        directionValue = [];
      }
    } else {
      directionValue = [];
    }

    setOrderInfo({
      ...orderInfo,
      nomenclature: {
        ...orderInfo.nomenclature,
        value: value.item,
        //     tempList: filterNomenclature,
      },

      directions: {
        ...orderInfo.directions,
        value: directionValue[0],
      },
      manager: { ...orderInfo.manager, value: managerValue },
    });
  }

  // onDelete
  const deleteNomenclature = (nomenclatureId) => () => {
    return Api.deleteNomenclatureOrdered(nomenclatureId).then(
      (results) => {
        let updateNomenclature = orderInfo.newOrder.nomenclatures.filter(
          (item) => item.id !== nomenclatureId,
        );
        setOrderInfo({
          ...orderInfo,
          newOrder: {
            ...orderInfo.newOrder,
            nomenclatures: updateNomenclature,
          },
        });
        results.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
      },
    );
  };

  const copyNomenclature = (nomenclatureId) => () => {
    let allNomenclatures = orderInfo.newOrder.nomenclatures;

    let copyNomenclature = JSON.parse(
      JSON.stringify(
        orderInfo.newOrder.nomenclatures.filter(
          (item) => item.id == nomenclatureId.nomenclature_id,
        )[0],
      ),
    );

    return Api.copyNomenclatureOrdered(nomenclatureId).then(
      (results) => {
        if (results.data.new_nomenclature_id) {
          copyNomenclature.id = results.data.new_nomenclature_id;
          allNomenclatures.unshift(copyNomenclature);

          setOrderInfo({
            ...orderInfo,
            newOrder: {
              ...orderInfo.newOrder,
              nomenclatures: allNomenclatures,
            },
          });
          results.data.messages.map((item) =>
            infoMassege(item.status, item.text),
          );
        }
      },
    );
  };

  function onTwoStep() {
    setActiveStep(1);
  }

  // function
console.log(orderInfo );

  return (
    <>
      <CreateInfoOrder.Provider
        value={{ ...orderInfo, setOrderInfo }}
      >
        {activeStep === 0 && (
          <Box my={6} mx={4}>
            <Typography className={s.MainTitle} variant="h4">
              Створення замовлення
            </Typography>
            <Box my={2} mb={6}>
              <Typography variant="body1" className={s.SubMainTitle}>
                Додайте товар до замовлення.
              </Typography>
            </Box>

            <OneStepOrder
              // directions={directions}
              // Nomenclature={Nomenclature}
              setActiveStep={setActiveStep}
              onNomenclature={onNomenclature}
              onDirections={onDirections}
              AuthUser={AuthUser}
              // manager={manager.value}
              // newOrder={newOrder}
              // setNewOrder={setNewOrder}
              // setOrderStep={setOrderStep}
            ></OneStepOrder>
          </Box>
        )}
        {activeStep === 1 && (
          <Box py={6} px={4}>
            <Typography className={s.MainTitle} variant="h4">
              Створення замовлення
            </Typography>
            <Box my={2} mb={2}>
              <Typography variant="h5" className={s.SubMainTitle}>
                Додайте товар до замовлення.
              </Typography>
            </Box>
            <TwoStepOrder
              onNomenclature={onNomenclature}
              onDirections={onDirections}
              setActiveStep={setActiveStep}
              deleteNomenclature={deleteNomenclature}
              copyNomenclature={copyNomenclature}
              isLoaderOrderNomenclatures={isLoaderOrderNomenclatures}
              AuthUser={AuthUser}
              // newOrder={newOrder}
            ></TwoStepOrder>
          </Box>
        )}

        {activeStep === 2 && (
          <Box my={6} mx={4}>
            <Typography className={s.MainTitle} variant="h4">
              Оформлення замовлення.
              {!AuthUser.client_profile.company && <>Реєстрація</>}
            </Typography>
            <Box my={2} mb={4}>
              <Typography variant="h5" className={s.SubMainTitle}>
                Для відправки замовлення заповніть реєстраційну форму
              </Typography>
            </Box>
            <ThreeStepOrder onTwoStep={onTwoStep}></ThreeStepOrder>
            {/* <TwoStepOrder></TwoStepOrder> */}
          </Box>
        )}
      </CreateInfoOrder.Provider>
    </>
  );
};
