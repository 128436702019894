import React, { useState } from 'react';
import s from './tableOrderManager.module.scss';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableOrderRowManager } from '../tableOrderRowManager/tableOrderRowManager';

export const TableOrderManager = ({ ...props }) => {
  const {
    onClick,
    nomenclatures,
    AuthUser,
    tableData,
    setTablerData,
    filterValue,
    statuses,
    setFilterValue,
  } = props;
 
  const handleChangePage = (event, newPage) => {
     
    setFilterValue({ ...filterValue, page_number: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
 

    setFilterValue({
      ...filterValue,
      page_number: 1,
      page_size: Number(event.target.value),
    });
  };
  const handleLineLabels = ({ from, to, count, page }) => {
    return `${from}-${to} з ${count !== -1 ? count : to}`;
  };

  return (
    <>
      <TableContainer>
        <Table className={s.table} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <div>№ замовлення</div>
              </TableCell>
              <TableCell>Дата створення</TableCell>
              <TableCell>Номенклатура</TableCell>
              <TableCell>Напрям</TableCell>
              <TableCell>К-сть</TableCell>
              <TableCell>Дата поставки</TableCell>
              <TableCell>Адреса поставки</TableCell>
              <TableCell>Компанія</TableCell>
              <TableCell>Коментарі та файли</TableCell>

              {AuthUser.role_code === 'ap' && (
                <TableCell>Менеджер</TableCell>
              )}
              
              <TableCell>Ціна (грн/без ПДВ)</TableCell>

              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={'table_managers'}>
            {console.log('data table', tableData.orders) }
            {tableData.orders.map((item, index) => (
              <TableOrderRowManager
                key={item.id}
                tableData={tableData}
                setTablerData={setTablerData}
                filterValue={filterValue}
                row={item}
                AuthUser={AuthUser}
                statuses={statuses}
              />
            ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={tableData.allCount}
            rowsPerPage={filterValue.page_size}
            labelRowsPerPage="Номенклатур на сторінці"
            labelDisplayedRows={handleLineLabels}
            page={filterValue.page_number - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </>
  );
};
