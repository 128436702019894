// import tinycolor from "tinycolor2";

const primary = '#494a4e';
const secondary = '#2e408a';
const warning = '#faad14';
const success = '#2fa372';
const info = '#9013FE';

export default {
  palette: {
    primary: {
      main: primary,
      // contrastText: "#6c6e76",
    },
    secondary: {
      main: secondary,
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    info: {
      main: info,
    },
    text: {
      primary: '#6c6e75',
      secondary: '#5866a1',
    },
    background: {
      default: '#f9fafa',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  overrides: {
    MuiPickersDay:{
      daySelected:{
        borderRadius: '2px',
        backgroundColor:'#2e408a',
      }
    },
     
    MuiInputBase:{
      adornedEnd:{
        border: '1px solid #dadbdc',
        fontSize: '14px',
        padding: '5px 10px',
        borderRadius: '4px',
        '&:after': {
          borderBottom:'none !important'
        },
        '&:before': {
          borderBottom:'none !important'
        },
      }
    },
    MuiTableCell:{
      head:{
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '1.5',
        color: '#6c6e76'
      },
      root:{
        padding: '16px 5px'      
      }
    },
    
    MuiPickersDay: {
      day: {
        width: '24px',
        height: '24px',
        borderRadius: '2px',
      },
    },
    MuiPickersCalendarHeader:{
      dayLabel:{
        width: '24px',
      }
    },
    MuiPickersCalendar:{
      transitionContainer:{
        minHeight: '120px'
      }
    },
    MuiPickersBasePicker:{
      pickerView:{
        minWidth: '220px',
        minHeight: '215px'
      }
    },
    
    MuiOutlinedInput: {
      input: {
        padding: '8px 16px',
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: '1',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '0',
        marginBottom: '0',
      },
    },

    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};
