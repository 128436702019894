import { types as t } from 'mobx-state-tree';

 

export const allSelectFilter = t.model('allSelectFilter', {
  value: t.maybeNull(t.number),
  label: t.maybeNull(t.string),
})
export const allData = t.model('allDirections', {
  value: t.maybeNull(t.string),
  label: t.maybeNull(t.string),
})
export const allDirections = t.model('allDirections', {
  value: t.maybeNull(t.string),
  label: t.maybeNull(t.string),
})

const status = t.model('status', {
  unordered: t.boolean,
  valuated: t.boolean,
  valuating: t.boolean,
})

export const filterValue = t.model('filterValue', {
  manager: t.maybeNull(t.string),
  direction: t.maybeNull(t.string),
  data: t.maybeNull(t.string),
  status: t.maybeNull(status),
})

 

