import { apiRequest } from './utils';

export const nomenclaturesManager = (id) => {
  return apiRequest({
    method: 'GET',
    url: `/ordered_nomenclatures/?manager_id=${id}`,
  });
};

export const valurionNomenclatures = (id, price) => {
  return apiRequest({
    method: 'PATCH',
    url: `/ordered_nomenclatures/${id}/`,
    data: { price: price },
  });
};
export const paginatedOrderedNomenclatures = (data) => {
  return apiRequest({
    method: 'GET',
    url: `/paginated_ordered_nomenclatures/?${data}`,
  });
};
export const paginatedOrdered = (data) => {
  return apiRequest({
    method: 'GET',
    url: `/paginated_orders/?${data}`,
  });
};

export const fetchDataNomenclatureFilter = (data) => {
 
  return apiRequest({
    method: 'POST',
    url: `/filter_values/`,
    data,
  });
};
