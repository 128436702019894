import React, { useState, useEffect } from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import { useFormikContext } from 'formik';
import   './CustomSelectAsync.scss';
import CreateInfoOrder from '../Order/CreateOrder/CreateInfoOrderContext';
import loadOptions from './AsyncFetchData';
import PropTypes from 'prop-types';
import { propOr, isNil } from 'ramda';

export const CustomSelectAsync = ({
  value,
  direction_id,
  nomenclature,
  onNomenclature,
  field,
  form: { touched, errors },
  ...props
}) => {
  const [inputValue, onInputChange] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
 
  
  const directionFilterId =
    direction_id === 'all' ? '' : direction_id;
  let selectValue =
    propOr('', ['name'], nomenclature) !== ''
      ? {
          value: propOr('', ['name'], nomenclature),
          label: propOr('', ['name'], nomenclature),
        }
      : null;

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!isNil(nomenclature) && nomenclature.length < 1) {
      setFieldValue('nomenclature', '');
    }
  }, [nomenclature]);

  const onMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const onMenuClose = () => {
    setMenuIsOpen(false);
  };

  let defaultAdditional = {
    page_number: 1,
    page_size: 30,
    directionNew: directionFilterId,
  };

  const wrappedLoadOptions = (...args) => {
    args[2].directionNew = directionFilterId;
    return loadOptions(...args);
  };

  return (
    <>
      <div className={`wrapSelect`}>
        <div>
          <AsyncPaginate
            value={selectValue}
            className={`inputSelect ${
              touched[field.name] && errors[field.name]
                ? 'inputSelect__box_error'
                : ''
            }`}
            direction_id={directionFilterId}
            additional={defaultAdditional}
            loadOptions={wrappedLoadOptions}
            onChange={(item) => {
              setFieldValue(field.name, item.value);
              onNomenclature(item);
            }}
            inputValue={inputValue}
            placeholder="Виберіть номенклатуру"
            onInputChange={onInputChange}
            menuIsOpen={menuIsOpen}
            cacheUniq={direction_id}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
          />
         </div>
 
        {touched[field.name] && errors[field.name] && (
          <div className="input_error">{errors[field.name]}</div>
        )}
      </div>
    </>
  );
};

CustomSelectAsync.propTypes = {
  direction_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onNomenclature: PropTypes.func,
};
CustomSelectAsync.defaultProps = { direction_id: 'all' };
