import { types as t } from 'mobx-state-tree';

const Company = t.model('Company', {
  id: t.maybeNull(t.number),
  name: t.maybeNull(t.string),
  edrpou: t.maybeNull(t.string),
  concluded: t.boolean,
});

const Profile = t.model('Profile', {
  id: t.maybeNull(t.number),
  company: t.maybeNull(Company),
  has_free_order: t.maybeNull(t.boolean),
  can_make_order: t.maybeNull(t.boolean),
  is_admin: t.maybeNull(t.boolean),
  full_name: t.maybeNull(t.string),
  address: t.maybeNull(t.string),
  site: t.maybeNull(t.string),
});
const Manager = t.model('Manager', {
  code: t.maybeNull(t.string),
  id: t.maybeNull(t.number),
  is_admin: t.boolean,
});

const UserModel = t
  .model('User', {
    id: t.number,
    email: t.maybeNull(t.string),
    role: t.maybeNull(t.string),
    full_name: t.maybeNull(t.string),
    phone_number: t.maybeNull(t.string),
    photo: t.maybeNull(t.string),
    is_superuser: t.boolean,
    client_profile: t.maybeNull(Profile),
    manager_profile: t.maybeNull(Manager),
    role_code:t.maybeNull(t.string),
  })

  .views((store) => ({
    get fullName() {
      return `${store.profile.firstName} ${store.profile.firstName}`;
    },
  }));

export default UserModel;
