import React from 'react';
import {
  Box,
  Typography,
  Button as MaterialButton,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Formik, Field, Form } from 'formik';

import ButtonCustom from '../Button';
import { CustomInput } from '../../components/Form/Elements/input/input';
import { CustomInputMask } from '../../components/Form/Elements/inputMask/inputMask';

import useStyle from './style';
import { publicRoutes } from '../../constants/routes';

import {
  nameValidation,
  emailValidation,
  passwordValidation,
} from '../../utils/validations';


const initialValues = {
  phone_number: '',
  email: '',
  full_name: '',
  password: '',
  passwordConfirm: '',
};
const validationSchema = Yup.object({
  full_name: nameValidation("Ім'я").required("Поле обов'язкове для заповнення"),
  phone_number: Yup.string().required("Поле обов'язкове для заповнення"),
  email: emailValidation(),
  password1: passwordValidation(),
  password2: passwordValidation().oneOf(
    [Yup.ref('password1'), null],
    'Паролі повинні співпадати',
  ),
});

export function RegisterForm({ onSubmit, isLoading }) {
  const s = useStyle();
  return (
    <>
      <Box>
        <Typography variant="h3" className={s.formTitle}>
          Реєстрація
        </Typography>
      </Box>


      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className={s.form}>
          <Field
            placeholder="Прізвище, ім'я по батькові"
            name="full_name"
            id="full_name"
            type="text"
            component={CustomInput}
          />
          <Field
            placeholder="Номер телефону"
            name="phone_number"
            id="phone_number"
            mask="+380(99)999-99-99"
            type="tel"
            component={CustomInputMask}
          />
          <Field
            placeholder="Email"
            name="email"
            id="email"
            type="text"
            component={CustomInput}
          />
          <Field
            placeholder="Пароль"
            name="password1"
            id="password1"
            type="password"
            component={CustomInput}
          />
          <Field
            placeholder="Повторіть пароль"
            name="password2"
            id="password2"
            key="password2"
            type="password"
            component={CustomInput}
          />

          <Box className={s.formFooterRegister}>
            <Box className={s.formFooterRegisterInfo}>
              <Typography variant="body1" color="textPrimary">
                Вже зареєстровані?
              </Typography>
              <Link
                className={s.formFooterLink}
                to={publicRoutes.LOGIN}
              >
                <Typography variant="body2">Увійти</Typography>
              </Link>
            </Box>
            <ButtonCustom
              text="Зареєструватись"
              type="submit"
              isLoading={isLoading}
            />
          </Box>
          <Box className={s.form_title}>
            <Typography variant="body1" color="textPrimary">
              А хочете ми приймемо Ваше замовлення без реєстрації!
          </Typography>
            <div className={s.unregister_button__blok}>
              <Link
                className={`${s.formFooterLink} ${s.unregister_button}`}
                to={publicRoutes.UNREGISTER}
              >
                <ButtonCustom
                  text="без реєстрації"
                  type="div"
                  isLoading={isLoading}
                />
              </Link>
            </div>
          </Box>
        </Form>

      </Formik>

      {/* <Box display="flex" justifyContent="center">
        <MaterialButton size="large" className={s.googleButton}>
          Реєстрація через Gmail
        </MaterialButton>
      </Box> */}
    </>
  );
}

RegisterForm.propTypes = {
  isLoading: bool,
  onSubmit: func.isRequired,
};

RegisterForm.defaultProps = { isLoading: false };
