import React, { useEffect, useState, memo } from 'react';
import { Box } from '@material-ui/core';
import { Route, useHistory } from 'react-router-dom';
import s from './Home.module.scss'; 
import { Sidebar } from '../../components/Sidebar/Sidebar.js';
import { UserSetings } from '../../components/Users/UserSetings/UserSetings.js';
import { privateRoutes } from '../../constants/routes';
import localStorageKeys from '../../constants/localStorageKeys';
import { CreateOrder } from '../../components/Order/CreateOrder/CreateOrder.js';
import storageService from '../../utils/storageService';
import { useStore } from '../../stores/stores.js';
import { observer } from 'mobx-react';
import { Loader } from '../../components/Loader/Loader.js';
import { ViewOrders } from '../../components/UserOrdres/ViewOrders/ViewOrders';
import { DeatailOrder } from '../../components/UserOrdres/DeatailOrder/DeatailOrder';
import { ArchiveUsersOrders } from '../../components/UserOrdres/ArchiveUsersOrders/ArchiveUsersOrders';
import { ManagerViewsOrders } from '../../components/evaluationNomenclature/managerViewsOrders/managerViewsOrders';
import { ViewsAllManager } from '../../components/ManagerActions/ViewsAllManager/ViewsAllManager';
import { ViewsRequestManagers } from '../../components/ManagerActions/ViewsRequestManagers/ViewsRequestManagers';
import { ManagerDetailOrder } from '../../components/ManagerActions/ManagerDetailOrder/ManagerDetailOrder';
import { propOr } from 'ramda';
import { isRuleUsers } from '../../utils/general';
import { DetailUser } from '../../components/Users/DetailedUserInformation/DetailUser';
import { All_Companies } from '../../components/Companies/allCompanies/allCompanies';
const MainScreen = ({ SetOpen, currentLocation }) => {
  const [isLoading, setisLoading] = useState(true);
  const history = useHistory();
  const { users } = useStore();
  const AuthUser = users.authUser;

  useEffect(() => {
    const userID = storageService.get(localStorageKeys.USER_ID);

    Promise.resolve(users.fetchUser(userID))
      .then((result) => {
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
      });
  }, []);

  return (
    <Box className={s.mainScreen}>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          {(AuthUser.role_code === 'ap' ||
            AuthUser.role_code === 'mp') &&
            currentLocation === privateRoutes.HOME &&
            history.push('/statuses/valuating/')}
          <Box className={s.mainScreenSidebar}>
            <Sidebar SetOpen={SetOpen} />
          </Box>

          <div className={s.mainScreenHero}>
            <Route
              path={privateRoutes.SETTINGS}
              component={UserSetings}
              exact
            />
            <Route
              path={privateRoutes.CREATE_ORDER}
              component={CreateOrder}
              exact
            />

            {AuthUser.manager_profile !== null ? (
              <Route
                path={privateRoutes.STATUSES}
                component={ManagerViewsOrders}
              />
            ) : (
              <Route
                path={privateRoutes.HOME}
                component={ViewOrders}
                exact
              ></Route>
            )}
            {AuthUser.manager_profile !== null ? (
              <></>
            ) : (
              <Route
                path={privateRoutes.ARCHIVE}
                component={ArchiveUsersOrders}
                exact
              ></Route>
            )}

            {AuthUser.role_code === 'ak' ||
            AuthUser.role_code === 'ap' ? (
              <>
                <Route
                  path={privateRoutes.SETTINGS_ID}
                  component={DetailUser}
                  exact
                ></Route>
                <Route
                  path={privateRoutes.REQUESTMANAGER}
                  component={(props) => (
                    <ViewsRequestManagers role={AuthUser.role} />
                  )}
                  exact
                />
                <Route
                  path={privateRoutes.MANAGERDETAILINFO}
                  component={(props) => (
                    <ManagerDetailOrder
                      AuthUser={AuthUser}
                      role={AuthUser.role}
                      {...props}
                    />
                  )}
                  exact
                />
                <Route
                  path={privateRoutes.MANAGERS}
                  component={(props) => (
                    <ViewsAllManager role={AuthUser.role} />
                  )}

                  // exact
                />
              </>
            ) : null}
 
            {AuthUser.role_code === 'ap' && (
              <>
                <Route
                  path={privateRoutes.COMPANI_ID}
                  component={(props) => (
                    <ViewsAllManager
                      role="client"
                      {...props}
                      viewsManagerCompani="true"
                    />
                  )}
                  exact
                />
                <Route
                  path={privateRoutes.MANAGERSORDER}
                  component={(props) => (
                    <ManagerDetailOrder
                      AuthUser={AuthUser}
                      allManagerCheck="true"
                      role="manager"
                      {...props}
                    />   
                  )}
                  exact
                />
                <Route
                  path={privateRoutes.ALL_COMPANIES}
                  component={(props) => (
                    <All_Companies role={AuthUser.role} />
                  )}
                  exact
                />
                <Route
                  path={privateRoutes.COMPANI_MANAGER_INFO}
                  component={(props) => (
                    <ManagerDetailOrder
                      AuthUser={AuthUser}
                      role="client"
                      {...props}
                    />
                  )}
                  exact
                />
              </>
            )}

            <Route
              path={privateRoutes.ORDER}
              component={DeatailOrder}
            />
          </div>
        </>
      )}
    </Box>
  );
};
export default memo(observer(MainScreen));
