import React from 'react';
export const MoreVert = ({ size, color, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      {...props}
      viewBox="0 0 16 16"
    >
    <g   stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g   transform="translate(-1408.000000, -561.000000)">
            <g  transform="translate(272.000000, 307.000000)">
                <g   transform="translate(1136.000000, 254.000000)">
                  
                        <polygon  points="0 0 16 0 16 16 0 16"/>
            
                    <path d="M8,13 C8.82842712,13 9.5,13.6715729 9.5,14.5 C9.5,15.3284271 8.82842712,16 8,16 C7.17157288,16 6.5,15.3284271 6.5,14.5 C6.5,13.6715729 7.17157288,13 8,13 Z M8,6.5 C8.82842712,6.5 9.5,7.17157288 9.5,8 C9.5,8.82842712 8.82842712,9.5 8,9.5 C7.17157288,9.5 6.5,8.82842712 6.5,8 C6.5,7.17157288 7.17157288,6.5 8,6.5 Z M8,0 C8.82842712,0 9.5,0.671572875 9.5,1.5 C9.5,2.32842712 8.82842712,3 8,3 C7.17157288,3 6.5,2.32842712 6.5,1.5 C6.5,0.671572875 7.17157288,0 8,0 Z" fill="#8F9297"/>
                </g>
            </g>
        </g>
    </g>  
    </svg>
  );
};
