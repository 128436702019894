import { types as t } from 'mobx-state-tree';

import AuthStore from './AuthStore';
import UsersStore from './UsersStore';
import OrdersStore from './OrdersStore';
import NomenclaturesManagerStore from './NomenclaturesManagerStore';

const RootStore = t
  .model('RootStore', {
    auth: t.optional(AuthStore, {}),
    users: t.optional(UsersStore, {}),
    orders: t.optional(OrdersStore, {}),
    nomenclaturesManager:t.optional(NomenclaturesManagerStore,{})
  })
  .actions((store) => ({
    clearStore() {
      store = {};
    },
  }));

export default RootStore;
