import React, { useState } from 'react';
import s from './tableOrderRowManager.module.scss';
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import { ArrowLeft } from '../../../assetc/svg/arrowLeft';
import { ArrowDown } from '../../../assetc/svg/arrowDown';
import { CustomStatus } from '../../Order/CustomStatus/CustomStatus';
import { ChengeStatus } from '../../ChengeStatus/ChengeStatus';
import { propOr } from 'ramda';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { Chat } from '../../Chat/Chat';

export const TableOrderRowManager = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);

  const {
    tableData,
    filterValue,
    setTablerData,
    AuthUser,
    statuses,
    row,
    row: { header, main },
  } = props;


  // modall comment and file
  const [openModalChat, setOpenModalChat] = useState({
    isOpen: false,
  });

  const [modalChat, setModalChat] = React.useState({});
  const itemsRef = React.useRef([]);

  const handleOpenChat = (event, popoverId, nomenclature_id) => {
    setModalChat({
      openedPopoverId: popoverId,
      isOpen: true,
      anchorEl: event.currentTarget,
      nomenclature_id: nomenclature_id,
    });

    if(!!itemsRef.current[nomenclature_id]){

      itemsRef.current[nomenclature_id].classList.add(s.hidden_newMessageMark)
    }


  };
  const handleCloseChat = () => {
    setModalChat({
      openedPopoverId: null,
      isOpen: false,
      anchorEl: null,
    });
  };






  return (
    <>
      <TableRow className={s.root}>
        <TableCell className={s.toggleTd}>
          {row.ordered_nomenclatures.length > 1 && (
            <IconButton
              className={s.toggleClass}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <ArrowLeft size="24px" />
              ) : (
                <ArrowDown size="24px" />
              )}
            </IconButton>
          )}
        </TableCell>

        <TableCell rowSpan={open ? row.countNomenclatures : 1}>
          <div className={s.alignCenter}>{row.id}</div>
        </TableCell>
        <TableCell>{header[0].created}</TableCell>
        <TableCell>{header[0].nomenclature.name}</TableCell>
        <TableCell>
          {propOr('', 'name', header[0].nomenclature.direction)}
        </TableCell>
        <TableCell>{header[0].amount}</TableCell>
        <TableCell>{header[0].date}</TableCell>
        <TableCell>{header[0].address}</TableCell>
        <TableCell>
          <Box className={s.userInfo} display="flex">
            <Box mr={2}>
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                  transition: '0.3s',
                }}
              />
            </Box>
            <Box>
              <Typography variant="body1" className={s.fullName}>
                {header[0].client.full_name}
              </Typography>
              <Typography variant="body1" className={s.companyName}>
                {header[0].client.client_profile.company.name}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
                    <Button
                      type="button"
                      onClick={(e) => {
                        handleOpenChat(e,   1, header[0].id);
                      }}
                    >
                      Переглянути
                      {header[0].has_unread_comments && (
              <div ref={el => itemsRef.current[header[0].id] = el} className={s.newMessageMark  }></div>
            )}
                    </Button>

                    {modalChat.openedPopoverId ===   1 && (
                      <ModalWindow
                        open={
                          modalChat.openedPopoverId ===   1
                            ? modalChat
                            : modalChat
                        }
                        handleClose={handleCloseChat}
                        titleWindow="Коментарі та файли"
                      >
                        <Chat
                        AuthUser={AuthUser}
                        statuses={statuses}
                          nomenclature_id={modalChat.nomenclature_id}
                        ></Chat>
                      </ModalWindow>
                    )}
                  </TableCell>
        {AuthUser.role_code === 'ap' && (
          <TableCell>
            <Box className={s.userInfo} display="flex">
              <Box mr={2}>
                <Avatar
                  style={{
                    width: 40,
                    height: 40,
                    transition: '0.3s',
                  }}
                />
              </Box>
              <Box>
                <Typography variant="body1" className={s.fullName}>
                  {header[0].full_name}
                </Typography>
              </Box>
            </Box>
          </TableCell>
        )}

        <TableCell>{header[0].price}</TableCell>

        <TableCell>
          {row.statuses_for_select.length > 0 ? (
            <ChengeStatus
              filterValue={filterValue}
              tableData={tableData}
              setTablerData={setTablerData}
              orderId={row.id}
              value={row.status}
              chengeValue={row.statuses_for_select[0]}
            ></ChengeStatus>
          ) : (
            <CustomStatus status={row.status}></CustomStatus>
          )}
        </TableCell>
      </TableRow>
      {main.map((line, key) =>
        open ? (
          <TableRow key={key}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{line.created}</TableCell>
            <TableCell>{line.nomenclature.name}</TableCell>
            <TableCell>
              {' '}
              {propOr(' ', 'name', line.nomenclature.direction)}
            </TableCell>
            <TableCell>{line.amount}</TableCell>
            <TableCell>{line.date}</TableCell>
            <TableCell>{line.address}</TableCell>
            <TableCell>
              <Box className={s.userInfo} display="flex">
                <Box mr={2}>
                  <Avatar
                    style={{
                      width: 40,
                      height: 40,
                      transition: '0.3s',
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" className={s.fullName}>
                    {line.client.full_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={s.companyName}
                  >
                    {line.client.client_profile.company.name}
                  </Typography>
                </Box>
              </Box>
            </TableCell>

            <TableCell>
                    <Button
                      type="button"
                      onClick={(e) => {
                        handleOpenChat(e,   (key+2), line.id);
                      }}
                    >
                      Переглянути
                      {line.has_unread_comments && (
              <div ref={el => itemsRef.current[line.id] = el} className={s.newMessageMark  }></div>
            )}
                    </Button>

                    {modalChat.openedPopoverId ===    (key+2) && (
                      <ModalWindow
                        open={
                          modalChat.openedPopoverId ===   (key+2)
                            ? modalChat
                            : modalChat
                        }
                        handleClose={handleCloseChat}
                        titleWindow="Коментарі та файли"
                      >
                        <Chat
                        AuthUser={AuthUser}
                        statuses={statuses}
                          nomenclature_id={modalChat.nomenclature_id}
                        ></Chat>
                      </ModalWindow>
                    )}
                  </TableCell>

            {AuthUser.role_code === 'ap' && (
              <TableCell>
                <Box className={s.userInfo} display="flex">
                  <Box mr={2}>
                    <Avatar
                      style={{
                        width: 40,
                        height: 40,
                        transition: '0.3s',
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className={s.fullName}
                    >
                      
                      {line.full_name}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            )}
            <TableCell>{line.price}</TableCell>

            <TableCell></TableCell>
          </TableRow>
        ) : (
          <></>
        ),
      )}
    </>
  );
};
