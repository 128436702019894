import { isNil, propOr } from 'ramda';
import storageService from './storageService';

export const isProd = () => process.env.NODE_ENV === 'production';
export const isLoggedIn = () => !isNil(storageService.get('userId'));
 

export function getTimeData(dataValue){
    var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    var date = new Date(dataValue.replace(pattern, '$3-$2-$1'));
    return date.getTime() 
}
