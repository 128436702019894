import React, { useState, useEffect } from 'react';
import s from './baseFilter.module.scss';
import { Box, Button } from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment';

import * as Api from '../../api';
import { getTimeData } from '../../utils/general';
import InputDataPicer from '../InputDataPicer/InputDataPicer';
import InputFilterDataPicer from '../InputFilterDataPicer/InputFilterDataPicer';

export const BaseFilter = ({ ...props }) => {
  const {
    defaultValue,
    filterData,
    setFilterData,
    filterValue,
    changefilterValue,
    filterStatus,
    managerData,
    allManagerCheck,
    role,
  } = props;

  function onFilterCompanies(item) {
    if (defaultValue.hasOwnProperty('name_query')) {
      console.log(item );
      
      changefilterValue({
        ...filterValue,
        page_number: 1,
        name_query: item,
      });
    }
  }
  function onFilterCompaniesManager(item) {
    if (defaultValue.hasOwnProperty('manager_name_query')) {
      console.log(item );
      
      changefilterValue({
        ...filterValue,
        page_number: 1,
        manager_name_query: item,
      });
    }
  }
  function onFilterCompaniesEdrpou(item) {
    if (defaultValue.hasOwnProperty('edrpou_query')) {
      console.log(item );
      
      changefilterValue({
        ...filterValue,
        page_number: 1,
        edrpou_query: item,
      });
    }
  }
  



  function onFilterCompany(item) {
    if (defaultValue.hasOwnProperty('company_id')) {
      changefilterValue({
        ...filterValue,
        page_number: 1,
        company_id: item,
      });
    }
  }
  function onDirection(item) {
    if (defaultValue.hasOwnProperty('direction_id')) {
      changefilterValue({
        ...filterValue,
        page_number: 1,
        direction_id: item,
      });
    }
  }
  function onDate(item) {
    if (defaultValue.hasOwnProperty('date')) {
      changefilterValue({
        ...filterValue,
        page_number: 1,
        date: item,
      });
    }
  }
  function onDateTo(item) {
    if (defaultValue.hasOwnProperty('date_to')) {
      let data = moment(new Date(item)).format('DD.MM.YYYY');

      changefilterValue({
        ...filterValue,
        page_number: 1,
        date_to: data,
        date_to_not_format: item,
      });
    }
  }
  function onDateFrom(item) {
    if (defaultValue.hasOwnProperty('date_from')) {
      let data = moment(new Date(item)).format('DD.MM.YYYY');

      changefilterValue({
        ...filterValue,
        page_number: 1,
        date_from: data,

        date_from_not_format: item,
      });
    }
  }

  function onCleareFilter() {
    console.log(filterValue);

    changefilterValue(defaultValue);
  }

  const onChangeStatus = (status) => () => {
    let tempArray = filterValue.statuses;

    if (tempArray.indexOf(status) != -1) {
      let tempArrayStatus = filterValue.statuses;
      tempArray = tempArrayStatus.filter((item) => item !== status);
    } else {
      tempArray.push(status);
    }

    changefilterValue({
      ...filterValue,
      page_number: 1,
      statuses: tempArray,
    });
    // console.log(filterValue);
  };
  const onChangeManager = (values) => {
    let tempArrayManagers = values.map((item) => {
      return Number(item.value);
    });
    console.log(tempArrayManagers);

    changefilterValue({
      ...filterValue,
      page_number: 1,
      manager_profile_ids: tempArrayManagers,
    });
  };

  let field = {
    name: 'name',
  };

  return (
    <>
      <Box>
        <Box>Фільтр</Box>
        <Box>
          <Box className={s.filterWrap}>
            <Box className={s.selectWrap}>
              {defaultValue.hasOwnProperty('company_id') && (
                <Box className={s.selectBox}>
                  <Select
                    placeholder="Компанія"
                    name="company"
                    id="company"
                    value={filterValue.company_id}
                    options={filterData.companies}
                    onChange={onFilterCompany}
                  />
                </Box>
              )}
              {defaultValue.hasOwnProperty('direction_id') && (
                <Box className={s.selectBox}>
                  <Select
                    placeholder="Напрям"
                    name="directions"
                    id="directions"
                    options={filterData.directions}
                    value={filterValue.direction_id}
                    onChange={onDirection}
                  />
                </Box>
              )}

              {defaultValue.hasOwnProperty('date') && (
                <Box className={s.selectBox}>
                  <Select
                    placeholder="Дата"
                    name="dates"
                    id="dates"
                    options={filterData.dates}
                    value={filterValue.date}
                    onChange={onDate}
                  />
                </Box>
              )}

              {/* companies */}

              {defaultValue.hasOwnProperty('name_query') && (
                <Box className={s.selectBox}>
                  <Select
                    placeholder="Компанія"
                    name="companies"
                    id="companies"
                    value={filterValue.name_query}
                    options={filterData.companies.map((item) => {
                      return { value: item, label: item };
                    })}
                    onChange={onFilterCompanies}
                  />
                </Box>
              )}
              {defaultValue.hasOwnProperty('manager_name_query') && (
                <Box className={s.selectBox}>
                  <Select
                    placeholder="Менеджер"
                    name="manager_name_query"
                    id="manager_name_query"
                    options={filterData.managers.map((item) => {
                      return { value: item, label: item };
                    })}
                    value={filterValue.manager_name_query}
                    onChange={onFilterCompaniesManager}
                  />
                </Box>
              )}

              {defaultValue.hasOwnProperty('edrpou_query') && (
                <Box className={s.selectBox}>
                  <Select
                    placeholder="ЄДРПОУ"
                    name="edrpou"
                    id="edrpou"
                    options={filterData.edrpous.map((item) => {
                      return { value: item, label: item };
                    })}
                    value={filterValue.edrpou_query}
                    onChange={onFilterCompaniesEdrpou}
                  />
                </Box>
              )}

              {/*  */}

              {defaultValue.hasOwnProperty('date_from') && (
                <Box className={s.selectBox}>
                  {/* //   <Select
                //     placeholder="Дата від"
                //     name="date_from"
                //     id="date_from"
                //     options={filterData.dates_from}
                //     value={filterValue.date_from}
                //     onChange={onDateFrom}
                //   /> */}
                  <InputFilterDataPicer
                    onChange={onDateFrom}
                    name="date_from"
                    placeholder="Дата від"
                    valueDate={filterValue.date_from_not_format}
                    id="date_from"
                  ></InputFilterDataPicer>
                </Box>
              )}
              {defaultValue.hasOwnProperty('date_to') && (
                <Box className={s.selectBox}>
                  {/* <Select
                    placeholder="Дата до"
                    name="date_to"
                    id="date_to"
                    options={filterData.dates_to}
                    value={filterValue.date_to}
                    onChange={onDateTo}
                  /> */}
                  <InputFilterDataPicer
                    onChange={onDateTo}
                    name="date_to"
                    placeholder="Дата до"
                    valueDate={filterValue.date_to_not_format}
                    id="date_to"
                  ></InputFilterDataPicer>
                </Box>
              )}
            </Box>
            {filterStatus === 'true' && (
              <Box className={s.statusWrapButton}>
                {role == 'manager' ? (
                  <>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('valuating') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('valuating')}
                    >
                      Розцінюється
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('valuated') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('valuated')}
                    >
                      Розцінено
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf(
                    'agreed_without_contract',
                  ) != -1 && s.statusButtonActive
                } `}
                      onClick={onChangeStatus(
                        'agreed_without_contract',
                      )}
                    >
                      Погоджено, очікує на договір
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('contract_sent') !=
                    -1 && s.statusButtonActive
                } `}
                      onClick={onChangeStatus('contract_sent')}
                    >
                      Договір відправлено
                    </Button>

                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('agreed') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('agreed')}
                    >
                      Погоджено
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('in_progress') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('in_progress')}
                    >
                      Опрацьовано
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('declined') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('declined')}
                    >
                      Відмовлено
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('finished') != -1 &&
                  s.statusButtonActive
                }`}
                      onClick={onChangeStatus('finished')}
                    >
                      Заактовано
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('unactive') != -1 &&
                  s.statusButtonActive
                }`}
                      onClick={onChangeStatus('unactive')}
                    >
                      Неактивне
                    </Button>
                  </>
                ) : (
                  <>
                    {/* <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('unordered') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('unordered')}
                    >
                      Чернетка
                    </Button> */}
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('valuating') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('valuating')}
                    >
                      Розцінюється
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('valuated') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('valuated')}
                    >
                      Розцінено
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('agreed') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('agreed')}
                    >
                      Погоджено
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('in_progress') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('in_progress')}
                    >
                      Опрацьовано
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('declined') != -1 &&
                  s.statusButtonActive
                } `}
                      onClick={onChangeStatus('declined')}
                    >
                      Відмовлено
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('finished') != -1 &&
                  s.statusButtonActive
                }`}
                      onClick={onChangeStatus('finished')}
                    >
                      Заактовано
                    </Button>
                    <Button
                      className={`${s.statusButton} 
                ${
                  filterValue.statuses.indexOf('unactive') != -1 &&
                  s.statusButtonActive
                }`}
                      onClick={onChangeStatus('unactive')}
                    >
                      Неактивне
                    </Button>
                  </>
                )}
              </Box>
            )}
            {allManagerCheck === 'true' && (
              <Box className={s.selectBoxManagers}>
                <Select
                  placeholder="Менеджери"
                  onChange={onChangeManager}
                  isLoading={managerData.isLoading}
                  options={managerData.data}
                  isMulti
                />
              </Box>
            )}

            <Box>
              <Button onClick={onCleareFilter}>
                Очистити фільтр
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
