import * as Api from '../../../api';
import { propOr } from 'ramda';

export const fetchDataOrders = ({ ...props }) => {
  let fetchDataTable = props;

  let fetchParamsQuery = getUrl(fetchDataTable);

  return Api.paginatedOrdered(fetchParamsQuery).then((result) => {
    let tableData = {};
    tableData.allCount = result.data.count;
    tableData.itemsPerPage = result.data.results.length;
    tableData.orders = result.data.results.map((item) => {
      return getNomenclature(item);
    });

    return tableData;
  });
};

function getUrl(data) {
  return Object.entries(data)
    .map(([key, val]) => {
      if (typeof val === 'object') {
        return `${encodeURIComponent(key)}=${JSON.stringify(val)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          val,
        )}`;
      }
    })
    .join('&');
}

function getNomenclature(item) {
  let header = [];
  let main = [];

  item.ordered_nomenclatures.map((nomenclature, index) => {
    item.header = [];
    item.main = [];
    index === 0 ? header.push(nomenclature) : main.push(nomenclature);
  });
  item.header = header;
  item.main = main;
  return item;
}
