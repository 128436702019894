import React, { useState } from 'react';
import s from './tableNomanclaturesManager.module.scss';
import {
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
  TableBody,
  Button,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { Chat } from '../../Chat/Chat';

export const TableNomanclaturesManager = ({ ...props }) => {
  const {
    tableData,
    filterValue,
    AuthUser,
    statuses,
    setFilterValue,
    handleOpen,
  } = props;

  const handleChangePage = (event, newPage) => {
    setFilterValue({ ...filterValue, page_number: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterValue({
      ...filterValue,
      page_number: 1,
      page_size: Number(event.target.value),
    });
  };
  const handleLineLabels = ({ from, to, count, page }) => {
    return `${from}-${to} з ${count !== -1 ? count : to}`;
  };

  // modall comment and file
  const [openModalChat, setOpenModalChat] = useState({
    isOpen: false,
  });
  const itemsRef = React.useRef([]);

  const [modalChat, setModalChat] = React.useState({});
  const handleOpenChat = (event, popoverId, nomenclature_id) => {
    setModalChat({
      openedPopoverId: popoverId,
      isOpen: true,
      anchorEl: event.currentTarget,
      nomenclature_id: nomenclature_id,
    });
    if(!!itemsRef.current[nomenclature_id]){

      itemsRef.current[nomenclature_id].classList.add(s.hidden_newMessageMark)
    }

  };
  const handleCloseChat = () => {
    setModalChat({
      openedPopoverId: null,
      isOpen: false,
      anchorEl: null,
    });
  };
console.log(tableData );

  return (
    <>
      <TableContainer className={s.TableNomenclature}>
        <Table className={s.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>№ замовлення</TableCell>
              <TableCell>Дата створення</TableCell>
              <TableCell>Номенклатура</TableCell>
              <TableCell>Напрям</TableCell>
              <TableCell>К-сть</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Адреса</TableCell>
              <TableCell>Компанія</TableCell>
              <TableCell>Коментарі та файли</TableCell>

              {AuthUser.role_code === 'ap' && (
                <TableCell>Менеджер</TableCell>
              )}

              <TableCell>Розрахунок</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.nomenclature.map((row, key) => {
              return (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    className={s.idOrders}
                    scope="row"
                  >
                    {row.orderId}
                  </TableCell>

                  <TableCell>{row.created}</TableCell>
                  <TableCell>{row.nomenclature}</TableCell>
                  <TableCell>{row.directions}</TableCell>

                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>
                    <Box display="flex" className={s.userInfo}>
                      <Box mr={2}>
                        <Avatar
                          style={{
                            width: 40,
                            height: 40,
                            transition: '0.3s',
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={s.fullName}
                        >
                          {row.client.fullName}
                        </Typography>

                        <Typography
                          variant="body2"
                          className={s.companyName}
                        >
                          {row.client.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      onClick={(e) => {
                        handleOpenChat(e, key + 1, row.id);
                      }}
                    >
                       {console.log(row.has_unread_comments)}
                      Переглянути
                      {row.has_unread_comments && (
              <div ref={el => itemsRef.current[row.id] = el} className={s.newMessageMark  }></div>
            )}
                    </Button>

                    {modalChat.openedPopoverId === key + 1 && (
                      <ModalWindow
                        open={
                          modalChat.openedPopoverId === key + 1
                            ? modalChat
                            : modalChat
                        }
                        handleClose={handleCloseChat}
                        titleWindow="Коментарі та файли"
                      >
                        <Chat
                        AuthUser={AuthUser}
                        statuses={statuses}
                          nomenclature_id={modalChat.nomenclature_id}
                        ></Chat>
                      </ModalWindow>
                    )}
                  </TableCell>
                  {AuthUser.role_code === 'ap' && (
                    <TableCell>
                      <Box display="flex" className={s.userInfo}>
                        <Box mr={2}>
                          <Avatar
                            style={{
                              width: 40,
                              height: 40,
                              transition: '0.3s',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            className={s.fullName}
                          >
                            {row.manager.full_name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>
                    {row.price ? (
                      <>{row.price} грн.</>
                    ) : (
                      <Button
                        onClick={handleOpen(row.id)}
                        className={s.btn_border}
                      >
                        Розцінити
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableData.allCount}
        rowsPerPage={filterValue.page_size}
        labelRowsPerPage="Номенклатур на сторінці"
        labelDisplayedRows={handleLineLabels}
        page={filterValue.page_number - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
