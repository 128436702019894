import React from 'react';
import { Box } from '@material-ui/core';

import s from './UserSetings.module.scss';
import { UserInfo } from '../../Form/userInfo/userInfo';
import { ChengPassword } from '../../Form/chengPassword/chengPassword';

export const UserSetings = () => {
  const [open, setOpen] = React.useState(false);

  function isOpenBox() {
    setOpen(!open);
  }

  return (
    <> 
      <Box px={3}>
        <UserInfo isOpenBox={isOpenBox}></UserInfo>
      </Box>
      {open && (
        <Box p={3} className={s.setings}>
          <ChengPassword></ChengPassword>
        </Box>
      )}
    </>
  );
};
