import { propOr } from 'ramda';
import { types as t, flow } from 'mobx-state-tree';
import storageService from '../utils/storageService';
import UserModel from './modeles/UserModel';
import localStorageKeys from '../constants/localStorageKeys';
import * as Api from '../api';

const UsersStore = t
  .model('UserStore', {
    isLoading: t.optional(t.boolean, true),
    list: t.optional(t.array(UserModel), []),
    // listFiltres
    // filterdata
  })
  .actions((store) => ({
    addUser(user) {
       
      store.list.unshift(user);
    },
    fetchUser: flow(function* (userID) {
      try {
        const res = yield Api.getUser(userID);

        store.addUser(res.data.user);
        return res;
      } catch (error) {
        console.log(error );
        
        return error.response.data;
      }
    }),
    updateUser: flow(function* (userData) {
      try {
        const res = yield Api.updateUser(userData);

        store.addUser(res.data.user);

        storageService.set(
          localStorageKeys.USER_ID,
          res.data.user.id,
        );

        storageService.set(
          localStorageKeys.ACCESS_TOKEN,
          res.data.key,
        );

        storageService.set(
          localStorageKeys.USER_INFO,
          JSON.stringify(res.data.user),
        );
        return res;
      } catch (error) {
        console.log(error);

        return error.response.data;
      }
    }),
    changePassword: flow(function* (userData) {
      try {
        const res = yield Api.changePassword(userData);

        // const res = store.addUser(userData);
        return res;
      } catch (error) {
        return error.response.data;
      }
    }),
  }))
  .views((store) => ({
    get authUser() {
      const userId = storageService.get('userId');
      const allUsers = propOr([], 'list', store);
      return allUsers.find(({ id }) => id.toString() === userId);
    },
  }));

export default UsersStore;

// function fetchUser(flow, store) {
//   return function* fetchUser(userId) {
//     try {
//       flow.start();
//       const res = yield Api.getUser(userId);
//       store.addUser(res.data.user);

//       // console.log( store);
//       // const rootStore = getRoot(store);

//       // rootStore.users.addUser(user);
//       console.log('123');

//       flow.success();
//     } catch (err) {
//       console.log('456');

//       flow.success();
//       // TO DO: Андрій коли приведете помилки до єдиного форматy то перенеси логіку формування помилки у createFlow
//       // const formattedError = {
//       //   status: pathOr(
//       //     null,
//       //     ['response', 'data', 'status_code'],
//       //     err,
//       //   ),
//       //   reason: pathOr(null, ['response', 'data', 'reason'], err),
//       //   message: pathOr(
//       //     err.message,
//       //     ['response', 'data', 'non_field_errors', 0],
//       //     err,
//       //   ),
//       // };

//       // flow.failed(formattedError);
//     }
//   };
// }
